import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootStateType } from "../../redux/store";

interface ThemeState {
  isDarkTheme: boolean;
  isInvoiceModal: boolean;
}

const initialState: ThemeState = {
  isDarkTheme: false,
  isInvoiceModal: false,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setDarkTheme: (state, action: PayloadAction<{ isDarkTheme: boolean }>) => {
      state.isDarkTheme = action.payload.isDarkTheme;
    },
    setIsInvoiceModal: (
      state,
      action: PayloadAction<{ isInvoiceModal: boolean }>
    ) => {
      state.isInvoiceModal = action.payload.isInvoiceModal;
    },
  },
});

export const { setDarkTheme, setIsInvoiceModal } = themeSlice.actions;

export const themeSliceReducer = themeSlice.reducer;
export const selectTheme = (state: RootStateType) => state.theme.isDarkTheme;
export const selectInvoiceModal = (state: RootStateType) =>
  state.theme.isInvoiceModal;
