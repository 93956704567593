import { Frame } from "component/Frame";
import Toggle from "component/Switch/Toggle";
import React, { useEffect, useState } from "react";
import {
  useGetAllUserPreferencesQuery,
  useUpdateUserPreferenceMutation,
} from "../../redux/services/settingsApi";
import { toast } from "sonner";
import { PageLoader } from "component/Loader/Loader";
import { useSidebar } from "component/Sidebar/useSidebar";

const Notifications = () => {
  const {
    data: userPreferences,
    isSuccess,
    isLoading: isLoadingUserPreferences,
    isFetching,
  } = useGetAllUserPreferencesQuery();

  const [emailNotify, setEmailNotify] = useState<boolean | undefined>(
    undefined
  );
  const [smsNotify, setSmsNotify] = useState<boolean | undefined>(undefined);
  const [inAppNotify, setInAppNotify] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    if (isSuccess && userPreferences) {
      setEmailNotify(userPreferences.notify_email);
      setSmsNotify(userPreferences.notify_sms);
      setInAppNotify(userPreferences.notify_inapp);
    }
  }, [isSuccess, userPreferences]);

  const [updateUserPreference] = useUpdateUserPreferenceMutation();

  const handleToggle = async (param: string) => {
    let updatedState = {};

    if (param === "email-notify") {
      setEmailNotify(!emailNotify);
      updatedState = { notify_email: !emailNotify };
    } else if (param === "sms-notify") {
      setSmsNotify(!smsNotify);
      updatedState = { notify_sms: !smsNotify };
    } else if (param === "in-app-notify") {
      setInAppNotify(!inAppNotify);
      updatedState = { notify_inapp: !inAppNotify };
    }

    const payload = {
      notify_email: param === "email-notify" ? !emailNotify : emailNotify,
      notify_sms: param === "sms-notify" ? !smsNotify : smsNotify,
      notify_inapp: param === "in-app-notify" ? !inAppNotify : inAppNotify,
    };

    toast.loading("Updating notification preferences...");
    await updateUserPreference(payload).unwrap();
    toast.success(`${param.replace("-", " ")} updated successfully`);
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
  };

  const { isDarkTheme } = useSidebar();

  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <Frame rmPadding className="pb-10">
      <div>
        <p
          className={`border-b border-[#999898] p-5 ${toggleTextTheme} font-medium text-2xl`}
        >
          Notifications
        </p>
      </div>

      {isLoadingUserPreferences ? (
        <div className="mt-5">
          <PageLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-2 p-5">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className={`${toggleTextTheme} font-medium text-base`}>
                Email
              </p>
              <p className="text-[#999898] font-normal text-[11px]">
                Receive email notifications
              </p>
            </div>
            <Toggle
              isChecked={emailNotify || false}
              handleToggle={() => handleToggle("email-notify")}
            />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className={`${toggleTextTheme} font-medium text-base`}>SMS</p>
              <p className="text-[#999898] font-normal text-[11px]">
                Receive SMS notifications
              </p>
            </div>
            <Toggle
              isChecked={smsNotify || false}
              handleToggle={() => handleToggle("sms-notify")}
            />
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className={`${toggleTextTheme} font-medium text-base`}>In-app</p>
              <p className="text-[#999898] font-normal text-[11px]">
                Receive In-app notifications
              </p>
            </div>
            <Toggle
              isChecked={inAppNotify || false}
              handleToggle={() => handleToggle("in-app-notify")}
            />
          </div>
        </div>
      )}
    </Frame>
  );
};

export default Notifications;
