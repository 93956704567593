export enum ButtonTypeEnum {
  Submit = "submit",
  Reset = "reset",
  Button = "button",
}

export type SwitchType = {
  themeMode: string;
  toggleSwitch: () => void;
  isChecked: boolean;
};

export type BtnTypes = {
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
  label: string;
  btnType?:
    | ButtonTypeEnum.Button
    | ButtonTypeEnum.Reset
    | ButtonTypeEnum.Submit;
};
