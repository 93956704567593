import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import { useNavigate } from "react-router";
import { useForgotPasswordMutation } from "../../redux/services/authApi";

export const useForgotPassword = () => {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const formValues = {
    email: "",
  };

  const navigate = useNavigate();

  const [forgotPassword, { isLoading, isSuccess }] =
    useForgotPasswordMutation();
  const handleSubmit = async () => {
    const payload = { ...formik.values };
    console.log(payload, "payload");
    await forgotPassword(payload)
      .unwrap()
      .then(() => {
        localStorage.setItem("user-email", formik.values.email);
        toast.success("OTP sent Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
            navigate("/verify-otp");
          }, 1000);
        });
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
  };
};
