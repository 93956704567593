export const sortOptions = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

export const purchaseTableHead = [
  "Vendor name",
  "Vendor address",
  "Description",
  "Quantity",
  "Size",
  "Date",
  "Status",
  "Price",
  // "Reference number",
  "Outlet",
  "Product name",
  "Action",
];

export const purchaseTableBody = [
  {
    vendor_name: "Hassan Tyres",
    vendor_address: "32, Radison address",
    description: "Bus tyre",
    quantity: "213",
    size: "42/45",
    date: "4/26/2024",
    status: "Delivered",
    price: "₦40,000",
    reference_number: "#35647636",
    outlet: "Egbeda",
  },
];
