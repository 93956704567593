import { generateQueryString } from "helpers/helper";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";
import {  RentResponse } from "../../../@types/rent";

export const rentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createRent: builder.mutation({
      query: (payload) => ({
        url: "rent/create-rent",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["rents"],
    }),

    getRents: builder.query<RentResponse, QueryParams>({
      query: (params) => ({
        url: `rent/get-rent${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["rents"],
      transformResponse: (response:  RentResponse ) => response,
    }),

    updateRent: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `rent/update-rent/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["rents"],
    }),

    deleteRent: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `rent/delete-rent/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["rents"],
    }),

    downloadRent: builder.mutation<Blob, void>({
      query: () => ({
        url: "rent/export/excel/rent",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportRent: builder.mutation<Blob, void>({
      query: () => ({
        url: "rent/export/pdf/rent",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useCreateRentMutation,
  useGetRentsQuery,
  useUpdateRentMutation,
  useDeleteRentMutation,
  useDownloadRentMutation,
  useExportRentMutation,
} = rentApi;
