import React from "react";
import VerifyOtpForm from "./VerifiyOtpForm";

const VerifyOtp: React.FC = () => {
  return (
    <div className="relative h-screen login-bg-img flex">
      <VerifyOtpForm />
    </div>
  );
};

export default VerifyOtp;
