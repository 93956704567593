import {
  PurchaseTabIcon,
  SalesTabIcon,
  StockTabIcon,
} from "assets/images/svgs";
import {
  useGetAllProductCountQuery,
  useGetAllPurchasesCountQuery,
  useGetAllTotalStaffsQuery,
} from "../../redux/services/dashboardApi";
import Skeleton from "react-loading-skeleton";
import { formatNumberWithCommas } from "helpers/helper";
import {
  ProductCount,
  PurchaseCount,
  SalesCount,
} from "../../../@types/dashboard";

export const useDashboardTabs = () => {
  const { data: purchaseData, isLoading: isLoadingPurchase } =
    useGetAllPurchasesCountQuery();
  const { data: productData, isLoading: isLoadingProduct } =
    useGetAllProductCountQuery();
  const { data: salesData, isLoading: isLoadingSales } =
    useGetAllTotalStaffsQuery();

  const purchases = (purchaseData as PurchaseCount) ?? [];
  const products = (productData as ProductCount) ?? [];
  const sales = (salesData as SalesCount) ?? [];

  const dashboard_tabs = [
    {
      label: "Total Sales",
      amount: (
        <>
          {isLoadingSales ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={200}
              height={20}
            />
          ) : (
            <p>
              {`${sales?.currency || "-- --"}${formatNumberWithCommas(
                sales?.total_Sales ?? 0
              )}` || "----- -----"}
            </p>
          )}
        </>
      ),
      isIncreament: true,
      percentage: (
        <>
          {isLoadingPurchase ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={30}
              height={12}
            />
          ) : (
            <p> {sales?.percentage ?? "--- ---"}</p>
          )}
        </>
      ),
      period: (
        <>
          {isLoadingPurchase ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={60}
              height={12}
            />
          ) : (
            <p>Than last month</p>
          )}
        </>
      ),
      icon: PurchaseTabIcon,
      path: "/purchase",
      loading: false,
    },
    {
      label: "Total Purchase",
      amount: (
        <>
          {isLoadingPurchase ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={200}
              height={20}
            />
          ) : (
            <p>
              {`${purchases?.currency || "-- --"}${formatNumberWithCommas(
                purchases?.total_purchase ?? 0
              )}` || "----- -----"}
            </p>
          )}
        </>
      ),
      isIncreament: true,
      percentage: (
        <>
          {isLoadingPurchase ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={30}
              height={12}
            />
          ) : (
            <p> {purchases?.percentage ?? "--- ---"}</p>
          )}
        </>
      ),
      period: (
        <>
          {isLoadingPurchase ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={60}
              height={12}
            />
          ) : (
            <p>Than last month</p>
          )}
        </>
      ),
      icon: SalesTabIcon,
      path: "/sales-rent",
      loading: isLoadingPurchase,
    },
    {
      label: "Total stock value",
      amount: (
        <>
          {isLoadingProduct ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={200}
              height={20}
            />
          ) : (
            <p>
              {`${products?.currency || "-- --"}${formatNumberWithCommas(
                products?.total_Product ?? 0
              )}` || "----- -----"}
            </p>
          )}
        </>
      ),
      isIncreament: false,
      percentage: (
        <>
          {isLoadingProduct ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={30}
              height={12}
            />
          ) : (
            <p> {products?.percentage ?? "--- ---"}</p>
          )}
        </>
      ),
      period: (
        <>
          {isLoadingPurchase ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={60}
              height={12}
            />
          ) : (
            <p>Than last month</p>
          )}
        </>
      ),
      icon: StockTabIcon,
      path: "",
      loading: false,
    },
  ];
  return {
    dashboard_tabs,
  };
};

export const dashboard_table_head = [
  "Invoice No",
  "Invoice to",
  "Sales date",
  // "Paid Amount",
  "Status",
];

export const dashboard_table_body = [
  {
    invoice_no: "8974699",
    invoice_to: "Mr. Osarz Idama",
    sales_date: "4/6/2024",
    paid_amount: "₦50,000",
    status: "Delivered",
  },
  {
    invoice_no: "8974699",
    invoice_to: "Mr. Hassan",
    sales_date: "3/6/2024",
    paid_amount: "₦104,000",
    status: "Delivered",
  },
  {
    invoice_no: "8974699",
    invoice_to: "Miss Stella",
    sales_date: "2/6/2024",
    paid_amount: "₦5,000",
    status: "Pending",
  },
  {
    invoice_no: "8974699",
    invoice_to: "Mr. Somachi",
    sales_date: "2/6/2024",
    paid_amount: "₦38,000",
    status: "Delivered",
  },
  {
    invoice_no: "8974699",
    invoice_to: "Mrs Okolie",
    sales_date: "1/6/2024",
    paid_amount: "₦11,000",
    status: "Returned",
  },
];

// export const stock_history = [
//   {
//     label: "Total Sales Item",
//     value: 200,
//     isIncreament: true,
//     percentage: 20,
//   },
//   {
//     label: "Total Sales Return Item",
//     value: 3,
//     isIncreament: false,
//     percentage: 15,
//   },
//   {
//     label: "Total Purchase Item",
//     value: 70,
//     isIncreament: true,
//     percentage: 20,
//   },
//   {
//     label: "Total Purchase Return Item",
//     value: 1,
//     isIncreament: false,
//     percentage: 5,
//   },
// ];

export const selectOption = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Quaterly",
    value: "quarterly",
  },
];

export const chartDateOptions = [
  {
    label: "Weekly",
    value: "week",
  },
  { label: "Daily", value: "day" },
  { label: "Monthly", value: "month" },
  // { label: "Hourly", value: "hourly" },
];

export const dummyData = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      data: [45000, 89000, 100000, 3456, 45333, 233445, 89999],
      backgroundColor: "#344BFD",
      barThickness: 35,
      borderRadius: "5",
    },
    {
      data: [69000, 35000, 2349, 88999, 234556, 40000, 235000],
      backgroundColor: "#04B4FC",
      barThickness: 35,
      borderRadius: "5",
    },
  ],
};

export const dummyDoughnutdata = {
  labels: ["Car tire", "Bike tire", "Big tire"],
  datasets: [
    {
      data: [32, 49, 58],
      backgroundColor: ["#04B4FC", "#344BFD", "#4A07A0"],
    },
  ],
};

export const doughnut_chart_dummy_data = [
  {
    color: "bg-[#04B4FC]",
    label: "Car tire",
    percentage: "60",
  },
  {
    color: "bg-[#344BFD]",
    label: "Bike tire",
    percentage: "20",
  },
  {
    color: "bg-[#4A07A0]",
    label: "Big tire",
    percentage: "20",
  },
];

export const barchartData = [
  // {
  //   color: "bg-[#D9D9D9]",
  //   label: "Sales Target",
  // },
  {
    color: "bg-[#344BFD]",
    label: "Purchase",
  },
  {
    color: "bg-[#04B4FC]",
    label: "Sales",
  },
];
