import { SelectField } from "component/Select";
import { barchartData, chartDateOptions, dummyData } from "./utils";
import { useDashboardChart } from "./useDashboardChart";
import { Frame } from "component/Frame";
import { TitleText, col } from "component/Typography";
import { BarChart } from "component/Chart";
import { useGetDashboardGraphQuery } from "../../redux/services/dashboardApi";
import { PageLoader } from "component/Loader/Loader";
import { GraphData } from "../../../@types/dashboard";
import { useState } from "react";
import { OptionType } from "./types";
import { useSidebar } from "component/Sidebar/useSidebar";

const DashboardBarChart = () => {
  const [period, setPeriod] = useState<OptionType>({
    label: "Monthly",
    value: "month",
  });

  const { isDarkTheme } = useSidebar();
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const {
    data: graphData,
    isLoading: isLoadingGraphData,
    isFetching: isFetchingGraphData,
  } = useGetDashboardGraphQuery({
    period: (period as OptionType)?.value,
  });
  const graphDetails = (graphData as GraphData) ?? [];

  const barChartDetails = (
    <div className="flex justify-center gap-5 mt-7  bottom-0 mb-3 absolute  left-1/2 transform -translate-x-1/2">
      {barchartData.map((chi, idx) => {
        const { color, label } = chi;
        return (
          <div key={idx} className="flex gap-3 items-center">
            <span className={`${color} border-0 rounded-sm w-5 h-2.5`}></span>
            <p className={`${toggleTextTheme} text-[11px] font-normal`}>{label}</p>
          </div>
        );
      })}
    </div>
  );

  const graphChart = {
    // labels: graph_data.map((item) => `Month ${item.period}`),
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        data: graphDetails?.graph_data?.map((item) => item.purchase_value),
        backgroundColor: "#344BFD",
        barThickness: 35,
        borderRadius: 5,
      },
      {
        data: graphDetails?.graph_data?.map((item) => item.sales_value),
        backgroundColor: "#04B4FC",
        barThickness: 35,
        borderRadius: 5,
      },
    ],
  };
  return (
    <Frame className="relative h-[30rem]">
      <div className={col}>
        <TitleText value={`Sales & Purchase`} />
        <SelectField
          value={period as any}
          selectOptions={chartDateOptions}
          onChange={(e: any) => {
            console.log(e, "event");
            setPeriod(e);
          }}
          placeholder="Select Period..."
        />
      </div>
      {isLoadingGraphData || isFetchingGraphData ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <PageLoader />
        </div>
      ) : (
        <BarChart data={graphChart} />
      )}
      <div className="mt-10">{barChartDetails}</div>
    </Frame>
  );
};
export default DashboardBarChart;
