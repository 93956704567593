import {
  EyeCloseIcon,
  LockIcon,
  Logo,
  MsgIcon,
  TimesIcon,
} from "assets/images/svgs";
import { useLoginForm } from "./useLoginForm";
import { InputField } from "component/Form";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { useNavigate } from "react-router";
import {  useState } from "react";

const LoginForm = () => {
  const { formik, isLoading } = useLoginForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="border-0 rounded-[50px] pt-6 pb-28 bg-[#FAF9F6] shadow-custom-box-shadow w-[498px] m-auto px-10">
      <Logo className="m-auto" />
      <p className="text-black text-2xl">Welcome Back</p>
      <p className="text-sm mt-1">Enter your login details</p>

      <form
        onSubmit={formik.handleSubmit}
        className="mt-10 flex flex-col gap-4"
      >
        <InputField
          leftIcon={MsgIcon}
          rightIcon={TimesIcon}
          type="text"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Enter email"
          onRightIconClick={() => formik.setFieldValue("email", "")}
        />
        <InputField
          type={showPassword ? "text" : "password"}
          label="Password"
          name="password"
          onChange={formik.handleChange}
          leftIcon={LockIcon}
          rightIcon={EyeCloseIcon}
          placeholder="Enter password"
          onRightIconClick={() => setShowPassword(!showPassword)}
        />
        <p
          className="text-[#FC133080] ml-auto font-medium text-sm cursor-pointer"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password?
        </p>
        <Button
          btnType={ButtonTypeEnum.Submit}
          label="Continue"
          className="mt-14"
          loading={isLoading}
          disabled={!formik.isValid || !formik.dirty}
        />
      </form>
    </div>
  );
};

export default LoginForm;
