import { generateQueryString } from "helpers/helper";
import {
  GraphData,
  OutletCount,
  ProductCount,
  PurchaseCount,
  QueryParams,
  SalesCount,
} from "../../../@types/dashboard";
import { baseApi } from "../baseApi";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPurchasesCount: builder.query<PurchaseCount, void>({
      query: () => ({
        url: "/Dashboard/count-purchase",
        method: "GET",
      }),
      transformResponse: (response: PurchaseCount) => response,
    }),

    getAllProductCount: builder.query<ProductCount, void>({
      query: () => ({
        url: "/Dashboard/count-product",
        method: "GET",
      }),
      transformResponse: (response: ProductCount) => response,
    }),

    getAllTotalOutlets: builder.query<OutletCount, void>({
      query: () => ({
        url: "/Dashboard/total-outlets",
        method: "GET",
      }),
      transformResponse: (response: OutletCount) => response,
    }),
    getAllTotalStaffs: builder.query<SalesCount, void>({
      query: () => ({
        url: "/Dashboard/total-sales",
        method: "GET",
      }),
      transformResponse: (response: SalesCount) => response,
    }),
    getDashboardStockHistory: builder.query<any, QueryParams>({
      query: (params) => ({
        url: `/Dashboard/get-stockHistory${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      transformResponse: (response: any) => response,
    }),
    getDashboardGraph: builder.query<GraphData, { period: string | number }>({
      query: ({ period }: { period: string }) => ({
        url: `/Dashboard/graph-data?period=${period || ""}`,
        method: "GET",
      }),
      transformResponse: (response: { data: GraphData }) => response.data,
    }),
    getRecentInvoices: builder.query<any, QueryParams>({
      query: (params) => ({
        url: `/Dashboard/get-RecentInvoice${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      transformResponse: (response: any) => response,
    }),
    getNotifications: builder.query<any, void>({
      query: () => ({
        url: "/admin/notifications",
        method: "GET",
      }),
      providesTags: ["notifications"],
      transformResponse: (response: any) => response,
    }),

    markAsRead: builder.mutation<void, { id: number }>({
      query: (id) => ({
        url: `/admin/notifications/read/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["notifications"],
    }),
  }),
});

export const {
  useGetAllPurchasesCountQuery,
  useGetAllProductCountQuery,
  useGetAllTotalOutletsQuery,
  useGetAllTotalStaffsQuery,
  useGetDashboardGraphQuery,
  useGetRecentInvoicesQuery,
  useGetDashboardStockHistoryQuery,
  useGetNotificationsQuery,
  useMarkAsReadMutation,
} = authApi;
