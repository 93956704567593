import useDisclosure from "hooks/useDisclosure";
import React, { useMemo, useRef, useState } from "react";
import DashboardModal from "component/Modal/Modal";
import { useGetProductsQuery } from "../../../redux/services/productApi";
import { ProductsType } from "../../../../@types/product";
import { useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useProducts } from "pages/Products/useProducts";
import ReportsTable from "../ReportsTable";
// import { useGetReportsQuery } from "../../../redux/services/reportApi";
import {
  useGetProductReportCountBreakDownQuery,
  useGetProductReportsQuery,
} from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";
import { PrintIcon } from "assets/images/tsx";
import { useReactToPrint } from "react-to-print";

export default function ProductTab() {
  const {
    isOpen: openAddProductModal,
    open: onOpenAddProductModal,
    close: closeAddProductModal,
  } = useDisclosure();
  const { handleProductTabChange, productTab } = useProducts({
    closeModal: closeAddProductModal,
  });

  const [edit, setEdit] = useState(false);
  const [filterVal, setFilterVal] = useState<any>("");
  const [childData, setChildData] = useState<ProductsType>();
  const [searchVal, setSearchVal] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleDateFilter = (date: string) => {
    if (date === "start_date" && fromDate && toDate) {
      return fromDate;
    } else if (date === "end_date" && fromDate && toDate) {
      return toDate;
    } else {
      return null;
    }
  };

  const {
    data: productsData,
    isLoading: isLoadingProducts,
    isFetching,
  } = useGetProductReportsQuery({
    search: debouncedSearchVal,
    filter_by: productTab.value,
    page: page,
    "time[start_date]": handleDateFilter("start_date"),
    "time[end_date]": handleDateFilter("end_date"),
    // "time[unit]": filterVal?.value,
  });

  const {
    data: productCount,
    isLoading: isLoadingProductsCount,
    isFetching: isFetchingProductCount,
  } = useGetProductReportCountBreakDownQuery({
    search: debouncedSearchVal,
    filter_by: productTab.value,
    page: page,
  });

  const lastPage = productsData?.meta?.last_page ?? 0;
  const products = productsData?.data;

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();

  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";

  const totalCards = (
    <div
      onClick={onOpenBreakModal}
      className={`${toggledCardTheme} cursor-pointer hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p
        className={`${toggleTextTheme} font-medium text-3xl `}
      >{`All ${"Products"}`}</p>
      <div className="flex flex-col gap-4 mt-10">
        <div className="flex gap-2 items-center">
          <p className={`${toggleTextTheme} font-medium text-sm`}>
            Total Product in stock:
          </p>
          <p className={`${toggleTextTheme} font-medium text-sm`}>
            {isLoadingProductsCount ? (
              <Skeleton
                baseColor="#E0E0E0"
                highlightColor="#F5F5F5"
                width={20}
                height={10}
              />
            ) : (
              productCount?.total_in_stock || "n/a"
            )}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <p className={`${toggleTextTheme} font-medium text-sm`}>
            Total Product out of stock:
          </p>
          <p className={`${toggleTextTheme} font-medium text-sm`}>
            {isLoadingProductsCount ? (
              <Skeleton
                baseColor="#E0E0E0"
                highlightColor="#F5F5F5"
                width={20}
                height={10}
              />
            ) : (
              productCount?.total_out_of_stock || "n/a"
            )}
          </p>
        </div>
      </div>
    </div>
  );

  const titleClass = `font-medium font-bold text-center w-full p-2 ${toggleTextTheme} min-w-fit mb-3`;
  const colClass = `grid grid-cols-2 border-b-2 p-2 w-full gap-10 ${toggleTextTheme}`;
  const textClass = "text-[#4A07A0] font-semibold";

  const header = (
    <div className={colClass}>
      <p className="font-semibold">Items</p>
      <p className="font-semibold text-right">Quantities</p>
    </div>
  );

  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice Data",
  });

  const breakModal = (
    <>
      <div className="m-5" onClick={handlePrint}>
        <PrintIcon
          className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
        />
      </div>
      <div
        ref={componentRef}
        className="p-5 grid place-items-center w-full min-w-fit "
      >
        <p
          className={`${toggleTextTheme} font-semibold text-2xl capitalize text-center`}
        >{`${"Product"} Breakdown`}</p>
        <div className="grid grid-cols-2 mt-5 pb-10 h-[40rem]">
          <div className="border-r-2">
            <h3 className={titleClass}>Product quantities</h3>
            <div className="grid grid-rows place-items-center gap-5 overflow-auto">
              {header}
              {(productCount?.product_quantities as any[])?.map((chi, idx) => {
                const { product_name, quantity } = chi;
                return (
                  <div className={colClass}>
                    <p className={textClass}>{product_name}</p>
                    <p className="font-semibold text-right">{quantity}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* second layer */}
          <div>
            <h3 className={titleClass}>Out of stock products</h3>
            <div className="grid grid-rows place-items-center gap-5 overflow-auto">
              {header}
              {(productCount?.out_of_stock_products as any[])?.map(
                (chi, idx) => {
                  const { product_name, quantity } = chi;
                  return (
                    <div className={colClass}>
                      <p className={textClass}>{product_name}</p>
                      <p className="font-semibold text-right">{quantity}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      {totalCards}

      <ReportsTable
        setStartDate={setFromDate}
        setEndDate={setToDate}
        titleValue={"All Products"}
        setIsEdit={setEdit}
        productTableBody={products}
        loading={isLoadingProducts}
        isFetching={isFetching}
        onOpenAddModal={onOpenAddProductModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
        filterVal={filterVal}
        setFilterVal={setFilterVal}
      />

      {/* {plusDisplay} */}

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      <DashboardModal
        width="50%"
        open={openBreakModal}
        onClose={closeBreakModal}
      >
        {breakModal}
      </DashboardModal>
    </>
  );
}
