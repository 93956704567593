import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import { useNavigate } from "react-router";
import {
  useResendOtpMutation,
  useVerifyOtpMutation,
} from "../../redux/services/authApi";

export const useVerifyOtp = () => {
  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d+$/, "OTP must contain only numbers")
      .length(6, "OTP must be exactly 4 digits")
      .required("OTP is required"),
  });

  const formValues = {
    otp: "",
  };

  const email = localStorage.getItem("user-email");

  const navigate = useNavigate();

  const [verifyOtp, { isLoading }] = useVerifyOtpMutation();
  const [resendOtp] = useResendOtpMutation();

  const handleSubmit = async () => {
    const payload = { ...formik.values };
    await verifyOtp(payload)
      .unwrap()
      .then(() => {
        toast.success("OTP Verified Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
            navigate("/reset-password");
          }, 1000);
        });
      });
  };

  const handleResendOtp = async () => {
    toast.loading("sending OTP...");
    const payload = { email: email };
    await resendOtp(payload)
      .unwrap()
      .then(() => {
        toast.success("OTP Sent Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
          }, 1000);
        });
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    email,
    handleResendOtp,
  };
};
