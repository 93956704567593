export const useChart = () => {
  function formatTickValue(value: number): string {
    const absValue = Math.abs(value);
    if (absValue >= 1.0e9) {
      return (value / 1.0e9) + "b";
    } else if (absValue >= 1.0e6) {
      return (value / 1.0e6) + "m";
    } else if (absValue >= 1.0e3) {
      return (value / 1.0e3) + "k";
    } else {
      return value.toString();
    }
  }

  return { formatTickValue };
};
