import { useFormik } from "formik";
import { toast } from "sonner";
import * as yup from "yup";
import { SalesArray } from "../../../@types/sales";
import {
  useCreateSalesMutation,
  useUpdateSalesMutation,
} from "../../redux/services/salesApi";
import {
  useCreateRentMutation,
  useUpdateRentMutation,
} from "../../redux/services/rentApi";
import {
  useCreateCreditMutation,
  useUpdateCreditMutation,
} from "../../redux/services/creditApi";
import {
  useCreateDebitMutation,
  useUpdateDebitMutation,
} from "../../redux/services/debitApi";

export const useAddCredit = ({
  closeModal,
  isUpdate,
  ID,
  data,
  isActiveDebitTab,
}: {
  closeModal: () => void;
  isUpdate?: boolean;
  ID: number;
  data: any;
  isActiveDebitTab?: boolean;
}) => {
  const validationSchema = yup.object().shape({
    product_sold: yup.string().required("Product sold is required"),
    customer_name: yup.string().required("Customer name is required"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .positive("Quantity must be a positive number")
      .integer("Quantity must be an integer"),
    date: yup.string().required("Date is required"),
    unit_price: yup
      .number()
      .required("Unit price is required")
      .positive("Unit price must be a positive number"),
    cost_price: yup
      .number()
      .required("Cost price is required")
      .positive("Cost price must be a positive number"),
  });
  const debitValidationSchema = yup.object().shape({
    product_bought: yup.string().required("Product bought is required"),
    vendor_name: yup.string().required("Vendor name is required"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .positive("Quantity must be a positive number")
      .integer("Quantity must be an integer"),
    date: yup.string().required("Date is required"),
    unit_price: yup
      .number()
      .required("Unit price is required")
      .positive("Unit price must be a positive number"),
    cost_price: yup
      .number()
      .required("Cost price is required")
      .positive("Cost price must be a positive number"),
  });

  const formValues = {
    product_sold: (isUpdate && data?.product_sold) || "",
    product_bought: (isUpdate && data?.product_bought) || "",
    customer_name: (isUpdate && data?.customer_name) || "",
    vendor_name: (isUpdate && data?.vendor_name) || "",
    quantity: (isUpdate && data?.quantity) || "",
    cost_price: (isUpdate && (data?.cost_price || data?.cost_price)) || "",
    unit_price: (isUpdate && (data?.unit_price || data?.unit_price)) || "",
    date: (isUpdate && data?.date) || "",
  };

  const [createCredit, { isLoading }] = useCreateCreditMutation();

  const [updateCredit, { isLoading: isLoadingUpdate }] =
    useUpdateCreditMutation();

  const [createDebit, { isLoading: isLoadingDebit }] = useCreateDebitMutation();
  const [updateDebit, { isLoading: isUpdatingDebit }] =
    useUpdateDebitMutation();

  const handleSubmit = async () => {
    const obj = {
      product_sold: !isActiveDebitTab ? formik.values.product_sold : null,
      product_bought: isActiveDebitTab ? formik.values.product_bought : null,
      customer_name: !isActiveDebitTab ? formik.values.customer_name : null,
      vendor_name: isActiveDebitTab ? formik.values.vendor_name : null,
      quantity: formik.values.quantity,
      cost_price: formik.values.cost_price,
      unit_price: formik.values.unit_price,
      date: formik.values.date,
    };

    const payload = { payload: obj, id: ID };

    const handleSuccess = (message: string) => {
      toast.success(message);
      new Promise(() => {
        setTimeout(() => {
          toast.dismiss();
          closeModal();
        }, 1000);
      });
    };

    // try {
    switch (true) {
      case isUpdate && !isActiveDebitTab:
        await updateCredit(payload).unwrap();
        handleSuccess("Credit Updated Successfully");
        break;

      case isActiveDebitTab && !isUpdate:
        await createDebit(obj).unwrap();
        handleSuccess("Debit Created Successfully");
        break;

      case isActiveDebitTab && isUpdate:
        await updateDebit(payload).unwrap();
        handleSuccess("Debit Updated Successfully");
        break;

      default:
        await createCredit(obj).unwrap();
        handleSuccess("Credit Created Successfully");
        break;
    }
    // } catch (error) {
    //   console.error("Error processing request:", error);
    //   toast.error("An error occurred while processing your request.");
    // }
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: isActiveDebitTab
      ? debitValidationSchema
      : validationSchema,
    onSubmit: handleSubmit,
  });
  console.log(formik.errors, "errors check");
  return {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingDebit,
    isUpdatingDebit,
  };
};
