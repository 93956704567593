import { useState } from "react";

type TabType = {
  label: string;
  value: string;
};

export const useInvoice = () => {
  const [invoiceTab, setInvoiceTab] = useState({
    label: "All",
    value: "",
  });

  // const handleInvoiceTabChange = (value: string) => {
  //   setInvoiceTab(value);
  // };

  // const isActiveTab = (tab: string) => {
  //   if (invoiceTab === tab) {
  //     return "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]";
  //   }
  // };
  const handleInvoiceTabChange = (tab: TabType) => {
    setInvoiceTab(tab);
  };

  const isActiveTab = (tabValue: string) => {
    return invoiceTab.value === tabValue
      ? "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
      : "";
  };

  return {
    invoiceTab,
    handleInvoiceTabChange,
    isActiveTab,
  };
};
