import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { ChartType } from "./types";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
};

export function DoughnutChart({ data }: ChartType) {
  return (
    <div >
      <Doughnut width={220}  data={data} options={options} />
    </div>
  );
}
