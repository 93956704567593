import useDisclosure from "hooks/useDisclosure";
import React, { useState } from "react";
import DashboardModal from "component/Modal/Modal";
import { handleDateFilter, useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";
import { SalesTabEnum } from "../../Sales/utils";
import { useSales } from "../../Sales/useSales";
import SalesTable from "../../Sales/SalesTable";
import { SalesArray } from "../../../../@types/sales";
import AddSales from "../../Sales/AddSales";
import { useGetRentsQuery } from "../../../redux/services/rentApi";
import { RentArray } from "../../../../@types/rent";
import { useGetInvoicesQuery } from "../../../redux/services/invoiceApi";
import {
  useGetRentBreakdownQuery,
  useGetRentReportsQuery,
} from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";

export default function RentTab() {
  const {
    isOpen: openAddSalesModal,
    open: onOpenAddSalesModal,
    close: closeAddSalesModal,
  } = useDisclosure();
  const { handleSalesTabChange, isActiveTab, salesTab } = useSales();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<SalesArray>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // const {
  //   data: salesData,
  //   isLoading: isLoadingProducts,
  //   isFetching,
  // } = useGetSalesQuery({
  //   search: debouncedSearchVal,
  //   filter_by: salesTab.value,
  //   page: page,
  // });

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useGetInvoicesQuery({
    search: debouncedSearchVal,
    // search_by: productTab.value,
    filter_by: salesTab.value,
    page: page,
  });

  const {
    data: productCount,
    isLoading: isLoadingProductsCount,
    isFetching: isFetchingProductCount,
  } = useGetRentBreakdownQuery({
    search: debouncedSearchVal,
    // filter_by: productTab.value,
    page: page,
  });

  // console.log(productCount, "rent breakdown");

  const {
    data: rentData,
    isLoading: isLoadingRent,
    isFetching: isFetchingRent,
  } = useGetRentReportsQuery(
    {
      "time[start_date]": handleDateFilter("start_date", fromDate, toDate),
      "time[end_date]": handleDateFilter("end_date", fromDate, toDate),
      search: debouncedSearchVal,
      filter_by: "rent",
      page: page,
    }
    // { skip: salesTab.value !== SalesTabEnum.RENT }
  );

  // const sales = salesData?.data;
  // const lastPage = salesData?.meta?.last_page ?? 0;

  const sales = invoicesData?.invoice?.data ?? [];
  const lastPage = invoicesData?.invoice?.last_page;

  const rents = rentData?.data;
  const rentLastPage = rentData?.last_page;

  // console.log(rentData?.meta?.last_page, "rent last page");

  const isRent = salesTab.value !== SalesTabEnum.RENT;

  const { isDarkTheme } = useSidebar();

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();

  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const totalCards = (
    <div
      onClick={onOpenBreakModal}
      className={`${toggledCardTheme} cursor-pointer hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p className={`${toggleTextTheme} font-medium text-3xl `}>Total Rent</p>
      <p className={`${toggleTextTheme} font-medium text-4xl mt-10`}>
        {isLoadingProductsCount ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={40}
            height={30}
          />
        ) : (
          productCount?.total_rent || "0"
        )}
      </p>
    </div>
  );

  const titleClass = `font-medium font-bold text-center w-full p-2 ${toggleTextTheme} min-w-fit mb-3`;
  const colClass = `grid grid-cols-2 border-b-2 p-2 w-full gap-10 ${toggleTextTheme}`;
  const textClass = "text-[#4A07A0] font-semibold";

  const header = (
    <div className={colClass}>
      <p className="font-semibold">Items</p>
      <p className="font-semibold text-right border-l-2">Quantities</p>
    </div>
  );

  // paste right here

  const breakModal = (
    // react fragment <></>
    <>
      {/* paste below here */}

      <div
        //paste the componentRef here
        className="p-5 grid place-items-center w-full min-w-fit "
      >
        <p
          className={`${toggleTextTheme} font-semibold text-2xl capitalize text-center`}
        >{`${"Rent"} Breakdown`}</p>
        <div className="grid  mt-5 pb-10 h-[40rem]">
          <div className="">
            <h3 className={titleClass}>Product quantities</h3>
            <div className="grid grid-rows place-items-center gap-5 overflow-auto">
              {header}
              {(productCount?.rent_breakdown as any[])?.map((chi, idx) => {
                const { product_name, quantity } = chi;
                return (
                  <div className={colClass}>
                    <p className={textClass}>{product_name}</p>
                    <p className="font-semibold text-right border-l-2">
                      {quantity}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* second layer */}
          {/* <div>
          <h3 className={titleClass}>Out of stock products</h3>
          <div className="grid grid-rows place-items-center gap-5 overflow-auto">
            {header}
            {(productCount?.out_of_stock_products as any[])?.map((chi, idx) => {
              const { product_name, quantity } = chi;
              return (
                <div className={colClass}>
                  <p className={textClass}>{product_name}</p>
                  <p className="font-semibold text-right">{quantity}</p>
                </div>
              );
            })}
          </div>
        </div> */}
        </div>
      </div>
    </>
  );

  return (
    <>
      {totalCards}

      <SalesTable
        isReport
        isNotDeleteable
        isNotEditable
        loaderBottom
        dntShowAdd
        isRentTab={isRent}
        setIsEdit={setEdit}
        dateRange={true}
        setStartDate={setFromDate}
        setEndDate={setToDate}
        loading={isRent ? isLoadingRent : isLoadingInvoices}
        // loading={isRent ? isLoadingRent : isLoadingProducts}
        isFetching={isRent ? isFetchingRent : isFetching}
        onOpenAddModal={onOpenAddSalesModal}
        onEditChild={setChildData}
        salesTableBody={rents as RentArray[]}
        invoiceTableBody={sales}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={SalesTabEnum.RENT && rentLastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddSalesModal}
        // onClose={closeAddSalesModal}
        onClose={() => {
          closeAddSalesModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddSales
          closeModal={() => {
            closeAddSalesModal();
            setEdit(false);
          }}
          isRentTab={isRent}
          isEdit={edit}
          data={childData as SalesArray}
        />
      </DashboardModal>
      {/* modal wrap end */}
      <DashboardModal
        width="50%"
        open={openBreakModal}
        onClose={closeBreakModal}
      >
        {breakModal}
      </DashboardModal>
    </>
  );
}
