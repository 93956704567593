import { Frame } from "component/Frame";
import { TitleText, col } from "component/Typography";
import { selectOption } from "./utils";
import { TabArrowDown, TabArrowUp } from "assets/images/svgs";
import { useDashboardStockHistory } from "./useDashboardStockHistory";
import { SelectField } from "component/Select";
import { useGetDashboardStockHistoryQuery } from "../../redux/services/dashboardApi";
import { formatNumberWithCommas } from "helpers/helper";
import Skeleton from "react-loading-skeleton";
import { useSidebar } from "component/Sidebar/useSidebar";

const DashboardStockHistory = () => {
  const { handlePercentage, onPeriodChange, period } =
    useDashboardStockHistory();

  const {
    data: stockData,
    isLoading,
    isFetching,
  } = useGetDashboardStockHistoryQuery({
    filter_by: period.value as any,
  });

  // console.log(stockData, "stock data");
  const stocks = stockData ?? [];

  const stock_history = [
    {
      label: "Total Sales Item",
      value: `${stocks?.currency || "₦"}${formatNumberWithCommas(
        stocks?.total_sales ?? 0
      )}`,
      isIncreament: true,
      percentage: stocks?.percentage || "-- --",
    },
    // {
    //   label: "Total Sales Return Item",
    //   value: 3,
    //   isIncreament: false,
    //   percentage: 15,
    // },
    {
      label: "Total Purchase Item",
      value: `${stocks?.currency || "₦"}${formatNumberWithCommas(
        stocks?.total_purchase ?? 0
      )}`,
      isIncreament: true,
      percentage: stocks?.percentage || "-- --",
    },
    // {
    //   label: "Total Purchase Return Item",
    //   value: 1,
    //   isIncreament: false,
    //   percentage: 5,
    // },
  ];
  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#181717cc]" : "bg-[#D9D9D9CC]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggleLabelTheme = isDarkTheme ? "text-[#f7f7f7cc]" : "text-gray-600";

  const stockHistory = (
    <div className="flex flex-col gap-[2rem]  mt-5">
      {stock_history.map((chi, idx) => {
        const { label, value, isIncreament, percentage } = chi;
        return (
          <div
            key={idx}
            className={`${toggledBgTheme} transition-all flex flex-col py-3 gap-4 border-0 h-[200px] rounded-[10px] p-[10px] pb-0`}
          >
            <p className={`text-3xl ${toggleLabelTheme} font-bold`}>{label}</p>
            <div className="flex justify-between mt-auto ">
              <p className={`text-2xl ${toggleTextTheme} font-bold`}>
                {isLoading || isFetching ? (
                  <Skeleton
                    baseColor="#424141cc"
                    highlightColor="#F5F5F5"
                    width={100}
                    height={20}
                  />
                ) : (
                  value
                )}
              </p>
              <div className="flex gap-1 items-center mt-4 mb-1 ">
                {isIncreament ? <TabArrowUp /> : <TabArrowDown />}
                <p
                  className={`text-[8.5px] ${handlePercentage(isIncreament)}`}
                >{`${percentage}`}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
  return (
    <Frame width="w-2/5" className="pb-3">
      <div className={col}>
        <TitleText value="Stock History" />
        <SelectField
          selectOptions={selectOption}
          onChange={onPeriodChange}
          value={period}
        />
      </div>
      {stockHistory}
    </Frame>
  );
};
export default DashboardStockHistory;
