import { useSidebar } from "component/Sidebar/useSidebar";
import { FrameTypes } from "./types";

const Frame = ({ children, className, rmPadding, bg, width }: FrameTypes) => {
  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "bg-[#202020]" : "bg-[#FFF]";

  return (
    <div
      className={`${bg || toggledBgTheme} transition-all  border-0 rounded-[10px] ${
        width || "w-full"
      } ${rmPadding ? "" : "p-5"} ${className}`}
    >
      {children}
    </div>
  );
};

export default Frame;
