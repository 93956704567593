import { generateQueryString } from "helpers/helper";
import { ProductApiResponse } from "../../../@types/product";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";

export const productApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createOutlet: builder.mutation({
      query: (payload) => ({
        url: "outlets/create-outlet",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["outlets"],
    }),

    updateOutlet: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `outlets/update-outlet/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["outlets"],
    }),

    getOutlets: builder.query<any, QueryParams>({
      query: (params) => ({
        url: `outlets/get-outlet${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["outlets"],
      transformResponse: (response) => response,
    }),

    getOutletsNoPagination: builder.query<any, void>({
      query: () => ({
        url: `/outlets`,
        method: "GET",
      }),
      providesTags: ["outlets"],
      transformResponse: (response) => response,
    }),

    deleteOutlet: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `outlets/delete-outlet/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["outlets"],
    }),

    downloadOutlet: builder.mutation<Blob, void>({
      query: () => ({
        url: `outlets/outlet-export`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      invalidatesTags: ["products"],
    }),
    exportOutlet: builder.mutation<Blob, void>({
      query: () => ({
        url: "outlets/outlet/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useCreateOutletMutation,
  useGetOutletsQuery,
  useUpdateOutletMutation,
  useDeleteOutletMutation,
  useDownloadOutletMutation,
  useExportOutletMutation,
  useGetOutletsNoPaginationQuery,
} = productApi;
