import StaffTable from "../../Staff/StaffTable";
import useDisclosure from "hooks/useDisclosure";
import DashboardModal from "component/Modal/Modal";
import { useState } from "react";
import AddStaff from "../../Staff/AddStaff";
import { handleDateFilter, useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useGetOutletsQuery } from "../../../redux/services/outletApi";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useGetOutletsReportsQuery } from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";

export default function OutletTab() {
  const {
    isOpen: openAddStaffModal,
    open: onOpenAddStaffModal,
    close: closeAddStaffModal,
  } = useDisclosure();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [searchVal, setSearchVal] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const {
    data: outletData,
    isLoading: isLoadingOutlet,
    isFetching: isFetchingOutlet,
  } = useGetOutletsReportsQuery({
    search: debouncedSearchVal,
    "time[start_date]": handleDateFilter("start_date", fromDate, toDate),
    "time[end_date]": handleDateFilter("end_date", fromDate, toDate),
    // filter_by: staffTab.value,
    page: page,
  });

  const outlets = outletData?.data;
  const outletLastPage = outletData?.last_page ?? 0;
  const { isDarkTheme } = useSidebar();
  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const totalCards = (
    <div
      // onClick={onOpenBreakModal}
      className={`${toggledCardTheme} hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p className={`${toggleTextTheme} text-black font-medium text-3xl`}>
        Total Outlet
      </p>
      <p className={`${toggleTextTheme} font-medium text-4xl mt-10`}>
        {isLoadingOutlet ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={40}
            height={30}
          />
        ) : (
          outletData?.total || "0"
        )}
      </p>
    </div>
  );

  const breakModal = (
    <div className="p-5 grid place-items-center">
      <p className="font-semibold text-2xl capitalize text-center">{`${"Product"} Breakdown`}</p>
      <div className="grid grid-rows place-items-center gap-10 mt-5">
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {totalCards}

      <StaffTable
        isReport
        isNotDeleteable
        isNotEditable
        dateRange={true}
        setStartDate={setFromDate}
        setEndDate={setToDate}
        dntShowAdd
        loaderBottom={true}
        isOutlet={true}
        setIsEdit={setEdit}
        staffTableBody={outlets}
        loading={isLoadingOutlet}
        isFetching={isFetchingOutlet}
        onOpenAddModal={onOpenAddStaffModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          count={outletLastPage}
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      {/* modal wrap start */}
      <DashboardModal
        open={openAddStaffModal}
        // onClose={closeAddProductModal}
        onClose={() => {
          closeAddStaffModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddStaff
          closeModal={() => {
            closeAddStaffModal();
            setEdit(false);
          }}
          isOutletTab={true}
          isEdit={edit}
          data={childData as any}
        />
      </DashboardModal>
      {/* modal wrap end */}
      <DashboardModal open={openBreakModal} onClose={closeBreakModal}>
        {breakModal}
      </DashboardModal>
    </>
  );
}
