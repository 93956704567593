import { useState } from "react";
import { ProductTabEnum } from "./utils";

type TabType = {
  label: string;
  value: string;
};

export const useProducts = ({ closeModal }: { closeModal: () => void }) => {
  const [productTab, setProductTab] = useState<TabType>({
    label: "All Category",
    value: "",
  });

  const handleProductTabChange = (tab: TabType) => {
    setProductTab(tab);
  };

  const isActiveTab = (tabValue: string) => {
    return productTab.value === tabValue
      ? "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
      : "";
  };

  return {
    productTab,
    handleProductTabChange,
    isActiveTab,
  };
};
