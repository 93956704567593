// export const StaffTabs = ["Staffs", "Outlets"];

export enum StafffTabEnum {
  OUTLETS = "outlets",
}

export const staffTabs = [
  { label: "Staffs", value: "" },
  { label: "Outlets", value: StafffTabEnum.OUTLETS },
];

export const sortOptions = [
  {
    label: "Images",
    value: "images",
  },
  {
    label: "Product name",
    value: "product_name",
  },
  {
    label: "Description",
    value: "description",
  },
];

export const staffTableHead = [
  "Staff name",
  "ID number",
  "Position",
  "Pay",
  "Outlet",
  "Address",
  "Contact no",
  "Guardian no",
  "DOB",
  "Action",
];

export const outletTableHead = [
  "Outlet name",
  "Address",
  "No of Staff",
  "Year opened",
  "Action"
];

export const staffTableBody = [
  {
    staff_name: "Hassan Lamidi",
    id_number: "6969696483",
    position: "Sales rep",
    pay: "₦40,000",
    outlet: "Egbeda",
    address: "30, Palace road",
    contact_no: "08177135933",
    guardian_no: "08177135933",
    dob: "20/11/1995",
  },
];
