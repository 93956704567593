import { generateQueryString } from "helpers/helper";
import { ProductApiResponse } from "../../../@types/product";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";

export const productApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createProduct: builder.mutation({
      query: (payload) => ({
        url: "/products/create-product",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["products"],
    }),

    getProducts: builder.query<ProductApiResponse, QueryParams>({
      query: (params) => ({
        url: `/products/get-product${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["products"],
      transformResponse: (response: { data: ProductApiResponse }) =>
        response.data,
    }),
    getProductsNoPaginate: builder.query<ProductApiResponse, void>({
      query: () => ({
        url: `/products`,
        method: "GET",
      }),
      providesTags: ["products"],
      transformResponse: (response: { data: ProductApiResponse }) =>
        response.data,
    }),

    getAllPurchases: builder.query<any, void>({
      query: () => ({
        url: `/purchase/all-purchase`,
        method: "GET",
      }),
      transformResponse: (response: { data: any }) => response.data,
    }),

    updateProduct: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `products/update-product/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["products"],
    }),

    deleteProduct: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `products/delete-product/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["products"],
    }),

    downloadProduct: builder.mutation<Blob, { format: string }>({
      query: ({ format }) => ({
        url: `products/download?format=${format}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      // invalidatesTags: ["products"],
    }),
    exportProduct: builder.mutation<Blob, void>({
      query: () => ({
        url: "products/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      // invalidatesTags: ["products"],
    }),
    filterByProduct: builder.query<void, { productName: string }>({
      query: ({ productName }) => ({
        url: `purchase/filter-purchase?productName=${productName}`,
        method: "GET",
      }),
      // invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useDownloadProductMutation,
  useExportProductMutation,
  useGetAllPurchasesQuery,
  useFilterByProductQuery,
  useGetProductsNoPaginateQuery,
} = productApi;
