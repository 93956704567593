import Cookies from "js-cookie";
import React, { ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface GuardProps {
  children: ReactNode;
  redirectTo: string;
}

const getToken = (): string | undefined => {
  return Cookies.get("token");
};

const Guard: React.FC<GuardProps> = ({ children, redirectTo }) => {
  const location = useLocation();
  const token = getToken();

  if (!token) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default Guard;
