import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { ProductsType } from "../../../@types/product";
import { formatDateToYYYYMMDD, useDebounce } from "helpers/helper";
import { useAddPurchase } from "./useAddPurchase";
import { useGetSalesOutletQuery } from "../../redux/services/salesApi";
import { OptionType } from "pages/Dashboard/types";
import { SelectField } from "component/Select";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useFilterByProductQuery } from "../../redux/services/productApi";
import { BtnLoader } from "component/Loader/Loader";
import DefaultCheckIcon from "assets/images/tsx/DefaultCheckIcon";
import { useGetOutletsNoPaginationQuery } from "../../redux/services/outletApi";

interface AddProductProps {
  closeModal: () => void;
  isEdit?: boolean;
  data: ProductsType;
}

const AddPurchase: React.FC<AddProductProps> = ({
  closeModal,
  isEdit,
  data,
}) => {
  const {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingProduct,
    productErrMessage,
    isProductError,
  } = useAddPurchase({
    closeModal,
    isUpdate: isEdit,
    productId: data?.id,
    data,
  });

  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3";

  // const { data: salesOutletsData, isLoading: isLoadingSalesOutlets } =
  //   useGetOutletsNoPaginationQuery();

  // const salesOutlets = salesOutletsData?.data?.[0] ?? [];
  // const handleFormatSalesOutletDrop = () => {
  //   const newData = salesOutlets?.map((chi: any) => ({
  //     ...chi,
  //     label: chi.outlets_name,
  //     value: chi.id,
  //   }));
  //   return newData;
  // };

  const statusOption = [
    {
      label: "Delivered",
      value: "delivered",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Returned",
      value: "returned",
    },
  ];

  const { isDarkTheme } = useSidebar();

  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <form onSubmit={formik.handleSubmit} className="p-6 px-10">
      <div>
        <p className={`${toggleTextTheme} transition-all font-medium text-xl`}>
          {isEdit ? "Update purchase" : "Add a new purchase"}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        <InputField
          type="text"
          label="Vendor"
          name="vendor"
          onChange={formik.handleChange}
          value={formik.values.vendor}
          placeholder="Enter vendor name"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Address"
          name="address"
          onChange={formik.handleChange}
          value={formik.values.address}
          placeholder="Enter address"
          className={inputClass}
          labelClassName={labelClass}
        />

        <InputField
          type="number"
          label="Quantity"
          name="quantity"
          onChange={formik.handleChange}
          value={formik.values.quantity}
          placeholder="Enter quantity"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          placeholder="Enter description"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Size"
          name="size"
          onChange={formik.handleChange}
          value={formik.values.size}
          placeholder="Enter size"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Prize"
          name="prize"
          onChange={formik.handleChange}
          value={formik.values.prize}
          placeholder="Enter prize"
          className={inputClass}
          labelClassName={labelClass}
        />

        <div className="flex flex-col">
          <label className={`${labelClass} ${toggleTextTheme} mb-1`}>
            Date
          </label>
          <Flatpickr
            value={formik.values.date}
            onChange={(selectedDates: Date[]) => {
              const [date] = selectedDates;
              formik.setFieldValue("date", formatDateToYYYYMMDD(date));
            }}
            placeholder="Enter date"
            className={`${inputClass} ${toggleTextTheme}  ${toggledDateTheme} rounded-lg border  outline-none h-[50px] `}
          />
        </div>

        {/* <div className="flex flex-col gap-1 -ml-3 ">
          <label className={`${labelClass} ml-3`}>Outlet of Sales</label>
          <SelectField
            value={formik.values.outlet as OptionType}
            selectOptions={handleFormatSalesOutletDrop()}
            onChange={(e: any) => {
              formik.setFieldValue("outlet", e);
              formik.setFieldValue("outlet.id", e?.value);
            }}
            placeholder="Select outlet.."
            className={`${inputClass} !w-full`}
            padding="7px"
            bgColor="white"
            isLoading={isLoadingSalesOutlets}
          />
        </div> */}

        <InputField
          type="text"
          label="Outlet"
          name="outlet"
          onChange={formik.handleChange}
          value={formik.values.outlet}
          placeholder="Enter outlet"
          className={inputClass}
          labelClassName={labelClass}
        />
        <div>
          <InputField
            type="text"
            label="product name"
            name="product_name"
            onChange={formik.handleChange}
            value={formik.values.product_name}
            placeholder="Enter product name"
            className={inputClass}
            labelClassName={labelClass}
          />
          <div className="mt-2">
            {isLoadingProduct ? (
              <BtnLoader textClass={`${toggleTextTheme} text-xs`} />
            ) : null}
          </div>
          {productErrMessage ? (
            <p className="text-red-500 text-xs">{productErrMessage}</p>
          ) : null}
        </div>

        <div className="flex flex-col gap-1 -ml-3 ">
          <label className={`${labelClass} ${toggleTextTheme} ml-3`}>
            Status
          </label>
          <SelectField
            value={formik.values.status as OptionType}
            selectOptions={statusOption}
            onChange={(e: any) => {
              formik.setFieldValue("status", e);
              formik.setFieldValue("status.value", e?.value);
            }}
            placeholder="Select status.."
            className={`${inputClass} !w-full`}
            padding="7px"
            bgColor={isDarkTheme ? "#222121" : "white"}
          />
        </div>
      </div>
      {isEdit && (
        <p className="text-xs mt-10 text-yellow-500 font-medium">
          Note: The product name must either be{" "}
          <span className="font-bold">"{data?.product_name}"</span> or an <br />
          updated product name but must not be an exisiting product
        </p>
      )}
      <div className="flex justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Submit}
          label={isEdit ? "Update" : "Save"}
          className="w-[284px]"
          // disabled={!formik.isValid || !formik.dirty || isProductError}
          disabled={
            !formik.isValid || !formik.dirty || (isProductError && !isEdit)
          }
          loading={isEdit ? isLoadingUpdate : isLoading}
        />
      </div>
    </form>
  );
};

export default AddPurchase;
