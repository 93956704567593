import { OutletIcon, TabArrowDown, TabArrowUp } from "assets/images/svgs";
import { useDashboardTabs } from "./utils";
import { useNavigate } from "react-router";
import { useGetAllTotalOutletsQuery } from "../../redux/services/dashboardApi";
import { OutletCount } from "../../../@types/dashboard";
import Skeleton from "react-loading-skeleton";
import { useSidebar } from "component/Sidebar/useSidebar";

const DashboardTabs = () => {
  const navigate = useNavigate();
  const { dashboard_tabs } = useDashboardTabs();
  const { data: outletData, isLoading: isLoadingOutlets } =
    useGetAllTotalOutletsQuery();

  const outlets = (outletData as OutletCount) ?? [];

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleInnerBgTheme = isDarkTheme ? "bg-[#2a2929]" : "bg-[#fff]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const outletTab = (
    <div
      className={`${toggleInnerBgTheme} transition-all p-5 border-0 rounded-[10px]  flex flex-col gap-3 w-full`}
      onClick={() => navigate("/staffs-outlets")}
    >
      <OutletIcon />
      <div className="mt-2">
        <p className="text-[16px]">Total Outlet</p>
        <p className={`${toggleTextTheme} text-2xl`}>
          {isLoadingOutlets ? (
            <Skeleton
              baseColor="#E0E0E0"
              highlightColor="#F5F5F5"
              width={200}
              height={20}
            />
          ) : (
            outlets?.total_outlets ?? "----- -----"
          )}
        </p>
        <div className="flex gap-2 items-center mt-3">
          <p className="text-[#04B4FC] text-[11px]">
            {isLoadingOutlets ? (
              <Skeleton
                baseColor="#E0E0E0"
                highlightColor="#F5F5F5"
                width={30}
                height={12}
              />
            ) : (
              outlets?.total_staffs ?? "--- ---"
            )}
          </p>
          <p className="text-[11px]">
            {isLoadingOutlets ? (
              <Skeleton
                baseColor="#E0E0E0"
                highlightColor="#F5F5F5"
                width={60}
                height={12}
              />
            ) : (
              "Employees"
            )}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`${toggledBgTheme} transition-all px-[17px] py-[20px] flex w-full gap-5 cursor-pointer justify-between border-0 rounded-[20px]`}
    >
      {dashboard_tabs.map((chi, idx) => {
        const {
          label,
          amount,
          isIncreament,
          percentage,
          period,
          icon: Icon,
          path,
          loading,
        } = chi;
        return (
          <div
            key={idx}
            className={`${toggleInnerBgTheme}  transition-all p-5 border-0 rounded-[10px] flex flex-col gap-3 w-full`}
            onClick={() => navigate(path)}
          >
            <Icon />
            <div className="mt-2">
              <p className="text-[16px]">{label}</p>
              <p className={`${toggleTextTheme} text-2xl`}>{amount}</p>
              <div className="flex gap-2 items-center mt-3">
                <div className="flex gap-1 items-center">
                  {isIncreament ? <TabArrowUp /> : <TabArrowDown />}
                  <p className="text-[#04B4FC] text-[11px]">{percentage}</p>
                </div>
                <p className="text-[11px]">{period}</p>
              </div>
            </div>
          </div>
        );
      })}
      {outletTab}
    </div>
  );
};

export default DashboardTabs;

// import { OutletIcon, TabArrowDown, TabArrowUp } from "assets/images/svgs";
// import { useDashboardTabs } from "./utils";
// import { useNavigate } from "react-router";
// import { useGetAllTotalOutletsQuery } from "../../redux/services/dashboardApi";
// import { OutletCount } from "../../../@types/dashboard";
// import Skeleton from "react-loading-skeleton";

// const DashboardTabs = () => {
//   const navigate = useNavigate();
//   const { dashboard_tabs } = useDashboardTabs();
//   const { data: outletData, isLoading: isLoadingOutlets } =
//     useGetAllTotalOutletsQuery();

//   const outlets = (outletData as OutletCount) ?? [];

//   const outletTab = (
//     <div
//       className="bg-[#222121] p-5 border-0 rounded-[10px] flex flex-col gap-3 w-full"
//       onClick={() => navigate("/staffs-outlets")}
//     >
//       <OutletIcon />
//       <div className="mt-2">
//         <p className="text-[16px]">Total Outlet</p>
//         <p className="text-white text-2xl">
//           {isLoadingOutlets ? (
//             <Skeleton
//               baseColor="#E0E0E0"
//               highlightColor="#F5F5F5"
//               width={200}
//               height={20}
//             />
//           ) : (
//             outlets?.total_outlets ?? "----- -----"
//           )}
//         </p>
//         <div className="flex gap-2 items-center mt-3">
//           <p className="text-[#04B4FC] text-[11px]">
//             {isLoadingOutlets ? (
//               <Skeleton
//                 baseColor="#E0E0E0"
//                 highlightColor="#F5F5F5"
//                 width={30}
//                 height={12}
//               />
//             ) : (
//               outlets?.total_staffs ?? "--- ---"
//             )}
//           </p>
//           <p className="text-[11px]">
//             {isLoadingOutlets ? (
//               <Skeleton
//                 baseColor="#E0E0E0"
//                 highlightColor="#F5F5F5"
//                 width={60}
//                 height={12}
//               />
//             ) : (
//               "Employees"
//             )}
//           </p>
//         </div>
//       </div>
//     </div>
//   );

//   return (
//     <div className="bg-[#3b3b3b] px-[17px] py-[20px] flex w-full gap-5 cursor-pointer justify-between border-0 rounded-[20px]">
//       {dashboard_tabs.map((chi, idx) => {
//         const {
//           label,
//           amount,
//           isIncreament,
//           percentage,
//           period,
//           icon: Icon,
//           path,
//           loading,
//         } = chi;
//         return (
//           <div
//             key={idx}
//             className="bg-[#222121] p-5 border-0 rounded-[10px] flex flex-col gap-3 w-full"
//             onClick={() => navigate(path)}
//           >
//             <Icon />
//             <div className="mt-2">
//               <p className="text-[16px]">{label}</p>
//               <p className="text-white text-2xl">{amount}</p>
//               <div className="flex gap-2 items-center mt-3">
//                 <div className="flex gap-1 items-center">
//                   {isIncreament ? <TabArrowUp /> : <TabArrowDown />}
//                   <p className="text-[#04B4FC] text-[11px]">{percentage}</p>
//                 </div>
//                 <p className="text-[11px]">{period}</p>
//               </div>
//             </div>
//           </div>
//         );
//       })}
//       {outletTab}
//     </div>
//   );
// };

// export default DashboardTabs;
