import { ExcelIcon, PdfIcon, PrintIcon } from "assets/images/tsx";
import { SearchField } from "component/Form";
import { Frame } from "component/Frame";
import { TitleText } from "component/Typography";
import { Table, TableBodyData } from "component/Table";
import useDisclosure from "hooks/useDisclosure";
import { useRef, useState } from "react";
import { SalesArray } from "../../../@types/sales";
import {
  useDownloadSalesMutation,
  useExportSalesMutation,
} from "../../redux/services/salesApi";
import { toast } from "sonner";
import { useReactToPrint } from "react-to-print";
import DashboardModal from "component/Modal/Modal";

import { PageLoader } from "component/Loader/Loader";
import { RentArray } from "../../../@types/rent";

import {
  useDownloadCreditsMutation,
  useExportCreditsMutation,
} from "../../redux/services/creditApi";
import { useDownloadDebitsMutation } from "../../redux/services/debitApi";
import { creditTableHead, debitTableHead } from "./utils";
import DeleteCredit from "./deleteCredit";
import { useSidebar } from "component/Sidebar/useSidebar";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import {
  formatDateToYYYYMMDD,
  formatNumberWithCommas,
  normalizeToUTC,
} from "helpers/helper";

const CreditTable = ({
  creditTableBody,
  loading,
  onOpenAddModal,
  isFetching,
  onEditChild,
  setIsEdit,
  search,
  handleSearchTable,
  isDebitTab,
  loaderBottom,
  dntShowAdd,
  setStartDate,
  setEndDate,
  dateRange,
  isNotDeleteable,
  isNotEditable,
  isReport,
}: {
  creditTableBody: any[];
  loading?: boolean;
  isFetching?: boolean;
  onEditChild: (param: any) => void;
  onOpenAddModal: () => void;
  setIsEdit: (param: boolean) => void;
  search: string;
  handleSearchTable: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDebitTab?: boolean;
  loaderBottom?: boolean;
  dntShowAdd?: boolean;
  setStartDate?: (param: string) => void;
  setEndDate?: (param: string) => void;
  dateRange?: boolean;
  isNotDeleteable?: boolean;
  isNotEditable?: boolean;
  isReport?: boolean;

  // onTableClick: () => void;
}) => {
  const {
    isOpen: openDeleteModal,
    open: onOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();

  const [childData, setChildData] = useState<SalesArray>();

  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (icon: string) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const togglePDFIconTheme = isDarkTheme ? "stroke-white" : "";
  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const toggleExcelIconTheme = isDarkTheme ? "fill-white" : "";
  const hoverBgTheme = isDarkTheme
    ? "bg-[#222121] shadow-white-sm"
    : "bg-white shadow-xl";
  const hoverTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const hoverClass = `text-xs rounded-lg text-center ${hoverTextTheme}`;

  const [downloadCredit] = useDownloadCreditsMutation();
  const [exportCredit] = useExportCreditsMutation();

  const [downloadDebit] = useDownloadDebitsMutation();
  const [exportDebit] = useDownloadDebitsMutation();

  const handleExport = async (param: string) => {
    toast.loading("Downloading...");
    try {
      let blob: Blob;
      let fileName: string;

      switch (true) {
        case isDebitTab && param === "excel":
          blob = await downloadDebit().unwrap();
          fileName = "debit.xlsx";
          break;
        case isDebitTab && param === "pdf":
          blob = await exportDebit().unwrap();
          fileName = "debit.pdf";
          break;
        case !isDebitTab && param === "excel":
          blob = await downloadCredit().unwrap();
          fileName = "credit.xlsx";
          break;
        case !isDebitTab && param === "pdf":
          blob = await exportCredit().unwrap();
          fileName = "credit.pdf";
          break;
        default:
          toast.dismiss();
          toast.error("Invalid export format");
          return;
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.dismiss();
      toast.success(`Exported successfully as ${fileName}`);
    } catch (error) {
      toast.dismiss();
      toast.error(`Export to ${param.toUpperCase()} failed`);
      console.error("Download failed", error);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Products Data",
  });
  const inputClass = "pl-3 !h-[45px]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";

  const topData = (
    <div className="flex justify-between items-center p-4">
      <div className="flex gap-8 items-center">
        {/* Excel Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("excel")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("excel")}
        >
          <ExcelIcon
            className={`cursor-pointer transition-all ${toggleExcelIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "excel" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to Excel</p>
          </div>
        </div>

        {/* Print Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("print")}
          onMouseLeave={handleMouseLeave}
          onClick={handlePrint}
        >
          <PrintIcon
            className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "print" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Print</p>
          </div>
        </div>

        {/* PDF Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("pdf")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("pdf")}
        >
          <PdfIcon
            className={`cursor-pointer transition-all ${togglePDFIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] mt-3 transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "pdf" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to PDF</p>
          </div>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-xs font-medium">Search</label>
          <SearchField
            name="search"
            onChange={handleSearchTable}
            value={search}
          />
        </div>

        {/* <SelectField
          showFilterIcon
          borderColor="#999898"
          padding="0 0 0 2.5rem"
          bgColor="#fff"
          placeholder="Sort by"
          value={sortVal}
          selectOptions={sortOptions}
          onChange={onSortChange}
        /> */}
        {dateRange && (
          <div className="flex gap-5 items-center">
            <div className="flex flex-col gap-2">
              <label className="text-xs font-medium">From Date</label>
              <Flatpickr
                // value={}
                onChange={(selectedDates: Date[]) => {
                  const [date] = selectedDates;

                  setStartDate &&
                    setStartDate(formatDateToYYYYMMDD(normalizeToUTC(date)));
                }}
                placeholder="Start date.."
                className={`${inputClass} ${toggleTextTheme}  rounded-lg border outline-none h-[50px] ${toggledDateTheme} `}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-xs font-medium">To Date</label>
              <Flatpickr
                value={""}
                onChange={(selectedDates: Date[]) => {
                  const [date] = selectedDates;
                  // formik.setFieldValue("date", formatDateToYYYYMMDD(date));
                  setEndDate &&
                    setEndDate(formatDateToYYYYMMDD(normalizeToUTC(date)));
                }}
                placeholder="End date.."
                className={`${inputClass} ${toggleTextTheme}  rounded-lg border outline-none h-[50px] ${toggledDateTheme} `}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // console.log(creditTableBody, "data check");
  const salesBody = (
    <div className="overflow-auto h-full">
      {/* <div className="overflow-auto h-[700px]"> */}
      {loading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            className={`flex flex-col ${
              loaderBottom && "mt-[20rem]"
            } justify-center items-center`}
          >
            <PageLoader />
          </div>
        </div>
      ) : creditTableBody?.length > 0 ? (
        creditTableBody?.map((chi, idx) => {
          const {
            product_sold,
            product_bought,
            customer_name,
            vendor_name,
            quantity,
            unit_price,
            cost_price,
            date,
          } = chi;
          return (
            <TableBodyData
              dataLength={7}
              loading={isFetching}
              key={idx}
              one={product_sold || product_bought || "--- ---"}
              two={customer_name || vendor_name || "--- ---"}
              three={quantity || "--- ---"}
              four={`${"₦"}${formatNumberWithCommas(unit_price ?? 0)}`}
              five={`${"₦"}${formatNumberWithCommas(cost_price ?? 0)}`}
              six={date}
              sixClass={(isReport && "text-right") as string}
              onEdit={() => {
                onEditChild(chi);
                setIsEdit(true);
                onOpenAddModal();
              }}
              onDelete={() => {
                setChildData(chi);
                onOpenDeleteModal();
              }}
              isNotDeletable={isNotDeleteable}
              isNotEditable={isNotEditable}
            />
          );
        })
      ) : (
        <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          {isDebitTab ? "No debit listed here..." : "No credit listed here..."}
          {!dntShowAdd && (
            <span
              className="text-[#4A07A0] text-sm cursor-pointer"
              onClick={onOpenAddModal}
            >
              {isDebitTab ? "Add Debit" : "Add Credit"}
            </span>
          )}
        </p>
      )}
    </div>
  );

  return (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-0 overflow-auto h-[650px] pb-10"
    >
      <div className="p-4 px-6 border-b border-[#999898] ">
        <TitleText value={isDebitTab ? "All Debit" : "All Credit"} />
      </div>
      {topData}
      <div ref={componentRef}>
        <Table
          // tableHead={isDebitTab ? debitTableHead : creditTableHead}
          tableHead={
            isReport && isDebitTab
              ? debitTableHead?.filter((chi) => chi !== "Action")
              : isDebitTab
              ? debitTableHead
              : isReport
              ? creditTableHead?.filter((chi) => chi !== "Action")
              : creditTableHead
          }
          tableBody={salesBody}
          dataLength={creditTableHead.length}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openDeleteModal}
        onClose={closeDeleteModal}
        // width={"749px"}
      >
        <DeleteCredit
          data={childData}
          onCancel={closeDeleteModal}
          isDebitTab={isDebitTab}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Frame>
  );
};

export default CreditTable;
