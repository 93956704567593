import { useSidebar } from "component/Sidebar/useSidebar";
import { SwitchType } from "./types";

const Switch = ({ isChecked, toggleSwitch, themeMode }: SwitchType) => {
  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "bg-[#3c3b3b]" : "bg-black";
  const toggleInnerBgTheme = isDarkTheme ? "bg-black" : "bg-white";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  return (
    <div
      className={`relative inline-block h-10 transition-all ${toggledBgTheme} rounded-full p-1 w-full`}
    >
      <label
        htmlFor="toggle"
        className={`block h-8 w-[120px] cursor-pointer rounded-full transition-all ${toggleInnerBgTheme} shadow-md transform duration-300 ease-in-out ${
          isChecked ? "translate-x-[6.5rem]" : ""
        }`}
      >
        <span
          className={`absolute inset-0 flex items-center justify-center transition-all ${toggleTextTheme} text-xs font-normal`}
        >
          {themeMode}
        </span>
        <input
          id="toggle"
          type="checkbox"
          className="w-0 h-0 opacity-0"
          checked={isChecked}
          onChange={toggleSwitch}
        />
      </label>
    </div>
  );
};
export default Switch;
