import { useSidebar } from "component/Sidebar/useSidebar";
import { SelectStyleType } from "./types";

export const useSelect = ({
  bgColor,
  borderColor,
  padding,
  fontSize,
}: SelectStyleType) => {
  const { isDarkTheme } = useSidebar();
  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      outline: "none",
      boxShadow: "none",
      fontFamily: "Inter",
      // width:"50rem",
      padding: padding,
      borderRadius: ".5rem",
      cursor: "pointer",
      backgroundColor: bgColor || (isDarkTheme ? "#121212" : "#D9D9D9"),
      fontSize: fontSize,
      // border: "none",
      borderColor: borderColor || "#D9D9D9",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#999898",
      fontSize: "16px",
      fontWeight: "500",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#6D6A6A",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#4A07A0"
        : state.isFocused
        ? "#E2E2E2"
        : isDarkTheme
        ? "#121212"
        : "#FFFFFF",
      color: state.isSelected ? "#FFFFFF" : "#6D6A6A",
      cursor: "pointer",
      fontSize: "14px",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#6D6A6A",
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "12px",
    }),
  };
  return {
    selectStyle,
  };
};
