import { Navbar } from "component/Navbar";
import { Sidebar } from "component/Sidebar";
import { LayoutTypes } from "./types";

const Layout = ({ children }: LayoutTypes) => {
  return (
    <div className="flex">
      <Sidebar />
      <Navbar />
      <div>{children}</div>
    </div>
  );
};

export default Layout;
