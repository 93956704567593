import { SingleUser, User } from "../../../@types/auth";
import { LoginHistory, UserPreference } from "../../../@types/settings";
import { baseApi } from "../baseApi";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation<void, { payload: FormData; id: number }>({
      query: ({ payload, id }) => ({
        url: `/admin/Update-profile/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["userDetails"],
    }),

    inviteUser: builder.mutation({
      query: (payload) => ({
        url: "/admin/Admin-invite",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["invitedUsers"],
    }),

    getAllInvitedUsers: builder.query<SingleUser[], void>({
      query: () => ({
        url: "/admin/All-users",
        method: "GET",
      }),
      providesTags: ["invitedUsers"],
      transformResponse: (response: { data: SingleUser[] }) => response.data,
    }),

    getAllLoginHistory: builder.query<LoginHistory[], void>({
      query: () => ({
        url: "/admin/Login-history",
        method: "GET",
      }),
      transformResponse: (response: { data: LoginHistory[] }) => response.data,
    }),

    updateRoles: builder.mutation<void, { name: string; id: number }>({
      query: ({ name, id }) => ({
        url: `/admin/users/${id}/role`,
        method: "PUT",
        body: { name },
      }),
      invalidatesTags: ["invitedUsers"],
    }),

    deleteInvitedUser: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `admin/Users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["invitedUsers"],
    }),
    updateUserPreference: builder.mutation({
      query: (payload) => ({
        url: `admin/user/preferences`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["userPreferences"],
    }),
    getAllUserPreferences: builder.query<UserPreference, void>({
      query: () => ({
        url: "/admin/user/preferences",
        method: "GET",
      }),
      providesTags: ["userPreferences"],
      transformResponse: (response: UserPreference) => response,
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: "admin/ChangePassword",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useInviteUserMutation,
  useGetAllInvitedUsersQuery,
  useGetAllLoginHistoryQuery,
  useUpdateRolesMutation,
  useDeleteInvitedUserMutation,
  useUpdateUserPreferenceMutation,
  useGetAllUserPreferencesQuery,
  useChangePasswordMutation,
} = authApi;
