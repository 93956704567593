import useDisclosure from "hooks/useDisclosure";
import React, { useRef, useState } from "react";
import DashboardModal from "component/Modal/Modal";
import { formatNumberWithCommas, useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";
import InvoicePreviewModal from "pages/Invoice/invoicePreview";
import InvoiceTable from "../../Invoice/InvoiceTable";
import AddInvoiceModal from "pages/Invoice/AddInvoiceModal";
import { useGetInvoicesQuery } from "../../../redux/services/invoiceApi";
import { Invoices } from "../../../../@types/invoice";
import { useGetReportsQuery } from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";
import { useGetSalesAndInvoiceBreakdownQuery } from "../../../redux/services/reportApi";
import { PageLoader } from "component/Loader/Loader";
import { Table, TableBodyData } from "component/Table";
import { useReactToPrint } from "react-to-print";
import { PrintIcon } from "assets/images/tsx";

export default function InvoiceTab() {
  const {
    isOpen: openAddInvoiceModal,
    open: onOpenAddInvoiceModal,
    close: closeAddInvoiceModal,
  } = useDisclosure();

  const {
    isOpen: openInvoiceTemplateModal,
    open: onOpenInvoiceTemplateModal,
    close: closeInvoiceTemplateModal,
  } = useDisclosure();

  const {
    data: productCount,
    isLoading: isLoadingProductsCount,
    isFetching: isFetchingProductCount,
  } = useGetSalesAndInvoiceBreakdownQuery({
    // search: debouncedSearchVal,
    // filter_by: productTab.value,
    // page: page,
  });

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<Invoices>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleDateFilter = (date: string) => {
    if (date === "start_date" && fromDate && toDate) {
      return fromDate;
    } else if (date === "end_date" && fromDate && toDate) {
      return toDate;
    } else {
      return null;
    }
  };
  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useGetReportsQuery({
    search: debouncedSearchVal,
    "time[start_date]": handleDateFilter("start_date"),
    "time[end_date]": handleDateFilter("end_date"),
    // search_by: productTab.value,
    // filter_by: invoiceTab.value,
    page: page,
  });

  const lastPage = invoicesData?.last_page;
  const invoices = invoicesData?.data ?? [];

  const { isDarkTheme } = useSidebar();

  const [invoiceID, setInvoiceID] = useState<number>();

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();

  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const totalCards = (
    <div
      onClick={onOpenBreakModal}
      className={`${toggledCardTheme} cursor-pointer hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p className={`${toggleTextTheme} text-black font-medium text-3xl`}>
        Total Invoice
      </p>
      <p className="font-medium text-4xl mt-10">
        {isLoadingInvoices ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={40}
            height={20}
          />
        ) : (
          invoicesData?.total || "0"
        )}
      </p>
    </div>
  );
  const titleClass = `font-medium font-bold text-center w-full p-2 ${toggleTextTheme} min-w-fit mb-3`;
  const colClass = `grid grid-cols-2 border-b-2 p-2 w-full gap-10 ${toggleTextTheme}`;
  const textClass = "text-[#4A07A0] font-semibold";

  const header = (
    <div className={colClass}>
      <p className="font-semibold">Items</p>
      <p className="font-semibold text-right border-l-2">Quantities</p>
    </div>
  );

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice Data",
  });

  const headerLabels = [
    "Items",
    "Quantities",
    "Total Amount",
    "Created At",
    "Invoice To",
    "Delivered To",
    "Payment Method",
    "Entry Officer",
    "Address",
    "Balance",
    "Invoice No",
    "Discount",
  ];

  const breakModalBody = (
    <div className="overflow-auto">
      {isLoadingProductsCount ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className={`flex flex-col justify-center items-center`}>
            <PageLoader />
          </div>
        </div>
      ) : (productCount?.sales_breakdown as any[])?.length > 0 ? (
        (productCount?.sales_breakdown as any[])?.map((chi, idx) => {
          // console.log(chi?.invoices_breakdown, "breakdown");
          const {
            product_name,
            quantity,
            total_amount,
            created_at,
            invoice_to,
            delivered_to,
            payment_method,
            entry_officer,
            address,
            balance,
            invoice_no,
            discount,
          } = chi;
          return (
            <TableBodyData
              loading={isFetching}
              dataLength={12}
              key={idx}
              one={product_name}
              two={quantity}
              five={`${"₦"}${formatNumberWithCommas(total_amount ?? 0)}`}
              eight={created_at}
              nine={invoice_to}
              ten={delivered_to}
              eleven={payment_method}
              twelve={entry_officer}
              thirteen={address}
              fourteen={`${"₦"}${formatNumberWithCommas(balance ?? 0)}`}
              fifteen={invoice_no}
              // fifteenClass="text-right"
              sixteen={`${"₦"}${formatNumberWithCommas(discount ?? 0)}`}
              sixteenClass="text-right"
              isNotDeletable
              isNotEditable
              // onEdit={() => {
              //   onEditChild(chi);
              //   setInvoiceId(id);
              //   setIsEdit(true);
              //   onOpenAddModal();
              // }}
              // onDelete={() => {
              //   setChildData(chi);
              //   onOpenDeleteModal();
              // }}
              // isNotDeletable={isNotDeleteable}
              // isNotEditable={isNotEditable}
            />
          );
        })
      ) : (
        <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          No Invoice Breakdown
        </p>
      )}
    </div>
  );

  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";

  const breakModal = (
    <>
      <div className="m-5" onClick={handlePrint}>
        <PrintIcon
          className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
        />
      </div>
      <div ref={componentRef}>
        <h1 className={`${toggleTextTheme} text-center p-5`}>
          Invoice Breakdown
        </h1>
        <Table
          className=""
          tableHead={headerLabels}
          dataLength={12}
          tableBody={breakModalBody}
        />
      </div>
    </>
  );

  return (
    <>
      {totalCards}

      <div className="overflow-auto">
        {/* <InvoiceTable invoiceTableBody={[]} /> */}
        <InvoiceTable
          // theadLength={9}
          isReport
          isNotDeleteable
          isNotEditable
          dateRange={true}
          setStartDate={setFromDate}
          setEndDate={setToDate}
          dntShowGenInvoice
          loaderBottom={true}
          setInvoiceId={setInvoiceID}
          setIsEdit={setEdit}
          invoiceTableBody={invoices}
          loading={isLoadingInvoices}
          onOpenAddModal={onOpenAddInvoiceModal}
          onEditChild={setChildData}
          handleSearchTable={(e) => setSearchVal(e.target.value)}
          search={searchVal}
          isFetching={isFetching}
        />
      </div>

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddInvoiceModal}
        onClose={() => {
          closeAddInvoiceModal();
          setEdit(false);
        }}
        width={"70%"}
      >
        <AddInvoiceModal
          closeModal={() => {
            closeAddInvoiceModal();
            setEdit(false);
          }}
          invoiceID={invoiceID as number}
          isEdit={edit}
          data={childData as Invoices}
          onOpenTemplateModal={onOpenInvoiceTemplateModal}
        />
      </DashboardModal>
      {/* modal wrap end */}

      {/* ------- modal wrap start -----. */}
      <DashboardModal
        open={openInvoiceTemplateModal}
        onClose={closeInvoiceTemplateModal}
        width={"700px"}
        height={"85%"}
        borderRadius="0"
      >
        <InvoicePreviewModal />
      </DashboardModal>
      {/*-------  modal wrap end -----. */}
      <DashboardModal
        open={openBreakModal}
        onClose={closeBreakModal}
        width="80%"
        className="h-[calc(100%-10rem)]"
      >
        {breakModal}
      </DashboardModal>
    </>
  );
}
