import React, { useRef } from "react";
import InvoicePreviewTable from "./invoicePreviewTable";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { useAppSelector } from "../../redux/store";
import {
  selectAmount,
  selectOtherScannedItems,
  selectScannedItems,
} from "../../redux/features/scannedItemsSlice";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Logo } from "assets/images/svgs";
import { formatNumberWithCommas } from "helpers/helper";

const InvoicePreviewModal = () => {
  const topTextClass = "text-black font-normal text-[11px]";
  const headTextClass = "text-[#4A07A0] font-medium text-[16px]";
  const textClass = "text-black font-medium text-sm";
  const descClass = "text-[#999898] font-medium text-[11px] w-[110px]";
  const PayColClass = "flex gap-1 items-baseline";

  const invoiceTemplate = useAppSelector(selectScannedItems);
  const otherDetails = useAppSelector(selectOtherScannedItems);
  const amountDetails = useAppSelector(selectAmount);

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Products Data",
  });

  const handleDownloadPDF = async () => {
    if (componentRef.current) {
      const canvas = await html2canvas(componentRef.current);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("chrisanatex-invoice.pdf");
    }
  };
  const handleSaveAndPrint = () => {
    handlePrint();
    handleDownloadPDF();
  };

  return (
    <div className="bg-[#FAF9F6] p-10">
      <div ref={componentRef}>
        <div
          className=""
          style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <div className="text-center bg-[#FAF9F6] p-5 px-8 relative">
            <div className="grid place-items-center gap-3 ">
              <Logo className="w-[80px] absolute left-0 ml-2" />
              <p className="text-[#4A07A0] font-400 text-[25px] ml-2 font-normal">
                CHRISANATEX VENTURES LTD. COM
              </p>
            </div>
            <p className={topTextClass}>
              Sole Distributor ATTURO TYRES, Import, Export and General
              Merchandise.{" "}
            </p>
            <p className={topTextClass}>
              Sales and Distribution of Goodyear Tyres and Tubes.
            </p>
            <p className={`${topTextClass} mt-3`}>
              <span className="text-[#4A07A0]">HEAD OFFICE:</span> PEACE BLOCK,
              Shop A1 -005/006, Africa Tyre Village{" "}
            </p>
            <p className={topTextClass}>
              (ATV) Trade Fair Complex, Badagry Expressway, Lagos.
            </p>
            <p className={topTextClass}>
              <span className={topTextClass}>TEL:</span> 08033000628,
              08186732883
            </p>
          </div>
          <div className="bg-white p-5 px-8">
            <div className="flex justify-between items-center mt-10 mb-10  ">
              <div>
                <p className={headTextClass}>Invoice to</p>
                <p className={`${textClass} mt-3 mb-1`}>
                  {otherDetails?.invoice_to}
                </p>
                <p className={descClass}>{otherDetails?.address}</p>
              </div>
              <div>
                <p className={headTextClass}>Delivered to</p>
                <p className={`${textClass} mt-3 mb-1`}>
                  {otherDetails?.delivered_to}
                </p>
                <p className={descClass}>{otherDetails?.address}</p>
              </div>
              <div>
                <div className="w-full mt-11 flex flex-col gap-1">
                  <div className={PayColClass}>
                    <p className={`${textClass} `}>Payment method:</p>
                    <p className={descClass}>{otherDetails?.payment_method}</p>
                  </div>
                  <div className={PayColClass}>
                    <p className={`${textClass} `}>Invoice date:</p>
                    <p className={descClass}>{otherDetails?.invoice_date}</p>
                  </div>
                  <div>
                    <div className={PayColClass}>
                      <p className={`${textClass} `}>Invoice no:</p>
                      <p className={descClass}>{otherDetails?.invoice_no}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InvoicePreviewTable
              invoiceGenerateData={invoiceTemplate}
              otherDetails={otherDetails}
              amountDetails={amountDetails}
            />
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <div className="border-b border-black h-[60px] w-[210px] p-3 flex justify-center mt-5">
                  {otherDetails?.signature ? (
                    <img
                      src={otherDetails?.signature}
                      alt="signature"
                      className="h-[40px]"
                    />
                  ) : null}
                </div>
                <p className="text-xs mt-3">Staff Signature</p>
              </div>
              <div className="flex flex-col">
                <div className="border-b border-black h-[60px] w-[210px] p-3 flex justify-center mt-5">
                  {/* {otherDetails?.signature ? (
                    <img
                      src={otherDetails?.signature}
                      alt="signature"
                      className="h-[40px]"
                    />
                  ) : null} */}
                </div>
                <p className="text-xs mt-3">Customer Signature</p>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <p className="border-b border-black w-[210px] p-3 flex justify-center mt-5">
                  {otherDetails?.paid
                    ? "₦" +
                      " " +
                      formatNumberWithCommas(
                        String(otherDetails?.paid || "0.00")
                      )
                    : "n/a"}
                </p>
                <p className="text-xs mt-3">Amount (Paid)</p>
              </div>
              <div className="flex flex-col">
                <p className="border-b border-black w-[210px] p-3 flex justify-center mt-5">
                  {otherDetails?.balance
                    ? "₦" +
                      " " +
                      formatNumberWithCommas(
                        String(otherDetails?.balance || "0.00")
                      )
                    : "n/a"}
                </p>
                <p className="text-xs mt-3">Amount (Balance)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end px-5  mt-5">
        <Button
          btnType={ButtonTypeEnum.Button}
          label="Save & Print"
          className=" w-[235.5px]"
          onClick={handleSaveAndPrint}
        />
      </div>
    </div>
  );
};

export default InvoicePreviewModal;
