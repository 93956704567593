import { useFormik } from "formik";
import { toast } from "sonner";
import * as yup from "yup";
import { SalesArray } from "../../../@types/sales";
import {
  useCreateSalesMutation,
  useUpdateSalesMutation,
} from "../../redux/services/salesApi";
import {
  useCreateRentMutation,
  useUpdateRentMutation,
} from "../../redux/services/rentApi";

export const useAddSales = ({
  closeModal,
  isUpdate,
  salesId,
  data,
  isActiveRentTab,
}: {
  closeModal: () => void;
  isUpdate?: boolean;
  salesId: number;
  data: SalesArray;
  isActiveRentTab?: boolean;
}) => {
  const validationSchema = yup.object().shape({
    customer_name: yup.string().required("Customer name is required"),
    price: yup
      .number()
      .required("Price is required")
      .positive("Price must be a positive number"),
    date: yup.string().required("Date is required"),
  });
  const rentValidationSchema = yup.object().shape({
    customer_name: yup.string().required("Customer name is required"),
    price: yup
      .number()
      .required("Price is required")
      .positive("Price must be a positive number"),
    date: yup.string().required("Date is required"),
    return_date: yup.string().required("Return Date is required"),
  });

  const formValues = {
    customer_name: (isUpdate && data?.customer_name) || "",
    description: (isUpdate && data?.description) || "",
    quantity: (isUpdate && data?.quantity) || "",
    size: (isUpdate && data?.product?.size) || "",
    price: (isUpdate && (data?.price || data?.prize)) || "",
    date: (isUpdate && data?.product?.date) || "",
    product: (isUpdate && {
      label: data?.product?.product_name,
      value: data?.product?.id,
    }) || {
      label: "Select Product...",
      value: "",
    },
    outlet: (isUpdate && {
      label: data?.outlet?.name,
      value: data?.outlet?.id,
    }) || {
      label: "Select sales outlet...",
      value: "",
    },
    return_date: (isUpdate && data?.return_date) || "",
  };

  const [createSales, { isLoading }] = useCreateSalesMutation();

  const [updateSales, { isLoading: isLoadingUpdate }] =
    useUpdateSalesMutation();

  const [createRent, { isLoading: isLoadingRent }] = useCreateRentMutation();
  const [updateRent, { isLoading: isUpdatingRent }] = useUpdateRentMutation();

  const handleSubmit = async () => {
    const obj = {
      customer_name: formik.values.customer_name,
      product_id: formik.values.product.value,
      quantity: isActiveRentTab
        ? String(formik.values.quantity)
        : formik.values.quantity,
      description: formik.values.description,
      size: isActiveRentTab ? String(formik.values.size) : formik.values.size,
      prize: isActiveRentTab
        ? String(formik.values.price)
        : formik.values.price,
      date: formik.values.date,
      outlet_id: isActiveRentTab
        ? String(formik.values.outlet.value)
        : formik.values.outlet.value,
      return_date: isActiveRentTab ? formik.values?.return_date : null,
    };

    const payload = { payload: obj, id: salesId };

    const handleSuccess = (message: string) => {
      toast.success(message);
      new Promise(() => {
        setTimeout(() => {
          toast.dismiss();
          closeModal();
        }, 1000);
      });
    };

    // try {
    switch (true) {
      case isUpdate && !isActiveRentTab:
        await updateSales(payload).unwrap();
        handleSuccess("Sales Updated Successfully");
        break;

      case isActiveRentTab && !isUpdate:
        await createRent(obj).unwrap();
        handleSuccess("Rent Created Successfully");
        break;

      case isActiveRentTab && isUpdate:
        await updateRent(payload).unwrap();
        handleSuccess("Rent Updated Successfully");
        break;

      default:
        await createSales(obj).unwrap();
        handleSuccess("Sales Created Successfully");
        break;
    }
    // } catch (error) {
    //   console.error("Error processing request:", error);
    //   toast.error("An error occurred while processing your request.");
    // }
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: isActiveRentTab ? rentValidationSchema : validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingRent,
    isUpdatingRent,
  };
};
