import { useFormik } from "formik";
import { toast } from "sonner";
import * as yup from "yup";
import {
  useCreatePurchaseMutation,
  useUpdatePurchaseMutation,
} from "../../redux/services/purchaseApi";
import { useDebounce } from "helpers/helper";
import { useFilterByProductQuery } from "../../redux/services/productApi";

export const useAddPurchase = ({
  closeModal,
  isUpdate,
  productId,
  data,
}: {
  closeModal: () => void;
  isUpdate?: boolean;
  productId: number;
  data: any;
}) => {
  const validationSchema = yup.object().shape({
    vendor: yup.string().required("Vendor name is required"),
    address: yup.string().required("Address is required"),
    quantity: yup
      .number()
      .required("Quantity is required")
      .positive("Quantity must be a positive number")
      .integer("Quantity must be an integer"),
    description: yup.string().required("Description is required"),
    size: yup.string().required("Size is required"),
    product_name: yup.string().required("Product name is required"),
    prize: yup
      .number()
      .required("Cost price is required")
      .positive("Cost price must be a positive number"),
    date: yup.string().required("Date is required"),
    // status: yup.string().required("Status field is required"),
    status: yup.object().shape({
      value: yup.string().required("Status is required"),
    }),

    // outlet: yup.object().shape({
    //   value: yup.string().required("Outlet is required"),
    // }),
    outlet: yup.string().required("Outlet field is required"),
  });

  const formValues = {
    vendor: (isUpdate && data?.vendor) || "",
    address: (isUpdate && data?.address) || "",
    quantity: (isUpdate && data?.quantity) || "",
    description: (isUpdate && data?.description) || "",
    size: (isUpdate && data?.size) || "",
    prize: (isUpdate && data?.prize) || "",
    date: (isUpdate && data?.date) || "",
    // status: (isUpdate && data?.status) || "",
    status: (isUpdate && {
      label: data?.status,
      value: data?.status,
    }) || {
      label: "Select sales outlet...",
      value: "",
    },
    product_name: (isUpdate && data?.product_name) || "",
    // outlet: (isUpdate && {
    //   label: data?.outlet?.name,
    //   value: data?.outlet?.id,
    // }) || {
    //   label: "Select status...",
    //   value: "",
    // },
    outlet: (isUpdate && data?.outlet) || "",
  };

  const [createPurchase, { isLoading }] = useCreatePurchaseMutation();

  const [updatePurchase, { isLoading: isLoadingUpdate }] =
    useUpdatePurchaseMutation();

  const handleSubmit = async () => {
    if (isProductError && !isUpdate) {
      toast.error("Produt name already exist");
      return;
    }
    if (isUpdate && formik?.values?.product_name !== data?.product_name && isProductError) {
      toast.error("Produt name already exist");
      return;
    }
    const obj = {
      ...formik.values,
      status: formik.values.status.value,
      // outlet: String(formik.values.outlet.value),
      quantity: String(formik.values.quantity),
      size: String(formik.values.size),
    };
    if (isUpdate) {
      const payload = { payload: obj, id: productId };
      await updatePurchase(payload)
        .unwrap()
        .then(() => {
          toast.success("Purchase Updated Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              closeModal();
            }, 1000);
          });
        });
    } else {
      await createPurchase(obj)
        .unwrap()
        .then(() => {
          toast.success("Purchase Created Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              closeModal();
            }, 1000);
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const debouncedProductVal = useDebounce(formik.values.product_name, 1000);
  const {
    // data: productData,
    error,
    isLoading: isLoadingProduct,
  } = useFilterByProductQuery(
    { productName: debouncedProductVal },
    {
      skip: !debouncedProductVal,
    }
  );

  const isProductError = (error as { status: number })?.status === 409;
  const productErrMessage = (error as { data: { message: string } })?.data
    ?.message;

  return {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingProduct,
    isProductError,
    productErrMessage,
  };
};
