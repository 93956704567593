import { SearchIcon } from "assets/images/svgs";
import { SearchType } from "./types";
import { useSidebar } from "component/Sidebar/useSidebar";

const SearchField = ({ value, placeholder, name, onChange }: SearchType) => {
  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";

  return (
    <div className="relative grid place-items-center">
      <input
        type="text"
        id={name}
        className={`p-[7px] border border-[#999898] transition-all ${toggledBgTheme} pl-12 outline-none rounded-[10px]`}
        name={name}
        value={value}
        placeholder={placeholder || "Search table..."}
        onChange={onChange}
      />
      <SearchIcon className="absolute left-0 ml-3" />
    </div>
  );
};

export default SearchField;
