import React from "react";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword: React.FC = () => {
  return (
    <div className="relative h-screen login-bg-img flex">
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
