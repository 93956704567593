export const PrintIcon = ({ className }: { className?: string }) => (
  <svg
    width="22"
    height="27"
    viewBox="0 0 22 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.8089 16.1773H18.5278C19.5756 16.1773 20.0989 16.1773 20.4244 15.7863C20.75 15.3954 20.75 14.7669 20.75 13.5087V12.1744C20.75 9.65789 20.75 8.40098 20.0989 7.61909C19.4478 6.83719 18.4011 6.83719 16.3056 6.83719H5.19444C3.09889 6.83719 2.05222 6.83719 1.40111 7.61909C0.75 8.40098 0.75 9.65789 0.75 12.1744V14.843C0.75 15.4714 0.75 15.7863 0.912222 15.9825C1.07556 16.1773 1.33889 16.1773 1.86111 16.1773H3.69111"
      stroke="black"
      stroke-width="1.5"
      className={className}
    />
    <path
      d="M17.4168 6.83719V5.34278C17.4168 3.9978 17.4168 3.32532 17.1991 2.81161C17.0073 2.35944 16.7011 1.99183 16.3246 1.76152C15.8968 1.5 15.3368 1.5 14.2168 1.5H7.2835C6.1635 1.5 5.6035 1.5 5.17572 1.76152C4.79918 1.99183 4.49306 2.35944 4.30127 2.81161C4.0835 3.32532 4.0835 3.9978 4.0835 5.34278V6.83719M4.0835 24.5913V13.5087C4.0835 12.2504 4.0835 11.622 4.40905 11.231C4.73461 10.8401 5.25794 10.8401 6.30572 10.8401H15.1946C16.2424 10.8401 16.7657 10.8401 17.0913 11.231C17.4168 11.622 17.4168 12.2504 17.4168 13.5087V24.5913C17.4168 25.0143 17.4168 25.2251 17.3013 25.3252C17.1857 25.4253 17.019 25.3586 16.6857 25.2251L14.2591 24.2538C14.1724 24.2191 14.1291 24.2004 14.0835 24.2004C14.0391 24.2004 13.9946 24.2191 13.9079 24.2538L10.9257 25.4466C10.8391 25.4813 10.7957 25.5 10.7502 25.5C10.7057 25.5 10.6613 25.4813 10.5746 25.4466L7.59238 24.2538C7.50572 24.2191 7.46238 24.2004 7.41683 24.2004C7.37238 24.2004 7.32794 24.2191 7.24127 24.2538L4.81461 25.2251C4.48127 25.3586 4.31461 25.4253 4.19905 25.3252C4.0835 25.2251 4.0835 25.0143 4.0835 24.5913Z"
      stroke="black"
      stroke-width="1.5"
      className={className}
    />
    <path
      d="M8.52783 16.1773H11.8612M8.52783 20.1802H13.5278"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      className={className}
    />
  </svg>
);
