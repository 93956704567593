import { ForgotPassword, Login, ResetPassword, VerifyOtp } from "auth/Login";

export const Auth_route_group = [
  {
    element: Login,
    path: "/login",
  },
  {
    element: ForgotPassword,
    path: "/forgot-password",
  },
  {
    element: VerifyOtp,
    path: "/verify-otp",
  },
  {
    element: ResetPassword,
    path: "/reset-password",
  },
];
