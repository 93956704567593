export const useTable = () => {
  const handleGetLastArray = (array: string[], chi: string) => {
    const lastIndex = array.length - 1;
    const val = array[lastIndex] === chi;
    return val;
  };
  return {
    handleGetLastArray,
  };
};
