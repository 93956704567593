import { SmileyIcon, StaticImage } from "assets/images/svgs";
import NotificationWithProfile from "./Notification";
import { useAppSelector } from "../../redux/store";
import { selectUser } from "../../redux/features/authSlice";
// import { selectTheme } from "../../redux/features/themeSlice";
import { useSidebar } from "component/Sidebar/useSidebar";

const Navbar = () => {
  const user = useAppSelector(selectUser);

  //   <div className="bg-[#222121] absolute w-[calc(100%-275px)] h-[96px] right-0 py-[25px] pr-3 pl-0 flex justify-between items-center ">
  //   <div className="flex gap-3 items-center">
  //     <p className="text-white text-2xl ml-5">Welcome Back!</p>
  //     <div className="flex gap-2 items-center">
  //       <p className="text-2xl">{user?.username}</p>
  //       <SmileyIcon />
  //     </div>
  //   </div>
  //   <NotificationWithProfile imgSrc={user?.profile_picture || StaticImage} />
  // </div>

  // const defaultTheme = useAppSelector(selectTheme);

  const {
    isDarkTheme,
  } = useSidebar();


  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <div
      className={`${toggledBgTheme} transition-all absolute w-[calc(100%-275px)] h-[96px] right-0 py-[25px] pr-3 pl-0 flex justify-between items-center `}
    >
      <div className="flex gap-3 items-center">
        <p className={`${toggleTextTheme} transition-all text-2xl ml-5`}>Welcome Back!</p>
        <div className="flex gap-2 items-center">
          <p className="text-2xl">{user?.username}</p>
          <SmileyIcon />
        </div>
      </div>
      <NotificationWithProfile imgSrc={user?.profile_picture || StaticImage} />
    </div>
  );
};

export default Navbar;
