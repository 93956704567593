import { SvgTypes } from "./types";

export const CreditIcon = ({ fill }: SvgTypes) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7333 6.3475L14.7117 7.40667M9.88168 9.51083L11.87 10.0408M9.98085 15.4717L10.7758 15.6842C13.0258 16.2842 14.1508 16.5833 15.0375 16.0742C15.9233 15.5658 16.225 14.4467 16.8275 12.21L17.68 9.04583C18.2833 6.80833 18.5842 5.69 18.0725 4.80833C17.5608 3.92667 16.4367 3.6275 14.1858 3.02833L13.3908 2.81583C11.1408 2.21583 10.0158 1.91667 9.13001 2.42583C8.24335 2.93417 7.94168 4.05333 7.33834 6.29L6.48668 9.45417C5.88334 11.6917 5.58168 12.81 6.09418 13.6917C6.60584 14.5725 7.73085 14.8725 9.98085 15.4717Z"
      stroke={fill || "#999898"}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0001 17.955L9.20677 18.1717C6.96177 18.7825 5.8401 19.0883 4.9551 18.5692C4.07177 18.0508 3.7701 16.9108 3.16927 14.6292L2.31844 11.4025C1.71677 9.12167 1.41594 7.98083 1.92677 7.0825C2.36844 6.305 3.33344 6.33333 4.58344 6.33333"
      stroke={fill || "#999898"}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
