import { Layout } from "component/Layout";
import "./App.css";
import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { Auth_route_group } from "routes/Auth";
import { Pages_route_group } from "routes/routes";
import Guard from "component/Guard/Guard";
import PublicRoute from "component/Guard/PublicGuard";

function App() {
  return (
    <React.Fragment>
      <div className="text-[#999898] font-inter text-base not-italic font-medium relative">
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* {Auth_route_group.map((route, idx) => {
            const { path } = route;
            return <Route key={idx} path={path} element={<route.element />} />;
          })} */}
          {/* Auth routes (public routes like login, signup) */}
          {Auth_route_group.map((route, idx) => {
            const { path } = route;
            return (
              <Route
                key={idx}
                path={path}
                element={
                  <PublicRoute redirectTo="/dashboard">
                    <route.element />
                  </PublicRoute>
                }
              />
            );
          })}

          {Pages_route_group.map((route, idx) => {
            const { path } = route;

            return (
              <Route
                key={idx}
                path={path}
                element={
                  <Guard redirectTo="/login">
                    <Layout>
                      <route.element />
                    </Layout>
                  </Guard>
                }
              />
            );
          })}
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default App;
