import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSidebar } from "component/Sidebar/useSidebar";

export interface Modal {
  children: React.ReactNode;
  onClose?: (param: boolean) => void;
  open: boolean;
  className?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
}

export default function DashboardModal({
  children,
  open,
  onClose,
  className,
  width,
  height,
  borderRadius,
}: Modal) {
  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "#1e1e1e" : "background.paper";
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width || "400px",
    border: "none",
    borderRadius: borderRadius,
    bgcolor: toggledBgTheme,
    boxShadow: 24,
    height: height,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={`rounded-lg p-0 border-none overflow-auto ${className}`}
        >
          {children}
        </Box>
      </Modal>
    </div>
  );
}
