import Cookies from "js-cookie";
import { baseApi } from "../../redux/baseApi";
import { setAuthUser } from "../../redux/features/authSlice";
import { SingleUser } from "../../../@types/auth";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (user) => ({
        url: "/Login",
        method: "POST",
        body: user,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          Cookies.set("token", data.token);
          // localStorage.setIte("token", data?.token);
          dispatch(setAuthUser(data));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "admin/Logout",
        method: "GET",
        // body: user,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (payload) => ({
        url: "/forget-password",
        method: "POST",
        body: payload,
      }),
    }),

    verifyOtp: builder.mutation({
      query: (payload) => ({
        url: "/verify-otp",
        method: "POST",
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: "/passwordReset",
        method: "POST",
        body: payload,
      }),
    }),
    resendOtp: builder.mutation({
      query: (payload) => ({
        url: "/resend-otp",
        method: "POST",
        body: payload,
      }),
    }),
    getSingleUser: builder.query<SingleUser, number>({
      query: (id) => ({
        url: `/admin/single-user/${id}`,
        method: "GET",
      }),
      providesTags: ["userDetails"],
      transformResponse: (response: { data: SingleUser }) => response.data,
    }),
    getSingleAdminUser: builder.query<SingleUser, number>({
      query: (id) => ({
        url: `single-admin-user/${id}`,
        method: "GET",
      }),
      providesTags: ["userDetails"],
      transformResponse: (response: { data: SingleUser }) => response.data,
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useResendOtpMutation,
  useGetSingleUserQuery,
  useGetSingleAdminUserQuery,
  useLogoutMutation,
} = authApi;
