import useDisclosure from "hooks/useDisclosure";
import React, { useRef, useState } from "react";
import DashboardModal from "component/Modal/Modal";
import {
  formatNumberWithCommas,
  handleDateFilter,
  useDebounce,
} from "helpers/helper";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";
import { SalesTabEnum } from "../../Sales/utils";
import { useSales } from "../../Sales/useSales";
import SalesTable from "../../Sales/SalesTable";
import { SalesArray } from "../../../../@types/sales";
import AddSales from "../../Sales/AddSales";
import { useGetRentsQuery } from "../../../redux/services/rentApi";
import { RentArray } from "../../../../@types/rent";
// import { useGetInvoicesQuery } from "../../../redux/services/invoiceApi";
import { useGetSalesReportsQuery } from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";
import { useGetSalesAndInvoiceBreakdownQuery } from "../../../redux/services/reportApi";
import { PageLoader } from "component/Loader/Loader";
import { Table, TableBodyData } from "component/Table";
import { PrintIcon } from "assets/images/tsx";
import { useReactToPrint } from "react-to-print";

export default function SalesTab() {
  const {
    isOpen: openAddSalesModal,
    open: onOpenAddSalesModal,
    close: closeAddSalesModal,
  } = useDisclosure();
  const { handleSalesTabChange, isActiveTab, salesTab } = useSales();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<SalesArray>();
  const [searchVal, setSearchVal] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // const {
  //   data: salesData,
  //   isLoading: isLoadingProducts,
  //   isFetching,
  // } = useGetSalesQuery({
  //   search: debouncedSearchVal,
  //   filter_by: salesTab.value,
  //   page: page,
  // });

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useGetSalesReportsQuery({
    search: debouncedSearchVal,
    "time[start_date]": handleDateFilter("start_date", fromDate, toDate),
    "time[end_date]": handleDateFilter("end_date", fromDate, toDate),
    // search_by: productTab.value,
    filter_by: salesTab.value,
    page: page,
  });

  const {
    data: rentData,
    isLoading: isLoadingRent,
    isFetching: isFetchingRent,
  } = useGetRentsQuery(
    {
      search: debouncedSearchVal,
      filter_by: salesTab.value,
      page: page,
    },
    { skip: salesTab.value !== SalesTabEnum.RENT }
  );

  const {
    data: productCount,
    isLoading: isLoadingProductsCount,
    isFetching: isFetchingProductCount,
  } = useGetSalesAndInvoiceBreakdownQuery({
    search: debouncedSearchVal,
    // filter_by: productTab.value,
    page: page,
  });

  // const sales = salesData?.data;
  // const lastPage = salesData?.meta?.last_page ?? 0;

  const sales = invoicesData?.data ?? [];
  const lastPage = invoicesData?.last_page;

  const rents = rentData?.data;
  const rentLastPage = (rentData as any)?.meta?.last_page ?? 0;

  // console.log(rentData?.meta?.last_page, "rent last page");

  const isRent = salesTab.value === SalesTabEnum.RENT;

  const { isDarkTheme } = useSidebar();

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();

  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const totalCards = (
    <div
      onClick={onOpenBreakModal}
      className={`${toggledCardTheme} cursor-pointer hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p className={`${toggleTextTheme} text-black font-medium text-3xl`}>
        Total Sales
      </p>
      <p className="font-medium text-4xl mt-10">
        {isLoadingInvoices ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={40}
            height={20}
          />
        ) : (
          invoicesData?.total || "0"
        )}
      </p>
    </div>
  );

  const titleClass = `font-medium font-bold text-center w-full p-2 ${toggleTextTheme} min-w-fit mb-3`;
  const colClass = `grid grid-cols-2 border-b-2 p-2 w-full gap-10 ${toggleTextTheme}`;
  const textClass = "text-[#4A07A0] font-semibold";

  const headerLabels = [
    "Items",
    "Quantities",
    "Total Amount",
    "Created At",
    "Invoice To",
    "Delivered To",
    "Payment Method",
    "Entry Officer",
    "Address",
    "Balance",
    "Invoice No",
    "Discount",
  ];
  const columnClassNames = "font-semibold text-right border-l-2 pl-4";
  const header = (
    <div className={`${colClass} !grid !grid-cols-12 gap-4`}>
      {headerLabels.map((label, index) => (
        <div
          key={index}
          className={label ? columnClassNames : "font-semibold pl-4"}
        >
          {label}
        </div>
      ))}
    </div>
  );
  // const breakModal = (
  //   <div className="p-5 grid place-items-center w-full min-w-fit ">
  //     <p
  //       className={`${toggleTextTheme} font-semibold text-2xl capitalize text-center`}
  //     >{`${"Sales"} Breakdown`}</p>
  //     <div className="grid  mt-5 pb-10 h-[40rem]">
  //       <div className="">
  //         <h3 className={titleClass}>Product quantities</h3>
  //         <div className="grid grid-rows place-items-center gap-5 overflow-auto">
  //           {header}
  //           {(productCount?.sales_breakdown as any[])?.map((chi, idx) => {
  //             const { product_name, quantity } = chi;
  //             return (
  //               <div className={colClass}>
  //                 <p className={textClass}>{product_name}</p>
  //                 <p className="font-semibold text-right border-l-2">
  //                   {quantity}
  //                 </p>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div>

  //       {/* second layer */}
  //       {/* <div>
  //         <h3 className={titleClass}>Out of stock products</h3>
  //         <div className="grid grid-rows place-items-center gap-5 overflow-auto">
  //           {header}
  //           {(productCount?.out_of_stock_products as any[])?.map((chi, idx) => {
  //             const { product_name, quantity } = chi;
  //             return (
  //               <div className={colClass}>
  //                 <p className={textClass}>{product_name}</p>
  //                 <p className="font-semibold text-right">{quantity}</p>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div> */}
  //     </div>
  //   </div>
  // );

  const breakModalBody = (
    <div className="overflow-auto">
      {isLoadingProductsCount ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className={`flex flex-col justify-center items-center`}>
            <PageLoader />
          </div>
        </div>
      ) : (productCount?.sales_breakdown as any[])?.length > 0 ? (
        (productCount?.sales_breakdown as any[])?.map((chi, idx) => {
          // console.log(chi?.invoices_breakdown, "breakdown");
          const {
            product_name,
            quantity,
            total_amount,
            created_at,
            invoice_to,
            delivered_to,
            payment_method,
            entry_officer,
            address,
            balance,
            invoice_no,
            discount,
          } = chi;
          return (
            <TableBodyData
              loading={isFetching}
              dataLength={12}
              key={idx}
              one={product_name}
              two={quantity}
              five={`${"₦"}${formatNumberWithCommas(total_amount ?? 0)}`}
              eight={created_at}
              nine={invoice_to}
              ten={delivered_to}
              eleven={payment_method}
              twelve={entry_officer}
              thirteen={address}
              fourteen={`${"₦"}${formatNumberWithCommas(balance ?? 0)}`}
              fifteen={invoice_no}
              // fifteenClass="text-right"
              sixteen={`${"₦"}${formatNumberWithCommas(discount ?? 0)}`}
              sixteenClass="text-right"
              isNotDeletable
              isNotEditable
              // onEdit={() => {
              //   onEditChild(chi);
              //   setInvoiceId(id);
              //   setIsEdit(true);
              //   onOpenAddModal();
              // }}
              // onDelete={() => {
              //   setChildData(chi);
              //   onOpenDeleteModal();
              // }}
              // isNotDeletable={isNotDeleteable}
              // isNotEditable={isNotEditable}
            />
          );
        })
      ) : (
        <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          No Sales Breakdown
        </p>
      )}
    </div>
  );
  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice Data",
  });

  const breakModal = (
    <>
      <div className="m-5" onClick={handlePrint}>
        <PrintIcon
          className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
        />
      </div>
      <div ref={componentRef}>
        <h1 className={`${toggleTextTheme} text-center p-5`}>
          Sales Breakdown
        </h1>
        <Table
          className=""
          tableHead={headerLabels}
          dataLength={12}
          tableBody={breakModalBody}
        />
      </div>
    </>
  );

  return (
    <>
      {totalCards}

      <SalesTable
        isRentTab={isRent}
        setIsEdit={setEdit}
        dateRange={true}
        setStartDate={setFromDate}
        setEndDate={setToDate}
        loaderBottom
        dntShowAdd
        // salesTableBody={
        //   salesTab.value === SalesTabEnum.RENT
        //     ? (rents as RentArray[])
        //     : (sales as Invoices[])
        // }
        loading={isRent ? isLoadingRent : isLoadingInvoices}
        // loading={isRent ? isLoadingRent : isLoadingProducts}
        isFetching={isRent ? isFetchingRent : isFetching}
        onOpenAddModal={onOpenAddSalesModal}
        onEditChild={setChildData}
        salesTableBody={rents as RentArray[]}
        invoiceTableBody={sales}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={salesTab.value === SalesTabEnum.RENT ? rentLastPage : lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddSalesModal}
        // onClose={closeAddSalesModal}
        onClose={() => {
          closeAddSalesModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddSales
          closeModal={() => {
            closeAddSalesModal();
            setEdit(false);
          }}
          isRentTab={isRent}
          isEdit={edit}
          data={childData as SalesArray}
        />
      </DashboardModal>
      {/* modal wrap end */}
      <DashboardModal
        open={openBreakModal}
        onClose={closeBreakModal}
        width="80%"
        className="h-[calc(100%-10rem)]"
      >
        {breakModal}
      </DashboardModal>
    </>
  );
}
