import { useState } from "react";
import { OptionType } from "./types";
 
export const useDashboardTable = () => {
  ;
  const [period, setPeriod] = useState<OptionType>({
    label: "Monthly",
    value: "monthly",
  });

  const onPeriodChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      setPeriod(selectedOption);
    }
  };

  const handleStatusColor = (status: string) => {
    const successColor = "text-[#6FAB23]";
    const pendingColor = "text-[#EBEF1F]";
    const failedColor = "text-[#DB2B2B]";
    if (status === "delivered") {
      return successColor;
    } else if (status === "Pending") {
      return pendingColor;
    } else return failedColor;
  };
  return {

    period,
    onPeriodChange,
    handleStatusColor,
  };
};
