import {
  EyeCloseIcon,
  LockIcon,
  Logo,
  MsgIcon,
  TimesIcon,
} from "assets/images/svgs";
import { useLoginForm } from "./useLoginForm";
import { InputField } from "component/Form";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { useNavigate } from "react-router";
import { useForgotPassword } from "./useForgotPassword";

const ForgotPasswordForm = () => {
  const { formik, isLoading } = useForgotPassword();
  const navigate = useNavigate();
  return (
    <div className="border-0 rounded-[50px] pt-6 pb-28 bg-[#FAF9F6] shadow-custom-box-shadow w-[498px] m-auto px-10">
      <Logo className="m-auto" />

      <form
        onSubmit={formik.handleSubmit}
        className="mt-10 flex flex-col gap-4"
      >
        <InputField
          leftIcon={MsgIcon}
          rightIcon={TimesIcon}
          type="text"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Enter email"
          onRightIconClick={() => formik.setFieldValue("email", "")}
        />
        <div className="flex flex-col gap-5">
          <Button
            btnType={ButtonTypeEnum.Submit}
            label="Send"
            className="mt-14"
            loading={isLoading}
            disabled={!formik.isValid || !formik.dirty}
          />
          <div
            className="p-[15px] border-[0.5px] rounded-[10px] border-[#999898] cursor-pointer"
            onClick={() => navigate("/login")}
          >
            <p className="text-[#999898] font-medium text-base text-center">
              Back to Login
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
