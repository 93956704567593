import React from "react";
import { ScannedItem, invoiceTemplateTableHead } from "./utils";
import { Table, TableBodyData } from "component/Table";
import { formatNumberWithCommas } from "helpers/helper";
import { Amount, OtherItems } from "../../redux/features/scannedItemsSlice";

const InvoicePreviewTable = ({
  invoiceGenerateData,
  otherDetails,
  amountDetails,
}: {
  invoiceGenerateData: ScannedItem[];
  otherDetails: OtherItems;
  amountDetails: Amount;
}) => {
  const invoiceTableBody = (
    <div className="overflow-auto">
      {invoiceGenerateData.map((chi, idx) => {
        const {
          product_name,
          product_description,
          quantity,
          selling_price,
          amount = Number(quantity) * Number(selling_price),
          currency,
        } = chi;
        return (
          <TableBodyData
            dataLength={5}
            key={idx}
            onePrev={product_name}
            one={product_description}
            two={quantity}
            three={`${currency || "₦"}${formatNumberWithCommas(
              Number(selling_price) || 0
            )}`}
            four={`${currency || "₦"}${formatNumberWithCommas(amount)}`}
            fourClass="text-right"
            isNotDeletable
            isNotEditable
            trClass="bg-[#FAF9F6] border-b-white text-[13px] border-t-white border-b-8 border-t-8 rounded-[10px] text-black"
            oneClass="font-light w-[130px]"
          />
        );
      })}
    </div>
  );
  const totalDisplay = (
    <div className="flex flex-col gap-2">
      <div className="flex gap-[6rem] items-center justify-end mr-3 mt-4">
        <p className="font-medium text-black">TOTAL</p>
        <p className="font-medium text-black">{`₦${formatNumberWithCommas(
          amountDetails?.total_amount
        )}`}</p>
      </div>

      <div className="flex gap-[6rem] items-center justify-end mr-3 mt-4">
        <p className="font-medium text-black">DISCOUNT AMOUNT</p>
        <p className="font-medium text-black">
          {`₦${formatNumberWithCommas(amountDetails?.discount_amount)}`}
        </p>
      </div>
    </div>
  );

  const textFormDisplay = (
    <div className="flex justify-between items-center mt-20">
      <div className="flex flex-col gap-2 ">
        <p className="border-b text-black px-10  border-b-black text-center pb-1">
          {otherDetails?.entry_officer}
        </p>
        <p className="font-normal text-xs w-[210px] text-[#999898] text-center">
          Entry officer
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <p className="border-b text-black w-[210px] px-10 border-b-black text-center pb-1">
          {otherDetails?.customer}
        </p>
        <p className="font-normal text-xs text-[#999898] text-center">
          customer
        </p>
      </div>
    </div>
  );
  return (
    <div>
      <Table
        thClass="text-black font-medium text-[13px]"
        theadClass="bg-white border-b-0"
        tableHead={invoiceTemplateTableHead}
        dataLength={5}
        tableBody={invoiceTableBody}
      />
      {totalDisplay}
      {textFormDisplay}
    </div>
  );
};

export default InvoicePreviewTable;
