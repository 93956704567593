import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {
  useGetNotificationsQuery,
  useMarkAsReadMutation,
} from "../../redux/services/dashboardApi";
import { toast } from "sonner";
import { useSidebar } from "component/Sidebar/useSidebar";
import { BtnLoader } from "component/Loader/Loader";

interface Props {
  open: any;
  onClose: () => void;
  handleMarkAsRead: (id: any) => void;
  notifyData: any;
  isLoading?: boolean;
  loadingId?: number;
}

export default function AnchorTemporaryDrawer({
  open,
  onClose,
  handleMarkAsRead,
  notifyData,
  loadingId,
}: Props) {
  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "#222121" : "#FAF9F6";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              backgroundColor: toggledBgTheme,
              color: "#FFFFFF",
            },
          }}
        >
          <div>
            <p className={`p-5 pb-0 ${toggleTextTheme} font-bold text-2xl`}>
              Notifications
            </p>
          </div>
          <div className={`w-[500px] h-screen`}>
            {notifyData?.notifications?.length === 0 && (
              <p
                className={`text-2xl ${toggleTextTheme} grid place-items-center mt-[10rem] font-medium`}
              >
                No notifications found
              </p>
            )}

            <div className=" flex flex-col gap-2">
              {(notifyData?.notifications as any[])?.map((chi, idx) => {
                const { data, id } = chi;
                return (
                  <div key={idx} className="border rounded-lg p-3 m-5 ">
                    <p className="text-base text-gray-500 font-medium">
                      {data?.message || "-- -- "}
                    </p>
                    <p className={`font-bold ${toggleTextTheme} text-xs mt-5`}>
                      {data?.time || "-- -- "}
                    </p>
                    <div
                      className={`${
                        loadingId === id
                          ? "!cursor-not-allowed"
                          : "cursor-pointer"
                      } w-[100px] mt-5 ml-auto`}
                      onClick={() => {
                        if (loadingId === id) {
                          return;
                        }
                        handleMarkAsRead(id);
                      }}
                    >
                      <p className="p-2 text-xs text-[#4a07a0] font-bold  border rounded-lg bg-primary">
                        {loadingId === id ? (
                          <BtnLoader textClass="!text-[10px]" />
                        ) : (
                          "Mark as read"
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
