import { useState } from "react";

type TabType = {
  label: string;
  value: string;
};

export const useStaff = ({ closeModal }: { closeModal: () => void }) => {
  const [staffTab, setStaffTab] = useState<TabType>({
    label: "Staffs",
    value: "",
  });

  const handleStaffTabChange = (tab: TabType) => {
    setStaffTab(tab);
  };

  const isActiveTab = (tabValue: string) => {
    return staffTab.value === tabValue
      ? "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
      : "";
  };

  return {
    staffTab,
    handleStaffTabChange,
    isActiveTab,
  };
};
