import { SvgTypes } from "./types";

export const PurchaseIcon = ({ fill }: SvgTypes) => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1667 5.33334L8.08333 1.16667L1 5.33334V13.6667L8.08333 17.8333L15.1667 13.6667V5.33334Z"
      stroke={fill || "#999898"}
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M8.08333 8.66667V12M11.4167 7.00001V12M4.75 10.3333V12"
      stroke={fill || "#999898"}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
