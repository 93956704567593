import { useState } from "react";
import { OptionType } from "./types";
import { selectOption } from "./utils";

export const useDashboardStockHistory = () => {
  const [period, setPeriod] = useState<OptionType>({
    label: "Monthly",
    value: "monthly",
  });

  const onPeriodChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      setPeriod(selectedOption);
    }
  };

  const handlePercentage = (isIncreased: boolean) => {
    const decreamentColor = "text-[#DB2B2B]";
    const increamentColor = "text-[#04B4FC]";
    if (isIncreased) {
      return increamentColor;
    } else {
      return decreamentColor;
    }
  };
  return {
    period,
    handlePercentage,
    onPeriodChange,
    selectOption,
  };
};
