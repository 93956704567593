import React, { useRef } from "react";
import { ProductsType } from "../../../@types/product";
import {
  formatNumberWithCommas,
  printElement,
  updateSVGDimensions,
} from "helpers/helper";
import DashboardModal from "component/Modal/Modal";
import useDisclosure from "hooks/useDisclosure";
import ReactToPrint from "react-to-print";
import { StaticImage } from "assets/images/svgs";
import { useSidebar } from "component/Sidebar/useSidebar";

const ProductPreview = ({ data }: { data: ProductsType }) => {
  const updatedSVG = updateSVGDimensions({
    svgString: data.qr_image,
    newWidth: 50,
    newHeight: 50,
  });

  const expandedUpdatedSVG = updateSVGDimensions({
    svgString: data.qr_image,
    newWidth: 700,
    newHeight: 600,
  });

  const {
    isOpen: openQrModal,
    open: onOpenQrModal,
    close: closeQrModal,
  } = useDisclosure();

  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    printElement(printRef);
  };

  const { isDarkTheme } = useSidebar();

  // const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const colClass = "grid grid-cols-2 gap-10 items-center";
  const textClass = `font-medium ${toggleTextTheme}`;
  const descClass = "font-light text-[#767676]";
  // console.log(data, "datas");
  return (
    <div className="p-10 flex flex-col items-center justify-center ">
      <p className="text-[#4A07A0] text-2xl font-bold mb-6">PRODUCT OVERVIEW</p>
      <div className="flex flex-col gap-6 ">
        <div className={colClass}>
          <p className={textClass}>Product name:</p>
          <p className={descClass}>{data.product_name || "--- ---"}</p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Description:</p>
          <p className={descClass}>{data.product_description || "--- ---"}</p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Quantity:</p>
          <p className={descClass}>{data.quantity || "--- ---"}</p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Size:</p>
          <p className={descClass}>{data.size || "--- ---"}</p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Date:</p>
          <p className={descClass}>{data.date || "--- ---"}</p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Cost price:</p>
          <p className={descClass}>
            {`${data.currency || "₦"}${formatNumberWithCommas(
              data.cost_price ?? 0
            )}` || "--- ---"}
          </p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Selling price:</p>
          <p className={descClass}>
            {`${data.currency || "₦"}${formatNumberWithCommas(
              data.selling_price ?? 0
            )}` || "--- ---"}
          </p>
        </div>
        <div className={colClass}>
          <p className={textClass}>QR Code:</p>
          <div className="flex flex-col gap-2">
            <p className={descClass}>
              {<figure dangerouslySetInnerHTML={{ __html: updatedSVG }} />}
            </p>
            <p
              className="font-medium text-xs cursor-pointer text-[#4A07A0]"
              onClick={onOpenQrModal}
            >
              Expand QR Code
            </p>
          </div>
        </div>
        <div className={colClass}>
          <p className={textClass}>Year of Manufacturing:</p>
          <p className={descClass}>{data.year_of_manufacturing || "--- ---"}</p>
        </div>
        <div className={colClass}>
          <p className={textClass}>Product Image:</p>
          <div>
            <img
              src={data?.product_image?.[0]?.path || StaticImage}
              alt="Product Preview"
              className="w-[150px] h-[150px] rounded-2xl"
            />
          </div>
        </div>
      </div>

      {/* modal wrap start */}
      <DashboardModal
        open={openQrModal}
        onClose={closeQrModal}
        width={"800px"}
        height={"90%"}
      >
        <div className="p-10">
          {/* <p
            onClick={handlePrint}
            className="font-medium text-sm cursor-pointer text-[[#4A07A0] border-b pb-5"
          >
            Print QR Code
          </p> */}
          <ReactToPrint
            trigger={() => (
              <p className="font-medium text-sm cursor-pointer text-[#4A07A0] border-b pb-5">
                Print QR Code
              </p>
            )}
            content={() => printRef.current!}
          />

          <div ref={printRef}>
            <figure dangerouslySetInnerHTML={{ __html: expandedUpdatedSVG }} />
          </div>
        </div>
      </DashboardModal>
      {/* modal wrap end */}
    </div>
  );
};

export default ProductPreview;
