import { useSidebar } from "component/Sidebar/useSidebar";
import { ContainerType } from "./types";

const Container = ({ children, className }: ContainerType) => {
  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "bg-[#141414]" : "bg-container";

  // "h-[calc(100vh-96px)]   w-[calc(100%-275px)]  flex flex-col absolute bottom-0 bg-[#4f4e4e] overflow-auto"
  return (
    <div
      className={
        `h-[calc(100vh-96px)]   w-[calc(100%-275px)]   flex flex-col absolute bottom-0 transition-all ${toggledBgTheme} overflow-auto`
        // "h-[calc(100vh-96px)]   w-[calc(100%-275px)]  flex flex-col absolute bottom-0 bg-container overflow-auto"
      }
    >
      <div
        className={`h-screen p-5 flex flex-col gap-5 mb-[20rem] absolute right-0 left-0 ${className}`} //removed absolute
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
