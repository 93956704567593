// export const SalesTabs = ["Sales", "Rent"];

export enum CreditTabEnum {
  CREDIT = "",
  DEBIT = "debit",
}

export const creditTabs = [
  { label: "Credit", value: CreditTabEnum.CREDIT },
  { label: "Debit", value: CreditTabEnum.DEBIT },
];

export const sortOptions = [
  {
    label: "Images",
    value: "images",
  },
  {
    label: "Product name",
    value: "product_name",
  },
  {
    label: "Description",
    value: "description",
  },
];

export const creditTableHead = [
  "Product sold",
  "Customer name",
  "Quantity",
  "Unit price",
  "Cost price",
  "Date",
  "Action",
];

export const debitTableHead = [
  "Product bought",
  "Vendor name",
  "Quantity",
  "Unit price",
  "Cost price",
  "Date",
  "Action",
];

