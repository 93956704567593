import { TitleText, col } from "component/Typography";
import {
  dashboard_table_body,
  dashboard_table_head,
  selectOption,
} from "./utils";
import { PlusIcon } from "assets/images/svgs";
import { useDashboardTable } from "./useDashboardTable";
import { Frame } from "component/Frame";
import { SelectField } from "component/Select";
import { useNavigate } from "react-router";
import { useGetRecentInvoicesQuery } from "../../redux/services/dashboardApi";
import { Pagination } from "@mui/material";
import { useState } from "react";
import { PageLoader } from "component/Loader/Loader";
import Skeleton from "react-loading-skeleton";
import { useSidebar } from "component/Sidebar/useSidebar";

const DashboardTable = () => {
  const { handleStatusColor, onPeriodChange, period } = useDashboardTable();
  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "bg-[#131313]" : "bg-[#F5F5F5]";

  const textStyle = "text-[#6D6A6A] font-base font-medium";

  const dashboardTableHead = (
    <thead className={`${toggledBgTheme} transition-all`}>
      <tr className="grid grid-cols-4 items-center p-3">
        {dashboard_table_head.map((chi, idx) => (
          <th key={idx} className={textStyle}>
            {chi}
          </th>
        ))}
      </tr>
    </thead>
  );
  const navigate = useNavigate();

  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const {
    data: invoiceData,
    isLoading,
    isFetching,
  } = useGetRecentInvoicesQuery({
    filter_by: period.value as any,
    page: page,
  });
  const invoices = invoiceData ?? [];
  const lastPage = invoiceData?.meta?.last_page ?? 0;

  // console.log(invoices, "invoices");

  const dashboardTableBody = (
    <tbody>
      {isLoading ? (
        <tr>
          <td colSpan={5}>
            <div className="flex justify-center items-center h-[700px]">
              <PageLoader />
            </div>
          </td>
        </tr>
      ) : invoices?.data?.length > 0 ? (
        (invoices?.data as any[])?.map((chi, idx) => {
          const { invoice_no, invoice_to, sales_date, status } = chi;
          return (
            <tr
              key={idx}
              className="grid grid-cols-4 items-center text-center border-b border-[#D9D9D9CC] p-3 cursor-pointer"
              onClick={() => navigate("/invoice")}
            >
              <td className="grid grid-cols-2 m-auto items-center justify-center w-[80px]">
                <PlusIcon className="cursor-pointer" />
                <span className={textStyle}>
                  {isFetching ? (
                    <Skeleton
                      baseColor="#E0E0E0"
                      highlightColor="#F5F5F5"
                      width={100}
                      height={20}
                    />
                  ) : (
                    invoice_no
                  )}
                </span>
              </td>
              <td className={textStyle}>
                {isFetching ? (
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={100}
                    height={20}
                  />
                ) : (
                  invoice_to
                )}
              </td>
              <td className={textStyle}>
                {isFetching ? (
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={100}
                    height={20}
                  />
                ) : (
                  sales_date
                )}
              </td>
              {/* <td className={textStyle}>{amount || "--- ---"}</td> */}
              <td className={`${textStyle} ${handleStatusColor(status)}`}>
                {isFetching ? (
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={100}
                    height={20}
                  />
                ) : (
                  status
                )}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={4} className="text-center p-4">
            <p className={`font-medium transition-all ${toggleTextTheme}`}>
              No recent invoices listed here...{" "}
            </p>
          </td>
        </tr>
      )}
    </tbody>
  );

  return (
    <Frame rmPadding>
      <div className={`${col} p-5`}>
        <TitleText value="Recent Invoice" />
        <SelectField
          selectOptions={selectOption}
          onChange={onPeriodChange}
          value={period}
        />
      </div>
      <div className="h-[500px] overflow-auto">
        <table className="w-full">
          {dashboardTableHead}
          {dashboardTableBody}
          {/* pagination */}
          <div className="flex my-5 justify-center">
            <Pagination
              sx={{
                "& .MuiPaginationItem-root": {
                  color: isDarkTheme ? "white" : "",
                },
                "& .Mui-selected": {
                  color: "white",
                  backgroundColor: "rgb(74, 7, 160) !important",
                },
              }}
              className="transition-all"
              count={lastPage}
              page={page}
              onChange={handleChange}
            />
          </div>
        </table>
      </div>
    </Frame>
  );
};
export default DashboardTable;
