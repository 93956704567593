import { Container } from "component/Container";
import { productTabs } from "./utils";
import { useProducts } from "./useProducts";
import { Frame } from "component/Frame";
import ProductsTable from "./ProductsTable";
import { BigPlusIcon } from "assets/images/svgs";
import DashboardModal from "component/Modal/Modal";
import AddProduct from "./AddProduct";
import useDisclosure from "hooks/useDisclosure";
import { useGetProductsQuery } from "../../redux/services/productApi";
import { useState } from "react";
import { ProductsType } from "../../../@types/product";
import { useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";

const Products = () => {
  const {
    isOpen: openAddProductModal,
    open: onOpenAddProductModal,
    close: closeAddProductModal,
  } = useDisclosure();
  const { handleProductTabChange, isActiveTab, productTab } = useProducts({
    closeModal: closeAddProductModal,
  });

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<ProductsType>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const {
    data: productsData,
    isLoading: isLoadingProducts,
    isFetching,
  } = useGetProductsQuery({
    search: debouncedSearchVal,
    filter_by: productTab.value,
    page: page,
  });

  const lastPage = productsData?.[0]?.meta?.last_page ?? 0;
  const products = productsData?.[0]?.data ?? [];

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const productTabsData = (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-2.5 flex  sticky justify-between  items-center border-0 rounded-[10px]"
    >
      {productTabs.map((chi, idx) => {
        const { label, value } = chi;
        return (
          <div key={idx} className="w-[500px]">
            <p
              className={`${toggleTextTheme} text-center cursor-pointer ${isActiveTab(
                value
              )}`}
              onClick={() => handleProductTabChange(chi)}
            >
              {label}
            </p>
          </div>
        );
      })}
    </Frame>
  );
  const plusDisplay = (
    <div
      className="absolute right-12 mr-10 bottom-12 mb-14 cursor-pointer"
      onClick={() => onOpenAddProductModal()}
    >
      <BigPlusIcon />
    </div>
  );
  return (
    <Container className="h-[650px]">
      {/* <Container className="h-[full]"> */}
      {productTabsData}

      <ProductsTable
        setIsEdit={setEdit}
        productTableBody={products}
        loading={isLoadingProducts}
        isFetching={isFetching}
        onOpenAddModal={onOpenAddProductModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />

      {plusDisplay}

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
         <Pagination
              sx={{
                "& .MuiPaginationItem-root": {
                  color: isDarkTheme ? "white" : "",
                },
                "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
              }}
              className="transition-all"
              count={lastPage}
              page={page}
              onChange={handleChange}
            />
      </div>

      {/* modal wrap start */}
      <DashboardModal
        open={openAddProductModal}
        // onClose={closeAddProductModal}
        onClose={() => {
          closeAddProductModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddProduct
          closeModal={() => {
            closeAddProductModal();
            setEdit(false);
          }}
          isEdit={edit}
          data={childData as ProductsType}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Container>
  );
};

export default Products;
