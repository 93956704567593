import { Container } from "component/Container";
import { StafffTabEnum, staffTabs } from "./utils";
import { Frame } from "component/Frame";
import { BigPlusIcon } from "assets/images/svgs";
import StaffTable from "./StaffTable";
import { useStaff } from "./useStaff";
import useDisclosure from "hooks/useDisclosure";
import DashboardModal from "component/Modal/Modal";
import { useState } from "react";
import AddStaff from "./AddStaff";
import { useGetStaffQuery } from "../../redux/services/staffApi";
import { useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useGetOutletsQuery } from "../../redux/services/outletApi";
import { useSidebar } from "component/Sidebar/useSidebar";

const Staff = () => {
  const { handleStaffTabChange, isActiveTab, staffTab } = useStaff({
    closeModal: () => {},
  });
  const {
    isOpen: openAddStaffModal,
    open: onOpenAddStaffModal,
    close: closeAddStaffModal,
  } = useDisclosure();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [searchVal, setSearchVal] = useState("");

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const {
    data: staffsData,
    isLoading: isLoading,
    isFetching,
  } = useGetStaffQuery({
    search: debouncedSearchVal,
    filter_by: staffTab.value,
    page: page,
  });

  const {
    data: outletData,
    isLoading: isLoadingOutlet,
    isFetching: isFetchingOutlet,
  } = useGetOutletsQuery(
    {
      search: debouncedSearchVal,
      filter_by: staffTab.value,
      page: page,
    },
    {
      skip: staffTab.value !== StafffTabEnum.OUTLETS,
    }
  );

  const staffs = staffsData?.data?.[0]?.data;
  const lastPage = staffsData?.data?.[0]?.meta?.last_page ?? 0;

  const outlets = outletData?.data?.[0]?.data;
  const outletLastPage = outletData?.data?.[0]?.meta?.last_page ?? 0;
  const isOutlet = staffTab.value === StafffTabEnum.OUTLETS;

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const staffTabData = (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-2.5 px-5 flex justify-between items-center border-0 rounded-[10px]"
    >
      {staffTabs.map((chi, idx) => {
        const { label, value } = chi;
        return (
          <div key={idx} className="w-[500px]">
            <p
              className={`${toggleTextTheme} text-center cursor-pointer ${isActiveTab(
                value
              )}`}
              onClick={() => handleStaffTabChange(chi)}
            >
              {label}
            </p>
          </div>
        );
      })}
    </Frame>
  );
  const plusDisplay = (
    <div
      className="absolute right-12 mr-10 bottom-12 mb-14 cursor-pointer"
      onClick={() => onOpenAddStaffModal()}
    >
      <BigPlusIcon />
    </div>
  );
  return (
    <Container className="h-[650px]">
      {staffTabData}
      <StaffTable
        isOutlet={isOutlet}
        setIsEdit={setEdit}
        staffTableBody={
          staffTab.value === StafffTabEnum.OUTLETS ? outlets : staffs
        }
        loading={isOutlet ? isLoadingOutlet : isLoading}
        isFetching={isOutlet ? isFetchingOutlet : isFetching}
        onOpenAddModal={onOpenAddStaffModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />
      {plusDisplay}

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          count={
            staffTab.value === StafffTabEnum.OUTLETS ? outletLastPage : lastPage
          }
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      {/* modal wrap start */}
      <DashboardModal
        open={openAddStaffModal}
        // onClose={closeAddProductModal}
        onClose={() => {
          closeAddStaffModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddStaff
          closeModal={() => {
            closeAddStaffModal();
            setEdit(false);
          }}
          isOutletTab={isOutlet}
          isEdit={edit}
          data={childData as any}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Container>
  );
};

export default Staff;
