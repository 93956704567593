import { DummyProfile, StaticImage } from "assets/images/svgs";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { Frame } from "component/Frame";
import DashboardModal from "component/Modal/Modal";
import useDisclosure from "hooks/useDisclosure";
import React, { useState } from "react";
import EditProfile from "./EditProfile";
import { useAppSelector } from "../../redux/store";
import { selectUser } from "../../redux/features/authSlice";
import { useGetSingleUserQuery } from "../../redux/services/authApi";
import Skeleton from "react-loading-skeleton";
import { SingleUser } from "../../../@types/auth";
import { useSidebar } from "component/Sidebar/useSidebar";

const SettingsProfile = () => {
  const {
    isOpen: openEditProfileModal,
    open: onOpenEditProfileModal,
    close: closeEditProfileModal,
  } = useDisclosure();
  const user = useAppSelector(selectUser);

  const { id } = user;

  const {
    data: userData,
    isLoading,
    isFetching,
    refetch,
  } = useGetSingleUserQuery(id, {
    skip: !id,
  });

  const { isDarkTheme } = useSidebar();


  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const [userDetail, setUserDetail] = useState<SingleUser>();

  return (
    <Frame rmPadding>
      <div>
        <p
          className={`border-b border-[#999898] p-5 ${toggleTextTheme} font-medium text-2xl`}
        >
          My Profile
        </p>
      </div>
      <div className=" p-5">
        <p className={`ml-4 mb-3 font-medium ${toggleTextTheme} text-sm `}>
          Image
        </p>
        <div className="flex gap-6 ">
          <img
            src={
              userData?.profile_picture
                ? userData?.profile_picture
                : StaticImage
            }
            alt="Profile Preview"
            className="w-[150px] h-[150px] rounded-3xl"
          />
          <div className="flex flex-col gap-1">
            <p className="text-base font-medium capitalize">
              {isLoading || isFetching ? (
                <Skeleton
                  baseColor="#E0E0E0"
                  highlightColor="#F5F5F5"
                  width={300}
                />
              ) : (
                `${userData?.first_name} ${userData?.last_name}` || "---- ----"
              )}
            </p>
            <p className="text-base font-medium">
              {isLoading || isFetching ? (
                <Skeleton
                  baseColor="#E0E0E0"
                  highlightColor="#F5F5F5"
                  width={100}
                />
              ) : (
                userData?.username || "---- ----"
              )}
            </p>
            <p className="text-base font-medium">
              {isLoading || isFetching ? (
                <Skeleton
                  baseColor="#E0E0E0"
                  highlightColor="#F5F5F5"
                  width={200}
                />
              ) : (
                userData?.email || "---- ----"
              )}
            </p>
            <p className="text-base font-medium">
              {isLoading || isFetching ? (
                <Skeleton
                  baseColor="#E0E0E0"
                  highlightColor="#F5F5F5"
                  width={200}
                />
              ) : (
                userData?.phone || "---- ----"
              )}
            </p>
            <p className="text-base font-medium">
              {isLoading || isFetching ? (
                <Skeleton
                  baseColor="#E0E0E0"
                  highlightColor="#F5F5F5"
                  width={300}
                />
              ) : (
                userData?.address || "---- ----"
              )}
            </p>
          </div>
        </div>
        {/* <div> */}
        <Button
          btnType={ButtonTypeEnum.Button}
          label="Edit profile"
          onClick={() => {
            onOpenEditProfileModal();
            setUserDetail(userData);
          }}
          className="w-[153px] mt-5"
        />

        {/* modal wrap start */}
        <DashboardModal
          open={openEditProfileModal}
          onClose={closeEditProfileModal}
          width={"749px"}
        >
          <EditProfile user={userDetail} onCloseModal={closeEditProfileModal} />
        </DashboardModal>
        {/* modal wrap end */}
      </div>
    </Frame>
  );
};

export default SettingsProfile;
