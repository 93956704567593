import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import { useNavigate } from "react-router";
import { useLoginMutation } from "../../redux/services/authApi";

export const useLoginForm = () => {
  const loginValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const loginFormValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const handleSubmit = async () => {
    const payload = { ...formik.values };
    await login(payload)
      .unwrap()
      .then(() => {
        // toast.success("Login Successfully");
        // new Promise(() => {
        setTimeout(() => {
          toast.dismiss();
          navigate("/dashboard");
        });
        // });
      });
  };

  const formik = useFormik({
    initialValues: loginFormValues,
    validationSchema: loginValidationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
  };
};
