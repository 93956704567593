import { SvgTypes } from "./types";

export const ProductIcon = ({ fill }: SvgTypes) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.30938 4.43689V4.00064C4.30938 1.83314 5.97938 0.0618896 8.05938 0.0618896C10.1394 0.0618896 11.8094 1.83314 11.8094 4.00064V4.43814H14.3694C14.5242 4.43829 14.6735 4.49591 14.7883 4.59983C14.903 4.70376 14.9751 4.84658 14.9906 5.00064L16.1156 16.2506C16.1244 16.3375 16.1148 16.4252 16.0876 16.5082C16.0603 16.5911 16.016 16.6674 15.9575 16.7322C15.899 16.7969 15.8275 16.8487 15.7477 16.8842C15.668 16.9197 15.5817 16.9381 15.4944 16.9381H0.624383C0.537088 16.9381 0.450779 16.9197 0.371017 16.8842C0.291254 16.8487 0.219808 16.7969 0.161281 16.7322C0.102753 16.6674 0.058443 16.5911 0.0312045 16.5082C0.00396598 16.4252 -0.00559664 16.3375 0.00313292 16.2506L1.12813 5.00064C1.14362 4.84658 1.21573 4.70376 1.33051 4.59983C1.44528 4.49591 1.59455 4.43829 1.74938 4.43814H4.30938V4.43689ZM5.55938 4.43689H10.5594V4.00064C10.5594 2.50814 9.43188 1.31189 8.05938 1.31189C6.68688 1.31189 5.55938 2.50814 5.55938 4.00064V4.43814V4.43689ZM4.30938 5.68689H2.31563L1.31563 15.6869H14.8031L13.8019 5.68689H11.8094V7.56189C11.8094 7.72765 11.7435 7.88662 11.6263 8.00383C11.5091 8.12104 11.3501 8.18689 11.1844 8.18689C11.0186 8.18689 10.8597 8.12104 10.7424 8.00383C10.6252 7.88662 10.5594 7.72765 10.5594 7.56189V5.68689H5.55938V7.56189C5.55938 7.72765 5.49354 7.88662 5.37632 8.00383C5.25911 8.12104 5.10014 8.18689 4.93438 8.18689C4.76862 8.18689 4.60965 8.12104 4.49244 8.00383C4.37523 7.88662 4.30938 7.72765 4.30938 7.56189V5.68689Z"
      fill={fill || "#999898"}
    />
  </svg>
);
