import { SvgTypes } from "./types";

export const StaffsIcon = ({ fill }: SvgTypes) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 14.25C6.66 14.25 2 15.42 2 17.75V19.5H16V17.75C16 15.42 11.34 14.25 9 14.25ZM4.34 17.5C5.18 16.92 7.21 16.25 9 16.25C10.79 16.25 12.82 16.92 13.66 17.5H4.34ZM9 12.5C10.93 12.5 12.5 10.93 12.5 9C12.5 7.07 10.93 5.5 9 5.5C7.07 5.5 5.5 7.07 5.5 9C5.5 10.93 7.07 12.5 9 12.5ZM9 7.5C9.83 7.5 10.5 8.17 10.5 9C10.5 9.83 9.83 10.5 9 10.5C8.17 10.5 7.5 9.83 7.5 9C7.5 8.17 8.17 7.5 9 7.5ZM16.04 14.31C17.2 15.15 18 16.27 18 17.75V19.5H22V17.75C22 15.73 18.5 14.58 16.04 14.31ZM15 12.5C16.93 12.5 18.5 10.93 18.5 9C18.5 7.07 16.93 5.5 15 5.5C14.46 5.5 13.96 5.63 13.5 5.85C14.13 6.74 14.5 7.83 14.5 9C14.5 10.17 14.13 11.26 13.5 12.15C13.96 12.37 14.46 12.5 15 12.5Z"
      fill={fill || "#999898"}
    />
  </svg>
);
