import { useSidebar } from "component/Sidebar/useSidebar";
import { InputFieldType } from "./types";

const InputField = ({
  label,
  type,
  name,
  onChange,
  placeholder,
  leftIcon,
  rightIcon,
  className,
  onRightIconClick,
  labelClassName,
  value,
  disabled,
}: InputFieldType) => {
  const { isDarkTheme } = useSidebar();
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";

  return (
    <div className="flex flex-col gap-1 ">
      <label htmlFor={name} className={`${labelClassName} ${toggleTextTheme}`}>
        {label}
      </label>
      <div className="relative grid place-items-center">
        {leftIcon ? (
          <img src={leftIcon} alt="placeholder" className="absolute left-3" />
        ) : null}
        <input
          type={type}
          value={value}
          name={name}
          id={name}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          className={`${className} placeholder:text-[#E0E0E0CC] ${toggleTextTheme} pl-10 outline-none w-full placeholder:opacity-[0.8]  border-[0.5px] border-[#E0E0E0]  rounded-[10px] ${toggledBgTheme} p-[15px]`}
        />
        {rightIcon ? (
          <div
            className="absolute right-0 cursor-pointer h-10 w-10 flex flex-col items-center justify-center  mr-1 rounded-full active:bg-[#5c5858cc] transition-all duration-300 hover:bg-[#b8b3b3cc]"
            onClick={onRightIconClick}
          >
            <img
              src={rightIcon}
              alt="placeholder"
              className="w-[20px] h-[10px]"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InputField;
