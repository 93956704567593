import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { useChangePassword } from "./useChangePassword";
import { useState } from "react";
import DefaultCheckIcon from "assets/images/tsx/DefaultCheckIcon";
import { EyeCloseIcon, LockIcon } from "assets/images/svgs";
import { useSidebar } from "component/Sidebar/useSidebar";

const PasswordModal = ({ closeModal }: { closeModal: () => void }) => {
  const { formik, isLoading, passwordValidations, handlePasswordChange } =
    useChangePassword({ closeModal: closeModal });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <div className={`p-5 ${toggledBgTheme} transition-all `}>
      <p className={`${toggleTextTheme} transition-all font-medium text-2xl`}>
        Change password
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-5 mt-14"
      >
        <InputField
          type={showOldPassword ? "text" : "password"}
          label="Enter old password"
          name="old_password"
          onChange={formik.handleChange}
          value={formik.values.old_password}
          leftIcon={LockIcon}
          rightIcon={EyeCloseIcon}
          placeholder="Enter old password"
          onRightIconClick={() => setShowOldPassword(!showOldPassword)}
        />
        <InputField
          type={showPassword ? "text" : "password"}
          label="Enter new password"
          name="password"
          onChange={(e) => {
            formik.handleChange(e);
            handlePasswordChange(e.target.value);
          }}
          value={formik.values.password}
          leftIcon={LockIcon}
          rightIcon={EyeCloseIcon}
          placeholder="Enter new password"
          onRightIconClick={() => setShowPassword(!showPassword)}
        />
        <div className="relative">
          <InputField
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            name="password_confirmation"
            onChange={formik.handleChange}
            leftIcon={LockIcon}
            rightIcon={EyeCloseIcon}
            placeholder="Confirm password"
            onRightIconClick={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
          />
          {formik.values.password ? (
            <p className="ont-light text-xs text-red-900  absolute mt-2">
              {formik.errors.password_confirmation}
            </p>
          ) : null}
        </div>
        <div className="flex flex-col gap-3 mt-5">
          {passwordValidations.map((chi, idx) => {
            const { label, checked } = chi;
            return (
              <div className="flex gap-2 items-center" key={idx}>
                <DefaultCheckIcon fill={checked ? "#4a934a" : ""} />
                <p
                  className={`font-light text-xs transition-all duration-300 ${
                    isDarkTheme ? "text-white" : "text-custom-gray-scale-300"
                  } ${checked ? "text-[#4a934a]" : ""}`}
                >
                  {label}
                </p>
              </div>
            );
          })}
        </div>

        <Button
          btnType={ButtonTypeEnum.Submit}
          label="Continue"
          className="mt-14"
          loading={isLoading}
          disabled={!formik.isValid || !formik.dirty}
        />
      </form>
    </div>
  );
};

export default PasswordModal;
