import { StaticImage } from "assets/images/svgs";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { SingleUser } from "../../../@types/auth";
import * as yup from "yup";
import { useUpdateProfileMutation } from "../../redux/services/settingsApi";
import { toast } from "sonner";
import { useFormik } from "formik";
import { useState } from "react";
import { base64ToBlob } from "helpers/helper";
import { useSidebar } from "component/Sidebar/useSidebar";

const EditProfile = ({
  user,
  onCloseModal,
}: {
  user?: SingleUser;
  onCloseModal: () => void;
}) => {
  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3";

  const validationSchema = yup.object().shape({
    first_name: yup
      .string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters"),

    last_name: yup
      .string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters"),

    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),

    phone: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d+$/, "Phone number must be digits only")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number can't be longer than 15 digits"),

    address: yup.string().optional(),

    username: yup.string().optional(),
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | undefined>("");

  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  // console.log("testing", selectedFile, preview);

  const formik = useFormik({
    initialValues: {
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: user?.address || "",
      username: user?.username || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      const formData = new FormData();
      formData.append("first_name", formik.values.first_name);
      formData.append("last_name", formik.values.last_name);
      formData.append("email", formik.values.email);
      formData.append("phone", formik.values.phone);
      formData.append("address", formik.values.address);
      formData.append("username", formik.values.username);

      if (selectedFile) {
        console.log(selectedFile, "yes");
        formData.append("profile_picture", selectedFile);
      } else if (preview) {
        const imageBlob = base64ToBlob(preview);
        formData.append("profile_picture", imageBlob, "profile_picture.jpg");
      }

      if (!user?.id) {
        toast.error("User ID is missing");
        return;
      }

      await updateProfile({ payload: formData, id: user.id }).unwrap();
      toast.success("Profile Updated Successfully");
      setTimeout(() => {
        toast.dismiss();
        onCloseModal();
      }, 2000);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  return (
    <div className={`p-5 pb-16 px-10 ${toggledBgTheme} `}>
      <p className={`${toggleTextTheme} font-medium text-2xl mt-4 m-5`}>
        Edit Profile
      </p>
      <div>
        <p className={`ml-4 mb-3 font-medium ${toggleTextTheme} text-sm `}>
          Image
        </p>
        <input
          id="file"
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />

        <div>
          <img
            src={
              preview
                ? preview
                : user?.profile_picture
                ? user.profile_picture
                : StaticImage
            }
            alt="Profile Preview"
            className="w-[150px] h-[150px] rounded-3xl"
          />
        </div>

        <label
          htmlFor="file"
          className="text-[#999898] font-medium text-xs mt-2 cursor-pointer"
        >
          Click to change image
        </label>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-2 gap-y-5 gap-x-16 mt-5 relative"
      >
        <InputField
          type="text"
          label="First name"
          name="first_name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          placeholder="Enter first name"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Last name"
          name="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          placeholder="Enter last name"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Enter email"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Phone number"
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          placeholder="Enter phone number"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Address"
          name="address"
          value={formik.values.address}
          onChange={formik.handleChange}
          placeholder="Enter address"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Tagline"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          placeholder="Hi there"
          className={inputClass}
          labelClassName={labelClass}
        />
        <div></div>
        <div className="">
          <Button
            btnType={ButtonTypeEnum.Submit}
            label="Save"
            className="mt-14 w-[304px]"
            loading={isLoading}
            disabled={!formik.isValid || (!formik.dirty && !preview)}
          />
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
