import { Container } from "component/Container";
import { Frame } from "component/Frame";
import { BigPlusIcon } from "assets/images/svgs";
import DashboardModal from "component/Modal/Modal";
import useDisclosure from "hooks/useDisclosure";
import { useGetProductsQuery } from "../../redux/services/productApi";
import { useState } from "react";
import { ProductsType } from "../../../@types/product";
import { useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";
import { productTabs } from "pages/Products/utils";
import ProductsTable from "pages/Products/ProductsTable";
import { useProducts } from "pages/Products/useProducts";
import ReportsTable from "./ReportsTable";
import { useSearchParams } from "react-router-dom";
import {
  ProductTab,
  InvoiceTab,
  PurchaseTab,
  SalesTab,
  StaffTab,
  OutletTab,
  RentTab,
  DebitTab,
  CreditTab,
} from "./partials";

const getActiveTab = (tab: string | null) => {
  switch (tab) {
    case "product":
      return <ProductTab />;
    case "invoice":
      return <InvoiceTab />;
    case "sales":
      return <SalesTab />;
    case "rent":
      return <RentTab />;
    case "purchase":
      return <PurchaseTab />;
    case "staff":
      return <StaffTab />;
    case "outlet":
      return <OutletTab />;
    case "credit":
      return <CreditTab />;
    case "debit":
      return <DebitTab />;

    default:
      return <ProductTab />;
  }
};

const Reports = () => {
  const {
    isOpen: openAddProductModal,
    open: onOpenAddProductModal,
    close: closeAddProductModal,
  } = useDisclosure();
  const { handleProductTabChange, productTab } = useProducts({
    closeModal: closeAddProductModal,
  });

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<ProductsType>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const {
    data: productsData,
    isLoading: isLoadingProducts,
    isFetching,
  } = useGetProductsQuery({
    search: debouncedSearchVal,
    filter_by: productTab.value,
    page: page,
  });

  const lastPage = productsData?.[0]?.meta?.last_page ?? 0;
  const products = productsData?.[0]?.data ?? [];

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const [searchParams, setSearchParams] = useSearchParams();

  // Get a tab query parameter
  const tab = searchParams.get("tab");

  const reportsTab = [
    // { label: "All Reports", value: "" },
    { label: "Products", value: "product" },
    { label: "Invoice", value: "invoice" },
    { label: "Sales", value: "sales" },
    { label: "Rent", value: "rent" },
    { label: "Purchase", value: "purchase" },
    { label: "Staff", value: "staff" },
    { label: "Outlets", value: "outlet" },
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];

  // style for active tab
  const isActiveTab = (tabValue: string) => {
    return tab === tabValue
      ? "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
      : "";
  };

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();

  // const productTabsData = (
  //   <Frame
  //     rmPadding
  //     bg={toggledBgTheme}
  //     className="p-2.5 flex  sticky justify-between  items-center border-0 rounded-[10px]"
  //   >
  //     {reportsTab.map((chi, idx) => {
  //       const { label, value } = chi;
  //       return (
  //         <div key={idx} className="w-[500px]">
  //           <p
  //             className={`${toggleTextTheme} text-center cursor-pointer ${isActiveTab(
  //               value
  //             )}`}
  //             onClick={() => {
  //               setSearchParams({ tab: value });
  //             }}
  //           >
  //             {label}
  //           </p>
  //         </div>
  //       );
  //     })}
  //   </Frame>
  // );

  const totalCards = (
    <div
      onClick={onOpenBreakModal}
      className="bg-white cursor-pointer hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]"
    >
      <p className="text-black font-medium text-3xl ">{`Total ${"Products"}`}</p>
      <p className="font-medium text-4xl mt-10">50</p>
    </div>
  );

  const breakModal = (
    <div className="p-5 grid place-items-center">
      <p className="font-semibold text-2xl capitalize text-center">{`${"Product"} Breakdown`}</p>
      <div className="grid grid-rows place-items-center gap-10 mt-5">
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
        <div className="grid grid-cols-2 gap-10">
          <p>Big Tyre</p>
          <p>3</p>
        </div>
      </div>
    </div>
  );
  // console.log(getActiveTab(tab));
  return (
    <Container className="h-[650px]">
      <>
        <Frame
          rmPadding
          bg={toggledBgTheme}
          className="p-2.5 flex  sticky justify-between  items-center border-0 rounded-[10px]"
        >
          {reportsTab.map((chi, idx) => {
            const { label, value } = chi;
            return (
              <div key={idx} className="w-[500px]">
                <p
                  className={`${toggleTextTheme} text-center cursor-pointer ${isActiveTab(
                    value
                  )} ${
                    tab === null &&
                    value === "product" &&
                    "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
                  } `}
                  onClick={() => {
                    setSearchParams({ tab: value });
                  }}
                >
                  {label}
                </p>
              </div>
            );
          })}
        </Frame>

        {getActiveTab(tab)}
        {/* {totalCards} */}
        {/* 
      <ReportsTable
        titleValue={reportLabel}
        setIsEdit={setEdit}
        productTableBody={products}
        loading={isLoadingProducts}
        isFetching={isFetching}
        onOpenAddModal={onOpenAddProductModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      /> */}

        {/* {plusDisplay} */}

        {/* pagination */}
        {/* <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      <DashboardModal open={openBreakModal} onClose={closeBreakModal}>
        {breakModal}
      </DashboardModal> */}
      </>
    </Container>
  );
};

export default Reports;
