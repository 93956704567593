import { useFormik } from "formik";
import { toast } from "sonner";
import * as yup from "yup";
import {
  useCreateStaffMutation,
  useUpdateStaffMutation,
} from "../../redux/services/staffApi";
import {
  useCreateOutletMutation,
  useUpdateOutletMutation,
} from "../../redux/services/outletApi";

export const useAddStaff = ({
  closeModal,
  isUpdate,
  ID,
  data,
  isActiveOutletTab,
}: {
  closeModal: () => void;
  isUpdate?: boolean;
  ID: number;
  data: any;
  isActiveOutletTab?: boolean;
}) => {
  const validationSchema = yup.object().shape({
    staff_name: yup.string().required("Staff name is required"),
    id_number: yup.string().required("ID number category is required"),
    position: yup.string().required("Position is required"),
    pay_rate: yup.string().required("Pay field is required"),
    outlet: yup.object().shape({
      value: yup.string().required("Outlet is required"),
    }),
    address: yup.string().required("Address is required"),
    contact_number: yup.string().required("Contact no is required"),
    guardian_number: yup.string().required("Guardian no is required"),
    date_of_birth: yup.string().required("Date is required"),
  });

  const outletValidationSchema = yup.object().shape({
    outlet_name: yup.string().required("Outlet name is required"),
    address: yup.string().required("Address is required"),
    number_of_staff: yup.string().required("Staffs no is required"),
    year_of_establishment: yup
      .string()
      .required("Year opened field is required"),
  });

  const formValues = {
    staff_name: (isUpdate && data?.staff_name) || "",
    id_number: (isUpdate && data?.id_number) || "",
    position: (isUpdate && data?.position) || "",
    pay_rate: (isUpdate && data?.pay_rate) || "",
    outlet: (isUpdate && {
      label: data?.outlet?.outlets_name,
      value: data?.outlet?.id,
    }) || {
      label: "Select outlet...",
      value: "",
    },

    address: (isUpdate && data?.address) || "",
    contact_number: (isUpdate && data?.contact_number) || "",
    guardian_number: (isUpdate && data?.guardian_number) || "",
    date_of_birth: (isUpdate && data?.date_of_birth) || "",
    outlet_name: (isUpdate && data?.outlets_name) || "",
    number_of_staff: (isUpdate && data?.number_of_staff) || "",
    year_of_establishment: (isUpdate && data?.year_of_establishment) || "",
  };

  const [createStaff, { isLoading }] = useCreateStaffMutation();
  const [updateStaff, { isLoading: isLoadingUpdate }] =
    useUpdateStaffMutation();

  const [createOutlet, { isLoading: isLoadingOutlet }] =
    useCreateOutletMutation();
  const [updateOutlet, { isLoading: isUpdatingOutlet }] =
    useUpdateOutletMutation();

  const handleSubmit = async () => {
    const obj = {
      //   ...formik.values,
      address: formik.values.address,
      staff_name: !isActiveOutletTab ? formik.values.staff_name : null,
      id_number: !isActiveOutletTab ? formik.values.id_number : null,
      position: !isActiveOutletTab ? formik.values.position : null,
      pay_rate: !isActiveOutletTab ? formik.values.pay_rate : null,
      outlet_id: !isActiveOutletTab ? formik.values.outlet.value : null,
      contact_number: !isActiveOutletTab ? formik.values.contact_number : null,
      guardian_number: !isActiveOutletTab
        ? formik.values.guardian_number
        : null,
      date_of_birth: !isActiveOutletTab ? formik.values.date_of_birth : null,
      outlet_name: isActiveOutletTab ? formik.values.outlet_name : null,
      number_of_staff: isActiveOutletTab
        ? String(formik.values.number_of_staff)
        : null,
      year_of_establishment: isActiveOutletTab
        ? formik.values.year_of_establishment
        : null,
    };

    const payload = { payload: obj, id: ID };

    const handleSuccess = (message: string) => {
      toast.success(message);
      new Promise(() => {
        setTimeout(() => {
          toast.dismiss();
          closeModal();
        }, 1000);
      });
    };

    // try {
    switch (true) {
      case isUpdate && isActiveOutletTab:
        await updateOutlet(payload).unwrap();
        handleSuccess("Outlet Updated Successfully");
        break;

      case isActiveOutletTab && !isUpdate:
        await createOutlet(obj).unwrap();
        handleSuccess("Outlet Created Successfully");
        break;

      case !isActiveOutletTab && isUpdate:
        await updateStaff(payload).unwrap();
        handleSuccess("Staff Updated Successfully");
        break;

      default:
        await createStaff(obj).unwrap();
        handleSuccess("Staff Created Successfully");
        break;
    }
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: isActiveOutletTab
      ? outletValidationSchema
      : validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingOutlet,
    isUpdatingOutlet,
  };
};
