import Cookies from "js-cookie";
import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface PublicRouteProps {
  children: ReactNode;
  redirectTo: string; // The route to redirect to if the user is authenticated
}

const getToken = (): string | undefined => {
  return Cookies.get("token"); // Get token from cookies
};

const PublicRoute: React.FC<PublicRouteProps> = ({ children, redirectTo }) => {
  const location = useLocation();
  const token = getToken();

  // If the user is authenticated (token exists), redirect to the private route (e.g., dashboard)
  if (token) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  // If no token, allow access to the public route
  return <>{children}</>;
};

export default PublicRoute;
