import { BigPlusIcon } from "assets/images/svgs";
import PurchaseTable from "../../Purchase/PurchaseTable";
import DashboardModal from "component/Modal/Modal";
import useDisclosure from "hooks/useDisclosure";
import { useRef, useState } from "react";
import AddPurchase from "../../Purchase/AddPurchase";
import { handleDateFilter, useDebounce } from "helpers/helper";
import { useGetPurchaseQuery } from "../../../redux/services/purchaseApi";
import { Pagination } from "@mui/material";
import { useSidebar } from "component/Sidebar/useSidebar";
import {
  useGetPurchaseBreakdownQuery,
  useGetPurchasesReportsQuery,
} from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";
import { PrintIcon } from "assets/images/tsx";
import { useReactToPrint } from "react-to-print";

export default function PurchaseTab() {
  const {
    isOpen: openAddPurchaseModal,
    open: onOpenPurchaseModal,
    close: closeAddPurchaseModal,
  } = useDisclosure();
  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const {
    isOpen: openDeleteModal,
    open: onOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();
  const { isDarkTheme } = useSidebar();

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // upate state
  const [filterVal, setFilterVal] = useState<any>(""); //update type

  const {
    data: purchaseData,
    isLoading: isLoadingPurchase,
    isFetching,
  } = useGetPurchasesReportsQuery({
    search: debouncedSearchVal,
    "time[start_date]": handleDateFilter("start_date", fromDate, toDate),
    "time[end_date]": handleDateFilter("end_date", fromDate, toDate),
    page: page,
    filter: filterVal.value,
  });

  const {
    data: productCount,
    isLoading: isLoadingProductsCount,
    isFetching: isFetchingProductCount,
  } = useGetPurchaseBreakdownQuery({
    search: debouncedSearchVal,
    // filter_by: productTab.value,
    page: page,
  });

  const lastPage = purchaseData?.last_page ?? 0;
  const purchases = purchaseData?.data;

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const {
    isOpen: openBreakModal,
    open: onOpenBreakModal,
    close: closeBreakModal,
  } = useDisclosure();

  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";

  const totalCards = (
    <div
      onClick={onOpenBreakModal}
      className={`${toggledCardTheme} cursor-pointer hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p className={`${toggleTextTheme} font-medium text-3xl `}>
        Total Purchase
      </p>
      <p className={`${toggleTextTheme} font-medium text-4xl mt-10`}>
        {isLoadingProductsCount ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={40}
            height={30}
          />
        ) : (
          productCount?.total_purchase || "n/a"
        )}
      </p>
    </div>
  );

  const titleClass = `font-medium font-bold text-center w-full p-2 ${toggleTextTheme} min-w-fit mb-3`;
  const colClass = `grid grid-cols-2 border-b-2 p-2 w-full gap-10 ${toggleTextTheme}`;
  const textClass = "text-[#4A07A0] font-semibold";

  const header = (
    <div className={colClass}>
      <p className="font-semibold">Items</p>
      <p className="font-semibold text-right border-l-2">Quantities</p>
    </div>
  );

  // print start (copy)
  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Invoice Data",
  });
  // print end (copy)

  const breakModal = (
    <>
      {/* copy this div start */}
      <div className="m-5" onClick={handlePrint}>
        <PrintIcon
          className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
        />
      </div>
      {/* copy this div end */}
      <div
        // pass the ref attribute and the componentRef variable as the value
        ref={componentRef} //copy this
        className="p-5 grid place-items-center w-full min-w-fit "
      >
        <p
          className={`${toggleTextTheme} font-semibold text-2xl capitalize text-center`}
        >{`${"Purchase"} Breakdown`}</p>
        <div className="grid  mt-5 pb-10 h-[40rem]">
          <div className="">
            <h3 className={titleClass}>Product quantities</h3>
            <div className="grid grid-rows place-items-center gap-5 overflow-auto">
              {header}
              {(productCount?.purchase_brake_down as any[])?.map((chi, idx) => {
                const { product_name, quantity } = chi;
                return (
                  <div className={colClass}>
                    <p className={`${textClass} `}>
                      {product_name || "--- ---"}
                    </p>
                    <p className="font-semibold text-right border-l-2">
                      {quantity || "0.00"}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* second layer */}
          {/* <div>
          <h3 className={titleClass}>Out of stock products</h3>
          <div className="grid grid-rows place-items-center gap-5 overflow-auto">
            {header}
            {(productCount?.out_of_stock_products as any[])?.map((chi, idx) => {
              const { product_name, quantity } = chi;
              return (
                <div className={colClass}>
                  <p className={textClass}>{product_name}</p>
                  <p className="font-semibold text-right">{quantity}</p>
                </div>
              );
            })}
          </div>
        </div> */}
        </div>
      </div>
    </>
  );

  return (
    <>
      {totalCards}
      {/* {purchaseTabData} */}
      <PurchaseTable
        isReport
        isNotDeleteable
        isNotEditable
        dateRange={true}
        setStartDate={setFromDate}
        setEndDate={setToDate}
        loaderBottom
        dntShowAdd
        setIsEdit={setEdit}
        purchaseTableBody={purchases}
        loading={isLoadingPurchase}
        isFetching={isFetching}
        onOpenAddModal={onOpenPurchaseModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
        onSortChange={(e) => setFilterVal(e)}
        sortVal={filterVal}
      />
      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>

      {/* modal wrap start */}
      <DashboardModal
        open={openAddPurchaseModal}
        // onClose={closeAddPurchaseModal}
        onClose={() => {
          closeAddPurchaseModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddPurchase
          closeModal={() => {
            closeAddPurchaseModal();
            setEdit(false);
          }}
          isEdit={edit}
          data={childData as any}
        />
      </DashboardModal>
      {/* modal wrap end */}
      <DashboardModal
        width="50%"
        open={openBreakModal}
        onClose={closeBreakModal}
      >
        {breakModal}
      </DashboardModal>
    </>
  );
}
