import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import { useState } from "react";
import { useChangePasswordMutation } from "../../redux/services/settingsApi";

export const useChangePassword = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const passwordValidationMessage = `
    Must have 8 characters.
    Must include capital and small letters.
    Must include one number/special character.
  `;

  const validationSchema = yup.object().shape({
    old_password: yup.string().required("Old Password is required"),
    password: yup
      .string()
      .required("Password is required")
      .test("password-validation", passwordValidationMessage, (value) => {
        if (!value) return false;
        const hasMinLength = value.length >= 8;
        const hasLowercase = /[a-z]/.test(value);
        const hasUppercase = /[A-Z]/.test(value);
        const hasNumberOrSpecialChar =
          /[0-9]/.test(value) || /[@$!%*?&]/.test(value);
        return (
          hasMinLength && hasLowercase && hasUppercase && hasNumberOrSpecialChar
        );
      }),
    password_confirmation: yup
      .string()
      .required("Password confirmation is required")
      .test("password-match", "Passwords must match", function (value) {
        return value === this.parent.password;
      }),
  });

  const passwordCriteria = [
    { label: "Must have 8 characters", regex: /.{8,}/ },
    {
      label: "Must include capital and small letters",
      regex: /^(?=.*[a-z])(?=.*[A-Z])/,
    },
    {
      label: "Must include one number/special character",
      regex: /^(?=.*[0-9])|(?=.*[@$!%*?&])/,
    },
  ];

  const [passwordValidations, setPasswordValidations] = useState(
    passwordCriteria.map((criterion) => ({ ...criterion, checked: false }))
  );

  const handlePasswordChange = (password: string) => {
    const updatedValidations = passwordCriteria.map((criterion) => ({
      ...criterion,
      checked: criterion.regex.test(password),
    }));
    setPasswordValidations(updatedValidations);
  };

  const formValues = {
    old_password: "",
    password: "",
    password_confirmation: "",
  };

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const handleSubmit = async () => {
    const payload = { ...formik.values };
    await changePassword(payload)
      .unwrap()
      .then(() => {
        toast.success("Password Changed Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
            closeModal();
          }, 1000);
        });
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    passwordValidations,
    handlePasswordChange,
  };
};
