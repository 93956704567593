import React from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { formatDateToYYYYMMDD } from "helpers/helper";
import { useAddStaff } from "./useAddStaff";
import { useGetSalesOutletQuery } from "../../redux/services/salesApi";
import { SelectField } from "component/Select";
import { OptionType } from "pages/Dashboard/types";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useGetOutletsNoPaginationQuery } from "../../redux/services/outletApi";

interface Props {
  closeModal: () => void;
  isEdit?: boolean;
  data: any;
  isOutletTab?: boolean;
}

const AddStaff: React.FC<Props> = ({
  closeModal,
  isEdit,
  data,
  isOutletTab,
}) => {
  const {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingOutlet,
    isUpdatingOutlet,
  } = useAddStaff({
    closeModal,
    isUpdate: isEdit,
    ID: data?.id,
    data,
    isActiveOutletTab: isOutletTab,
  });

  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3";

  const { data: salesOutletsData, isLoading: isLoadingSalesOutlets } =
    useGetOutletsNoPaginationQuery();

  const salesOutlets = salesOutletsData?.data?.[0] ?? [];
  const handleFormatSalesOutletDrop = () => {
    const newData = salesOutlets?.map((chi: any) => ({
      ...chi,
      label: chi.outlets_name,
      value: chi.id,
    }));
    return newData;
  };

  const getTitle = (isEdit?: boolean, isOutletTab?: boolean): string => {
    if (isEdit && !isOutletTab) {
      return "Update Staff";
    } else if (isOutletTab && !isEdit) {
      return "Add Outlet";
    } else if (isOutletTab && isEdit) {
      return "Update Outlet";
    } else {
      return "Add Staff";
    }
  };

  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-[#f0f0f0]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <form onSubmit={formik.handleSubmit} className="p-6 px-10">
      <div>
        <p className={`${toggleTextTheme} transition-all font-medium text-xl`}>
          {getTitle(isEdit, isOutletTab)}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        <InputField
          type="text"
          label={isOutletTab ? "Outlet name" : "Staff name"}
          name={isOutletTab ? "outlet_name" : "staff_name"}
          onChange={formik.handleChange}
          value={
            isOutletTab ? formik.values.outlet_name : formik.values.staff_name
          }
          placeholder={isOutletTab ? "Enter outlet name" : "Enter staff name"}
          className={inputClass}
          labelClassName={labelClass}
        />
        {!isOutletTab ? (
          <InputField
            type="text"
            label="ID number"
            name="id_number"
            onChange={formik.handleChange}
            value={formik.values.id_number}
            placeholder="Enter staff ID number"
            className={inputClass}
            labelClassName={labelClass}
          />
        ) : null}
        {!isOutletTab ? (
          <InputField
            type="text"
            label="position"
            name="position"
            onChange={formik.handleChange}
            value={formik.values.position}
            placeholder="Enter position"
            className={inputClass}
            labelClassName={labelClass}
          />
        ) : null}
        {!isOutletTab ? (
          <InputField
            type="text"
            label="pay"
            name="pay_rate"
            onChange={formik.handleChange}
            value={formik.values.pay_rate}
            placeholder="Enter staff monthly"
            className={inputClass}
            labelClassName={labelClass}
          />
        ) : null}
        {!isOutletTab ? (
          <div className="flex flex-col gap-1 -ml-3 ">
            <label className={`${labelClass} ${toggleTextTheme} ml-3`}>
              Outlet
            </label>
            <SelectField
              value={formik.values.outlet as OptionType}
              selectOptions={handleFormatSalesOutletDrop()}
              onChange={(e: any) => {
                formik.setFieldValue("outlet", e);
                formik.setFieldValue("outlet.id", e?.value);
              }}
              placeholder="Select outlet.."
              className={`${inputClass} !w-full`}
              padding="7px"
              bgColor={isDarkTheme ? "#222121" : "white"}
              isLoading={isLoadingSalesOutlets}
            />
          </div>
        ) : null}

        <InputField
          type="text"
          label="Address"
          name="address"
          onChange={formik.handleChange}
          value={formik.values.address}
          placeholder="Enter address"
          className={inputClass}
          labelClassName={labelClass}
        />

        <InputField
          type={isOutletTab ? "number" : "text"}
          label={isOutletTab ? "No of Staff" : "Contact no"}
          name={isOutletTab ? "number_of_staff" : "contact_number"}
          onChange={formik.handleChange}
          value={
            isOutletTab
              ? formik.values.number_of_staff
              : formik.values.contact_number
          }
          placeholder={isOutletTab ? "Enter staff no" : "Enter contact no"}
          className={inputClass}
          labelClassName={labelClass}
        />

        <InputField
          type="text"
          label={isOutletTab ? "Year opened" : "Guardian no"}
          name={isOutletTab ? "year_of_establishment" : "guardian_number"}
          onChange={formik.handleChange}
          value={
            isOutletTab
              ? formik.values.year_of_establishment
              : formik.values.guardian_number
          }
          placeholder={isOutletTab ? "Enter year opened" : "Enter guardian no"}
          className={inputClass}
          labelClassName={labelClass}
        />

        {!isOutletTab ? (
          <div className="flex flex-col">
            <label className={`${labelClass} ${toggleTextTheme} mb-1`}>
              DOB
            </label>
            <Flatpickr
              value={formik.values.date_of_birth}
              onChange={(selectedDates: Date[]) => {
                const [date] = selectedDates;
                formik.setFieldValue(
                  "date_of_birth",
                  formatDateToYYYYMMDD(date)
                );
              }}
              placeholder="Enter date of birth"
              className={`${inputClass} ${toggleTextTheme}  ${toggledDateTheme} rounded-lg border  outline-none h-[50px] `}
            />
          </div>
        ) : null}
      </div>
      <div className="flex justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Submit}
          label={isEdit ? "Update" : "Save"}
          className="w-[284px]"
          disabled={
            !formik.isValid || !formik.dirty
            // !formik.isValid || !formik.dirty || !formik.values.outlet.value
          }
          loading={
            isLoading || isLoadingUpdate || isLoadingOutlet || isUpdatingOutlet
          }
        />
      </div>
    </form>
  );
};

export default AddStaff;
