import useDisclosure from "hooks/useDisclosure";
import { useState } from "react";
import { handleDateFilter, useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import DashboardModal from "component/Modal/Modal";
import { useCredit } from "../../Credit/useCredit";
import { CreditTabEnum } from "../../Credit/utils";
import { useGetCreditsQuery } from "../../../redux/services/creditApi";
import { useGetDebitsQuery } from "../../../redux/services/debitApi";
import CreditTable from "../../Credit/CreditTable";
import AddCredits from "../../Credit/AddCredit";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useGetCreditReportsQuery } from "../../../redux/services/reportApi";
import Skeleton from "react-loading-skeleton";

export default function CreditTab() {
  const {
    isOpen: openAddCreditModal,
    open: onOpenAddCreditModal,
    close: closeAddCreditModal,
  } = useDisclosure();
  const { creditTab } = useCredit();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const {
    data: creditData,
    isLoading: isLoadingCredit,
    isFetching,
  } = useGetCreditReportsQuery({
    search: debouncedSearchVal,
    filter_by: creditTab.value,
    "time[start_date]": handleDateFilter("start_date", fromDate, toDate),
    "time[end_date]": handleDateFilter("end_date", fromDate, toDate),
    page: page,
  });

  const {
    data: debitData,
    isLoading: isLoadingDebit,
    isFetching: isFetchingDebit,
  } = useGetDebitsQuery(
    {
      search: debouncedSearchVal,
      filter_by: creditTab.value,
      page: page,
    },
    { skip: creditTab.value !== CreditTabEnum.DEBIT }
  );

  const credits = creditData?.data;
  const lastPage = creditData?.last_page ?? 0;

  const debits = debitData?.data[0]?.data;
  const debitLastPage = debitData?.data[0]?.last_page ?? 0;

  const isDebit = creditTab.value === CreditTabEnum.DEBIT;

  const { isDarkTheme } = useSidebar();

  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggledCardTheme = isDarkTheme ? "bg-[#222121]" : "bg-white";

  const totalCards = (
    <div
      // onClick={onOpenBreakModal}
      className={`${toggledCardTheme} hover:scale-[1.02] transition-all duration-300 rounded-lg max-h-full shadow-lg p-5 h-[250px] w-[400px]`}
    >
      <p className={`${toggleTextTheme} text-black font-medium text-3xl`}>
        Total credit
      </p>
      <p className={`${toggleTextTheme} font-medium text-4xl mt-10`}>
        {isLoadingCredit ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={40}
            height={30}
          />
        ) : (
          creditData?.total || "0"
        )}
      </p>
    </div>
  );

  return (
    <>
      {totalCards}
      <CreditTable
        isReport
        isNotDeleteable
        isNotEditable
        dateRange={true}
        setStartDate={setFromDate}
        setEndDate={setToDate}
        dntShowAdd
        loaderBottom={true}
        isDebitTab={isDebit}
        setIsEdit={setEdit}
        creditTableBody={credits}
        loading={isDebit ? isLoadingDebit : isLoadingCredit}
        isFetching={isDebit ? isFetchingDebit : isFetching}
        onOpenAddModal={onOpenAddCreditModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />
      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={
            creditTab.value === CreditTabEnum.DEBIT ? debitLastPage : lastPage
          }
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddCreditModal}
        // onClose={closeAddCreditModal}
        onClose={() => {
          closeAddCreditModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddCredits
          closeModal={() => {
            closeAddCreditModal();
            setEdit(false);
          }}
          isDebitTab={isDebit}
          isEdit={edit}
          data={childData}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </>
  );
}
