export enum InvoiceTabEnum {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}
export const invoiceTabs = [
  { label: "All", value: "" },
  // { label: "All category", value: ProductTabEnum.PRODUCT_CATEGORY },
  { label: "Daily", value: InvoiceTabEnum.DAILY },
  { label: "Weekly", value: InvoiceTabEnum.WEEKLY },
  { label: "Monthly", value: InvoiceTabEnum.MONTHLY },
];

// export const invoiceTabs = ["All", "Daily", "Weekly", "Monthly"];
export const sortOptions = [
  {
    label: "Images",
    value: "images",
  },
  {
    label: "Product name",
    value: "product_name",
  },
  {
    label: "Description",
    value: "description",
  },
];

export const invoiceTableHead = [
  "Invoice to",
  "Delivered to",
  // "Description",
  // "Quantity",
  "Payment method",
  // "Unit price",
  // "Cost price",
  "Invoice no",
  "Invoice date",
  "Status",
  "Amount",
  "Discount",
  "Paid",
  "Balance",

  // "Total Amount",
  // "Code number",
  "Action",
];

export const newSalesTableHead = [
  "Invoice to",
  "Delivered to",
  "Payment method",
  "Invoice no",
  "Invoice date",
  "Status",
  "Amount",
  "Discount",
  "Paid",
  "Balance",
  "",
];

export const invoiceTableBody = [
  {
    invoice_to: "Hassan Lamidi",
    delivered_to: "Charles Uwaje",
    description: "Bus tire",
    quantity: "1",
    payment_method: "Cash",
    unit_price: "₦30,000",
    cost_price: "₦50,000",
    invoice_no: "311833",
    invoice_date: "25/10/2024",
    code_number: "#35647636",
  },
];

export const invoiceGenerateTableHead = [
  "Product Name",
  "Description",
  "Quantity",
  "Unit price",
  "Amount",
  "Action",
];
export const invoiceTemplateTableHead = [
  "Product name",
  "Description",
  "Quantity",
  "Unit price",
  "Amount",
];

export type InvoiceItem = {
  id: number;
  description: string;
  quantity: number;
  unit_price: number;
  amount: number;
  currency: string;
  cost_price: number;
  description_name: string;
};

export interface ScannedItem {
  discount?: string | number;
  quantityVal?: string;
  id: number;
  product_name: string;
  selling_price: string;
  product_description: string;
  quantity: number;
  amount: number;
  currency: string;
}

export type InvoiceData = InvoiceItem[];

export const invoiceGenerateData: InvoiceData = [
  {
    id: 1,
    description:
      "The product is good to go and is one of a kind widely chosen by many vehicles",
    quantity: 3,
    unit_price: 800,
    amount: 30000,
    cost_price: 1000,
    description_name: "Garri",
    currency: "₦",
  },
  {
    id: 2,
    description:
      "The product is good to go and is one of a kind widely chosen by many vehicles",
    quantity: 3,
    unit_price: 800,
    amount: 30000,
    cost_price: 1000,
    description_name: "Garri",
    currency: "₦",
  },
  {
    id: 3,
    description:
      "The product is good to go and is one of a kind widely chosen by many vehicles",
    quantity: 3,
    unit_price: 800,
    amount: 30000,
    cost_price: 1000,
    description_name: "Garri",
    currency: "₦",
  },
  {
    id: 4,
    description:
      "The product is good to go and is one of a kind widely chosen by many vehicles",
    quantity: 3,
    unit_price: 800,
    amount: 30000,
    cost_price: 1000,
    description_name: "Garri",
    currency: "₦",
  },
  {
    id: 5,
    description:
      "The product is good to go and is one of a kind widely chosen by many vehicles",
    quantity: 3,
    unit_price: 800,
    amount: 30000,
    cost_price: 1000,
    description_name: "Garri",
    currency: "₦",
  },
  {
    id: 6,
    description:
      "The product is good to go and is one of a kind widely chosen by many vehicles",
    quantity: 3,
    unit_price: 800,
    amount: 30000,
    cost_price: 1000,
    description_name: "Garri",
    currency: "₦",
  },
];
