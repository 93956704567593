import { SvgTypes } from "../tsx/types";

export const SalesIcon = ({ fill }: SvgTypes) => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.83333 8.66667H4.33333M9.33333 5.33334H4.33333M1 15.0383V6.21167C1 3.83334 1 2.64501 1.7325 1.90584C2.46417 1.16667 3.64333 1.16667 6 1.16667H9.33333C11.69 1.16667 12.8692 1.16667 13.6008 1.90584C14.3333 2.64417 14.3333 3.83334 14.3333 6.21167V15.0383C14.3333 16.2975 14.3333 16.9275 13.9483 17.1758C13.3192 17.5808 12.3467 16.7308 11.8575 16.4225C11.4533 16.1675 11.2517 16.0408 11.0267 16.0333C10.785 16.025 10.5792 16.1475 10.1425 16.4225L8.55 17.4267C8.12 17.6975 7.90583 17.8333 7.66667 17.8333C7.4275 17.8333 7.21333 17.6975 6.78333 17.4267L5.19167 16.4225C4.78667 16.1675 4.585 16.0408 4.36083 16.0333C4.11833 16.025 3.9125 16.1475 3.47583 16.4225C2.98667 16.7308 2.01417 17.5808 1.38417 17.1758C1 16.9275 1 16.2983 1 15.0383Z"
      stroke={fill || "#999898"}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
