import { Frame } from "component/Frame";
import { useGetAllLoginHistoryQuery } from "../../redux/services/settingsApi";
import Skeleton from "react-loading-skeleton";
import { useSidebar } from "component/Sidebar/useSidebar";

const LoginHistory = () => {
  const { data: loginHistoryData, isLoading } = useGetAllLoginHistoryQuery();

  const loginHistory = loginHistoryData ?? [];

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <Frame rmPadding>
      <div>
        <p
          className={`border-b border-[#999898] p-5 ${toggleTextTheme} font-medium text-2xl`}
        >
          Login history
        </p>
      </div>
      <div className="overflow-auto h-[700px]">
        <div className="flex flex-col gap-3 mt-3 ">
          {isLoading
            ? Array.from({ length: 5 }).map((_, idx) => (
                <div
                  key={idx}
                  className="flex flex-col border-b border-gray-300 p-2 "
                >
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={300}
                    height={20}
                  />
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={300}
                    height={10}
                  />
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={200}
                    height={5}
                  />
                  <Skeleton
                    baseColor="#E0E0E0"
                    highlightColor="#F5F5F5"
                    width={200}
                    height={5}
                  />
                </div>
              ))
            : loginHistory.map((chi, idx) => {
                const { user, login_time, email, user_agent } = chi;
                return (
                  <div
                    key={idx}
                    className="flex flex-col px-5 pb-2 overflow-hidden border-b border-gray-300"
                  >
                    <p className={`${toggleTextTheme} font-medium text-base`}>
                      {user || "---- ----"}
                    </p>
                    <p className={`${toggleTextTheme} font-medium text-[11px]`}>
                      {login_time || "---- ----"}
                    </p>
                    <p className="text-[#999898] font-medium text-xs">
                      {email || "---- ----"}
                    </p>
                    <p className="text-[#999898] font-medium text-[11px]">
                      {user_agent || "---- ----"}
                    </p>
                  </div>
                );
              })}
        </div>
      </div>
    </Frame>
  );
};

export default LoginHistory;
