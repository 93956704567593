import { Logo } from "assets/images/svgs";
import { useLoginForm } from "./useLoginForm";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { useNavigate } from "react-router";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";
import { maskEmail } from "helpers/helper";
import { useVerifyOtp } from "./useVerifyOtp";

const VerifyOtpForm = () => {
  const { formik, isLoading, email, handleResendOtp } = useVerifyOtp();
  const navigate = useNavigate();
  const handleOtpChange = (otp: string) => {
    const numericOtp = otp.replace(/\D/g, "");
    formik.setFieldValue("otp", numericOtp);
  };

  return (
    <div className="border-0 rounded-[50px] pt-6 pb-28 bg-[#FAF9F6] shadow-custom-box-shadow w-[498px] m-auto px-10">
      <Logo className="m-auto" />
      <p className="text-center text-black font-semibold text-2xl py-7 mt-7">
        Verify Email
      </p>
      <p className="text-center">
        We have sent you a{" "}
        <span className="text-[#6D6A6A] font-bold">
          Confirmation code (OTP)
        </span>{" "}
        <br />
        on this email {`${maskEmail(String(email || "-----"))}`}
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="mt-10 flex flex-col gap-4"
      >
        <div className="flex flex-col gap-5 items-center justify-center">
          <p className="text-[#6D6A6A] text-base">Enter Code</p>
          <MuiOtpInput
            length={6}
            value={formik.values.otp}
            onChange={handleOtpChange}
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
                "& input": {
                  padding: "0",
                  backgroundColor: "white",
                  height: "40px",
                },
              },
            }}
            width={400}
          />
        </div>

        <p className="text-center mt-3">
          Didn't receive the OTP?{" "}
          <span
            className="font-bold text-black cursor-pointer"
            onClick={handleResendOtp}
          >
            Send again
          </span>
        </p>

        <div className="flex flex-col gap-5">
          <Button
            btnType={ButtonTypeEnum.Submit}
            label="Send"
            className="mt-14"
            loading={isLoading}
            disabled={!formik.isValid || !formik.dirty}
          />
          <div
            className="p-[15px] border-[0.5px] rounded-[10px] border-[#999898] cursor-pointer"
            onClick={() => navigate("/login")}
          >
            <p className="text-[#999898] font-medium text-base text-center">
              Back to Login
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyOtpForm;
