import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { useAppSelector } from "../../redux/store";
import { selectUser } from "../../redux/features/authSlice";
import { SingleUser } from "../../../@types/auth";
import { formatDateToYYYYMMDD, updateSVGDimensions } from "helpers/helper";
import { SalesArray } from "../../../@types/sales";
import { useAddCredit } from "./useAddCredit";
import { useSidebar } from "component/Sidebar/useSidebar";

interface AddSalesProps {
  closeModal: () => void;
  isEdit?: boolean;
  data: SalesArray;
  isDebitTab?: boolean;
}

const AddCredits: React.FC<AddSalesProps> = ({
  closeModal,
  isEdit,
  data,
  isDebitTab,
}) => {
  const {
    formik,
    isLoading,
    isLoadingUpdate,
    isLoadingDebit,
    isUpdatingDebit,
  } = useAddCredit({
    closeModal,
    isUpdate: isEdit,
    ID: data?.id,
    data,
    isActiveDebitTab: isDebitTab,
  });

  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3";

  const getTitle = (isEdit?: boolean, isDebitTab?: boolean): string => {
    if (isEdit && !isDebitTab) {
      return "Update Credit";
    } else if (isDebitTab && !isEdit) {
      return "Add Debit";
    } else if (isDebitTab && isEdit) {
      return "Update Debit";
    } else {
      return "Add Credit";
    }
  };

  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-[#f0f0f0]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  return (
    <form onSubmit={formik.handleSubmit} className="p-6 px-10">
      <div>
        <p className={`${toggleTextTheme} transition-all font-medium text-xl`}>
          {getTitle(isEdit, isDebitTab)}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        {/* <div className="flex flex-col gap-1 -ml-3 ">
          <label className={`${labelClass} ml-3`}>Products</label>
          <SelectField
            value={formik.values.product as OptionType}
            selectOptions={formatProducts()}
            onChange={(e: any) => {
              console.log(e, "event");
              formik.setFieldValue("product", {
                label: e?.label,
                value: e?.value,
              });

              formik.setFieldValue("description", e?.product_description);
              formik.setFieldValue("quantity", e?.quantity);
              formik.setFieldValue("size", e?.size);
              const updatedSVG = updateSVGDimensions({
                svgString: e?.qr_image,
                newWidth: 100,
                newHeight: 100,
              });
              setIsBarCodeDisplay(updatedSVG);
            }}
            placeholder="Select Product.."
            className={`${inputClass} !w-full`}
            padding="6px"
            bgColor="white"
            isLoading={isLoadingProducts}
          />
        </div> */}

        <InputField
          type="text"
          label={isDebitTab ? "Product Bought" : "Product Sold"}
          name={isDebitTab ? "product_bought" : "product_sold"}
          value={
            isDebitTab
              ? formik.values.product_bought
              : formik?.values.product_sold
          }
          onChange={formik.handleChange}
          placeholder="Enter product name"
          // disabled
          className={inputClass}
          labelClassName={labelClass}
        />

        <InputField
          type="text"
          label={isDebitTab ? "Vendor name" : "Customer name"}
          name={isDebitTab ? "vendor_name" : "customer_name"}
          onChange={formik.handleChange}
          value={
            isDebitTab
              ? formik.values?.vendor_name
              : formik.values.customer_name
          }
          placeholder={isDebitTab ? "Enter vendor name" : "Enter customer name"}
          className={inputClass}
          labelClassName={labelClass}
        />

        <InputField
          type="number"
          label="Quantity"
          name="quantity"
          onChange={formik.handleChange}
          value={formik.values.quantity}
          placeholder="Enter quantity"
          // disabled
          className={`${inputClass}`}
          // className={`${inputClass} cursor-not-allowed !bg-[#f0f0f0] !text-[#ccc]`}
          labelClassName={labelClass}
        />

        <div className="flex flex-col">
          <label className={`${labelClass} ${toggleTextTheme} mb-1`}>
            Date
          </label>
          <Flatpickr
            value={formik.values.date}
            onChange={(selectedDates: Date[]) => {
              const [date] = selectedDates;
              formik.setFieldValue("date", formatDateToYYYYMMDD(date));
            }}
            placeholder="Enter  date"
            className={`${inputClass} ${toggleTextTheme}  ${toggledDateTheme} rounded-lg border  outline-none h-[50px] `}
          />
        </div>

        <InputField
          type="number"
          label="Cost price"
          name="cost_price"
          onChange={formik.handleChange}
          value={formik.values.cost_price}
          placeholder="Enter price"
          className={inputClass}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Unit price"
          name="unit_price"
          onChange={formik.handleChange}
          value={formik.values.unit_price}
          placeholder="Enter price"
          className={inputClass}
          labelClassName={labelClass}
        />
      </div>
      <div className="flex justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Submit}
          label={isEdit ? "Update" : "Save"}
          className="w-[284px]"
          disabled={!formik.isValid || !formik.dirty}
          loading={
            isLoading || isLoadingUpdate || isLoadingDebit || isUpdatingDebit
          }
        />
      </div>
    </form>
  );
};

export default AddCredits;
