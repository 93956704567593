import { ExcelIcon, PdfIcon, PrintIcon } from "assets/images/tsx";
import { SearchField } from "component/Form";
import { Frame } from "component/Frame";
import { TitleText } from "component/Typography";
import { rentTableHead, salesTableBody, salesTableHead } from "./utils";
import { Table, TableBodyData } from "component/Table";
import useDisclosure from "hooks/useDisclosure";
import { useRef, useState } from "react";
import { SalesArray } from "../../../@types/sales";
import {
  useDownloadSalesMutation,
  useExportSalesMutation,
} from "../../redux/services/salesApi";
import { toast } from "sonner";
import { useReactToPrint } from "react-to-print";
import DashboardModal from "component/Modal/Modal";
import DeleteSales from "./deleteSales";
import { PageLoader } from "component/Loader/Loader";
import {
  formatNumberWithCommas,
  formatDateToYYYYMMDD,
  normalizeToUTC,
} from "helpers/helper";
import { RentArray } from "../../../@types/rent";
import {
  useDownloadRentMutation,
  useExportRentMutation,
} from "../../redux/services/rentApi";
import { useSidebar } from "component/Sidebar/useSidebar";
import { newSalesTableHead } from "pages/Invoice/utils";
import { Invoices } from "../../../@types/invoice";
import {
  useDownloadInvoiceMutation,
  useExportInvoiceMutation,
} from "../../redux/services/invoiceApi";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

const SalesTable = ({
  salesTableBody,
  loading,
  onOpenAddModal,
  isFetching,
  onEditChild,
  setIsEdit,
  search,
  handleSearchTable,
  isRentTab,
  invoiceTableBody,
  loaderBottom,
  dntShowAdd,
  setStartDate,
  setEndDate,
  dateRange,
  isNotDeleteable,
  isNotEditable,
  isReport,
  theadLength,
}: {
  salesTableBody: RentArray[];
  loading?: boolean;
  isFetching?: boolean;
  onEditChild: (param: RentArray) => void;
  onOpenAddModal: () => void;
  setIsEdit: (param: boolean) => void;
  search: string;
  handleSearchTable: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRentTab?: boolean;
  invoiceTableBody: Invoices[];
  loaderBottom?: boolean;
  dntShowAdd?: boolean;
  setStartDate?: (param: string) => void;
  setEndDate?: (param: string) => void;
  dateRange?: boolean;
  isNotDeleteable?: boolean;
  isNotEditable?: boolean;
  isReport?: boolean;
  theadLength?: number;
  // onTableClick: () => void;
}) => {
  const {
    isOpen: openDeleteModal,
    open: onOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();

  const [childData, setChildData] = useState<RentArray>();

  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (icon: string) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const togglePDFIconTheme = isDarkTheme ? "stroke-white" : "";
  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const toggleExcelIconTheme = isDarkTheme ? "fill-white" : "";
  const hoverBgTheme = isDarkTheme
    ? "bg-[#222121] shadow-white-sm"
    : "bg-white shadow-xl";
  const hoverTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const hoverClass = `text-xs rounded-lg text-center ${hoverTextTheme}`;

  const [downloadInvoice] = useDownloadInvoiceMutation();
  const [exportInvoice] = useExportInvoiceMutation();

  const [downloadRent] = useDownloadRentMutation();
  const [exportRent] = useExportRentMutation();

  const handleExport = async (param: string) => {
    toast.loading("Downloading...");
    try {
      let blob: Blob;
      let fileName: string;

      switch (true) {
        case isRentTab && param === "excel":
          blob = await downloadRent().unwrap();
          fileName = "rent.xlsx";
          break;
        case isRentTab && param === "pdf":
          blob = await exportRent().unwrap();
          fileName = "rent.pdf";
          break;
        case !isRentTab && param === "excel":
          blob = await downloadInvoice().unwrap();
          fileName = "sales.xlsx";
          break;
        case !isRentTab && param === "pdf":
          blob = await exportInvoice().unwrap();
          fileName = "sales.pdf";
          break;
        default:
          toast.dismiss();
          toast.error("Invalid export format");
          return;
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.dismiss();
      toast.success(`Exported successfully as ${fileName}`);
    } catch (error) {
      toast.dismiss();
      toast.error(`Export to ${param.toUpperCase()} failed`);
      console.error("Download failed", error);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Sales Data",
  });
  const inputClass = "pl-3 !h-[45px]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";

  const topData = (
    <div className="flex justify-between items-center p-4">
      <div className="flex gap-8 items-center">
        {/* Excel Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("excel")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("excel")}
        >
          <ExcelIcon
            className={`cursor-pointer transition-all ${toggleExcelIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 shadow-xl ${
              hoveredIcon === "excel" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to Excel</p>
          </div>
        </div>

        {/* Print Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("print")}
          onMouseLeave={handleMouseLeave}
          onClick={handlePrint}
        >
          {/* <PrintIcon className="cursor-pointer" /> */}
          <PrintIcon
            className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
          />

          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "print" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Print</p>
          </div>
        </div>

        {/* PDF Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("pdf")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("pdf")}
        >
          {/* <PdfIcon className="cursor-pointer" /> */}
          <PdfIcon
            className={`cursor-pointer transition-all ${togglePDFIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] mt-3 transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "pdf" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to PDF</p>
          </div>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-xs font-medium">Search</label>
          <SearchField
            name="search"
            onChange={handleSearchTable}
            value={search}
          />
        </div>

        {/* <SelectField
          showFilterIcon
          borderColor="#999898"
          padding="0 0 0 2.5rem"
          bgColor="#fff"
          placeholder="Sort by"
          value={sortVal}
          selectOptions={sortOptions}
          onChange={onSortChange}
        /> */}
        {dateRange && (
          <div className="flex gap-5 items-center">
            <div className="flex flex-col gap-2">
              <label className="text-xs font-medium">From Date</label>
              <Flatpickr
                // value={}
                onChange={(selectedDates: Date[]) => {
                  const [date] = selectedDates;

                  setStartDate &&
                    setStartDate(formatDateToYYYYMMDD(normalizeToUTC(date)));
                }}
                placeholder="Start date.."
                className={`${inputClass} ${toggleTextTheme}  rounded-lg border outline-none h-[50px] ${toggledDateTheme} `}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-xs font-medium">To Date</label>
              <Flatpickr
                value={""}
                onChange={(selectedDates: Date[]) => {
                  const [date] = selectedDates;
                  // formik.setFieldValue("date", formatDateToYYYYMMDD(date));
                  setEndDate &&
                    setEndDate(formatDateToYYYYMMDD(normalizeToUTC(date)));
                }}
                placeholder="End date.."
                className={`${inputClass} ${toggleTextTheme}  rounded-lg border outline-none h-[50px] ${toggledDateTheme} `}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const salesBody = (
    <div className="overflow-auto h-full">
      {/* <div className="overflow-auto h-[700px]"> */}
      {loading ? (
        <div
          className={
            "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          }
        >
          <div
            className={`flex flex-col ${
              loaderBottom && "mt-[20rem]"
            } justify-center items-center`}
          >
            <PageLoader />
          </div>
        </div>
      ) : salesTableBody?.length > 0 ? (
        salesTableBody?.map((chi, idx) => {
          const {
            customer_name,
            product,
            description,
            quantity,
            size,
            date,
            price,
            return_date,
            prize,
            // code_number,
            entry_officer,
            outlet,
          } = chi;
          return (
            <TableBodyData
              loading={isFetching}
              key={idx}
              one={customer_name || "--- ---"}
              two={product?.product_name || "--- ---"}
              three={description || "--- ---"}
              four={quantity || "--- ---"}
              five={size || product?.size || "--- ---"}
              six={date || product?.date || "--- ---"}
              returnText={isRentTab ? return_date : false}
              seven={`${"₦"}${formatNumberWithCommas((price || prize) ?? 0)}`}
              // eight={code_number}
              nine={entry_officer || "--- ---"}
              ten={outlet?.name || "--- ---"}
              tenClass={(isReport && "text-right") as string}
              dataLength={11}
              onEdit={() => {
                onEditChild(chi);
                setIsEdit(true);
                onOpenAddModal();
              }}
              onDelete={() => {
                setChildData(chi);
                onOpenDeleteModal();
              }}
              isNotDeletable={isNotDeleteable}
              isNotEditable={isNotEditable}
            />
          );
        })
      ) : (
        <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          No rent listed here...{" "}
          {!dntShowAdd && (
            <span
              className="text-[#4A07A0] text-sm cursor-pointer"
              onClick={onOpenAddModal}
            >
              {isRentTab ? "Add rent" : "Add new sales"}
            </span>
          )}
        </p>
      )}
    </div>
  );

  const invoiceBody = (
    <div className="overflow-auto h-full">
      {loading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            className={`flex flex-col ${
              loaderBottom && "mt-[20rem]"
            } justify-center items-center`}
          >
            <PageLoader />
          </div>
        </div>
      ) : invoiceTableBody?.length > 0 ? (
        invoiceTableBody?.map((chi, idx) => {
          const {
            invoice_to,
            delivered_to,
            payment_method,
            invoice_no,
            invoice_date,
            status,
            amount,
            discount,
            paid,
            balance,
          } = chi;
          return (
            <TableBodyData
              loading={isFetching}
              dataLength={10}
              key={idx}
              one={invoice_to}
              two={delivered_to}
              five={payment_method}
              eight={invoice_no}
              nine={invoice_date}
              ten={status}
              eleven={`${"₦"}${formatNumberWithCommas(amount ?? 0)}`}
              twelve={`${"₦"}${formatNumberWithCommas(discount ?? 0)}`}
              thirteen={`${"₦"}${formatNumberWithCommas(paid ?? 0)}`}
              fourteen={`${"₦"}${formatNumberWithCommas(balance ?? 0)}`}
              // onEdit={() => {
              //   onEditChild(chi);
              //   setIsEdit(true);
              //   onOpenAddModal();
              // }}
              // onDelete={() => {
              //   setChildData(chi);
              //   onOpenDeleteModal();
              // }}
              isNotEditable
              isNotDeletable
            />
          );
        })
      ) : (
        <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          No sales listed here...{" "}
          {!dntShowAdd && (
            <span
              className="text-[#4A07A0] text-sm cursor-pointer"
              onClick={onOpenAddModal}
            >
              Generate an Invoice
            </span>
          )}
        </p>
      )}
    </div>
  );

  return (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-0 overflow-auto h-[650px] pb-10"
    >
      <div className="p-4 px-6 border-b border-[#999898] ">
        <TitleText value={isRentTab ? "Rented items" : "All Sales"} />
      </div>
      {topData}
      <div ref={componentRef}>
        <Table
          // tableHead={isRentTab ? rentTableHead : newSalesTableHead}
          tableHead={
            isReport && isRentTab
              ? rentTableHead?.filter((chi) => chi !== "Action")
              : isRentTab
              ? rentTableHead
              : newSalesTableHead
          }
          // tableBody={salesBody}
          tableBody={isRentTab ? salesBody : invoiceBody}
          dataLength={isRentTab ? 11 : 10}
        />
      </div>
      {/* modal wrap start */}
      {/* <DashboardModal
        open={openDeleteModal}
        onClose={closeDeleteModal}
        // width={"749px"}
      >
        <DeleteSales
          data={childData as SalesArray}
          onCancel={closeDeleteModal}
          isRentTab={isRentTab}
        />
      </DashboardModal> */}
      {/* modal wrap end */}
    </Frame>
  );
};

export default SalesTable;
