import {
  EyeCloseIcon,
  LockIcon,
  Logo,
  MsgIcon,
  TimesIcon,
} from "assets/images/svgs";
import { useLoginForm } from "./useLoginForm";
import { InputField } from "component/Form";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { useResetPassword } from "./useResetPassword";
import DefaultCheckIcon from "assets/images/tsx/DefaultCheckIcon";
import { useGetSingleUserQuery } from "../../redux/services/authApi";
import { maskEmail } from "helpers/helper";
import Skeleton from "react-loading-skeleton";

const ResetPasswordForm = () => {
  const {
    formik,
    isLoading,
    passwordValidations,
    handlePasswordChange,
    email,
    isLoadingUser,
  } = useResetPassword();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="border-0 rounded-[50px] pt-6 pb-28 bg-[#FAF9F6] shadow-custom-box-shadow w-[498px] m-auto px-10">
      <Logo className="m-auto" />
      <p className="text-black text-2xl">Welcome Back</p>
      <p className="text-sm mt-1">Enter your login details</p>
      <p className="text-xs">
        {isLoadingUser ? (
          <Skeleton
            baseColor="#E0E0E0"
            highlightColor="#F5F5F5"
            width={200}
            height={10}
          />
        ) : (
          `${maskEmail(String(email || "-----"))}`
        )}
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="mt-10 flex flex-col gap-4"
      >
        <InputField
          type={showPassword ? "text" : "password"}
          label="Enter new password"
          name="password"
          onChange={(e) => {
            formik.handleChange(e);
            handlePasswordChange(e.target.value);
          }}
          value={formik.values.password}
          leftIcon={LockIcon}
          rightIcon={EyeCloseIcon}
          placeholder="Enter new password"
          onRightIconClick={() => setShowPassword(!showPassword)}
        />
        <div className="relative">
          <InputField
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            name="password_confirmation"
            onChange={formik.handleChange}
            leftIcon={LockIcon}
            rightIcon={EyeCloseIcon}
            placeholder="Confirm password"
            onRightIconClick={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
          />
          {formik.values.password ? (
            <p className="ont-light text-xs text-red-900  absolute mt-2">
              {formik.errors.password_confirmation}
            </p>
          ) : null}
        </div>
        <div className="flex flex-col gap-3 mt-5">
          {passwordValidations.map((chi, idx) => {
            const { label, checked } = chi;
            return (
              <div className="flex gap-2 items-center" key={idx}>
                <DefaultCheckIcon fill={checked ? "#4a934a" : ""} />
                <p
                  className={`font-light text-xs transition duration-300 text-custom-gray-scale-300 ${
                    checked ? "text-[#4a934a]" : ""
                  }`}
                >
                  {label}
                </p>
              </div>
            );
          })}
        </div>

        <Button
          btnType={ButtonTypeEnum.Submit}
          label="Continue"
          className="mt-14"
          loading={isLoading}
          disabled={!formik.isValid || !formik.dirty || isLoadingUser}
        />
      </form>
    </div>
  );
};

export default ResetPasswordForm;
