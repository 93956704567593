import { useSidebar } from "component/Sidebar/useSidebar";
import { TextType } from "./types";

export const SmText = {
  smText: "text-[11px]",
};

export const TitleText = ({ value }: TextType) => {
  const { isDarkTheme } = useSidebar();
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";
  return <p className={`${toggleTextTheme} font-semibold`}>{value}</p>;
};

export const Text = ({ value }: TextType) => {
  return <p className="text-[#6D6A6A]">{value}</p>;
};

export const col = "flex justify-between items-center";
