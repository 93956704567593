import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { selectTheme, setDarkTheme } from "../../redux/features/themeSlice";
import { useAppSelector } from "../../redux/store";
import { useEffect } from "react";

export const useSidebar = () => {
  const navigate = useNavigate();
  const handleRoute = (path: string) => {
    navigate(path);
  };
  const defaultTheme = useAppSelector(selectTheme);
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    const newTheme = defaultTheme;
    dispatch(setDarkTheme({ isDarkTheme: !newTheme }));
    localStorage.setItem("defaultTheme", JSON.stringify(!newTheme));
  };
  
  const isDarkTheme = JSON.parse(
    localStorage.getItem("defaultTheme") || "false"
  );
  
  
  const locationEquatesPath = (path: string) => {
    const location = window.location.pathname;
    let isPath = false;
    isPath = location === path;
    return isPath;
  };

  return {
    isDarkTheme,
    handleRoute,
    locationEquatesPath,
    handleToggleTheme,
  };
};
