import DashboardStockHistory from "./DasboardStockHistory";
import DashboardTable from "./DashboardTable";
import DashboardTabs from "./DashboardTabs";
import { Container } from "component/Container";
import { ColPropsType } from "./types";
import DashboardLineChart from "./DashboardBarChart";
import DashboardDoughnutChart from "./DashboardDoughnutChart";

const Dashboard = () => {
  const Col = ({ children }: ColPropsType) => {
    return <div className="flex gap-7">{children}</div>;
  };

  return (
    <Container>
      <DashboardTabs />
      <Col>
        <DashboardLineChart />
        {/* <DashboardDoughnutChart /> */}
      </Col>
      <Col>
        <DashboardTable />
        <DashboardStockHistory />
      </Col>
    </Container>
  );
};

export default Dashboard;
