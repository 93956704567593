import { Container } from "component/Container";
import { BigPlusIcon } from "assets/images/svgs";
import PurchaseTable from "./PurchaseTable";
import DashboardModal from "component/Modal/Modal";
import useDisclosure from "hooks/useDisclosure";
import { useState } from "react";
import AddPurchase from "./AddPurchase";
import { useDebounce } from "helpers/helper";
import { useGetPurchaseQuery } from "../../redux/services/purchaseApi";
import { Pagination } from "@mui/material";
import DeletePurchase from "./deletePurchase";
import { useSidebar } from "component/Sidebar/useSidebar";

const Purchase = () => {
  const {
    isOpen: openAddPurchaseModal,
    open: onOpenPurchaseModal,
    close: closeAddPurchaseModal,
  } = useDisclosure();
  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const {
    isOpen: openDeleteModal,
    open: onOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();
  const { isDarkTheme } = useSidebar();

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // upate state
  const [filterVal, setFilterVal] = useState<any>(""); //update type

  const {
    data: purchaseData,
    isLoading: isLoadingPurchase,
    isFetching,
  } = useGetPurchaseQuery({
    search: debouncedSearchVal,
    page: page,
    filter: filterVal.value,
  });

  const lastPage = purchaseData?.meta?.last_page ?? 0;
  const purchases = purchaseData?.data;

  const plusDisplay = (
    <div
      className="absolute right-12 mr-10 bottom-12 mb-14 cursor-pointer"
      onClick={() => onOpenPurchaseModal()}
    >
      <BigPlusIcon />
    </div>
  );

  return (
    <Container className="h-[650px]">
      {/* {purchaseTabData} */}
      <PurchaseTable
        setIsEdit={setEdit}
        purchaseTableBody={purchases}
        loading={isLoadingPurchase}
        isFetching={isFetching}
        onOpenAddModal={onOpenPurchaseModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
        onSortChange={(e) => setFilterVal(e)}
        sortVal={filterVal}
      />
      {plusDisplay}
      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>

      {/* modal wrap start */}
      <DashboardModal
        open={openAddPurchaseModal}
        // onClose={closeAddPurchaseModal}
        onClose={() => {
          closeAddPurchaseModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddPurchase
          closeModal={() => {
            closeAddPurchaseModal();
            setEdit(false);
          }}
          isEdit={edit}
          data={childData as any}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Container>
  );
};

export default Purchase;
