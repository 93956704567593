import { SearchField } from "component/Form";
import { Frame } from "component/Frame";
import { TitleText } from "component/Typography";
import { productTableHead, sortOptions } from "./utils";
import { Table, TableBodyData } from "component/Table";
import {
  ProductsArray,
  ProductsResponse,
  ProductsType,
} from "../../../@types/product";
import {
  formatNumberWithCommas,
  // modifySvgContent,
  updateSVGDimensions,
} from "helpers/helper";
import { useRef, useState } from "react";
import useDisclosure from "hooks/useDisclosure";
import DashboardModal from "component/Modal/Modal";
import ProductPreview from "./productPreview";
import { PageLoader } from "component/Loader/Loader";
import DeleteProduct from "./deleteProduct";
import {
  useDownloadProductMutation,
  useExportProductMutation,
} from "../../redux/services/productApi";
import { toast } from "sonner";
import { useReactToPrint } from "react-to-print";
import { useSidebar } from "component/Sidebar/useSidebar";
import { ExcelIcon, PdfIcon, PrintIcon } from "assets/images/tsx";

const ProductsTable = ({
  productTableBody,
  loading,
  onOpenAddModal,
  isFetching,
  onEditChild,
  setIsEdit,
  search,
  handleSearchTable,
}: {
  productTableBody: ProductsArray[];
  loading?: boolean;
  isFetching?: boolean;
  onEditChild: (param: ProductsType) => void;
  onOpenAddModal: () => void;
  setIsEdit: (param: boolean) => void;
  search: string;
  handleSearchTable: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const {
    isOpen: openPreviewModal,
    open: onOpenPreviewModal,
    close: closePreviewModal,
  } = useDisclosure();

  const {
    isOpen: openDeleteModal,
    open: onOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();

  const [childData, setChildData] = useState<ProductsType>();

  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const handleMouseEnter = (icon: string) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const { isDarkTheme } = useSidebar();

  const [downloadProduct] = useDownloadProductMutation();
  const [exportProduct] = useExportProductMutation();
  const handleExport = async (param: string) => {
    toast.loading("Downloading...");

    try {
      let blob: Blob;
      let fileName: string;

      if (param === "excel") {
        blob = await downloadProduct({ format: "xlx" }).unwrap();
        fileName = "products.xlsx";
      } else if (param === "pdf") {
        blob = await exportProduct().unwrap();
        fileName = "products.pdf";
      } else {
        toast.dismiss();
        toast.error("Invalid export format");
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.dismiss();
      toast.success(`Exported successfully as ${fileName}`);
    } catch (error) {
      toast.dismiss();
      toast.error(`Export to ${param.toUpperCase()} failed`);
      console.error("Download failed", error);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Products Data",
  });

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const togglePDFIconTheme = isDarkTheme ? "stroke-white" : "";
  const togglePrintIconTheme = isDarkTheme ? " stroke-white" : "";
  const toggleExcelIconTheme = isDarkTheme ? "fill-white" : "";
  const hoverBgTheme = isDarkTheme
    ? "bg-[#222121] shadow-white-sm"
    : "bg-white shadow-xl";
  const hoverTextTheme = isDarkTheme ? "text-white" : "text-black";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const hoverClass = `text-xs rounded-lg text-center ${hoverTextTheme}`;

  const topData = (
    <div className="flex justify-between items-center p-4">
      <div className="flex gap-8 items-center">
        {/* Excel Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("excel")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("excel")}
        >
          <ExcelIcon
            className={`cursor-pointer transition-all ${toggleExcelIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 shadow-xl ${
              hoveredIcon === "excel" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to Excel</p>
          </div>
        </div>

        {/* Print Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("print")}
          onMouseLeave={handleMouseLeave}
          onClick={handlePrint}
        >
          {/* <PrintIcon className="cursor-pointer" /> */}
          <PrintIcon
            className={`cursor-pointer transition-all ${togglePrintIconTheme} transition-all`}
          />

          <div
            className={`${hoverBgTheme} absolute w-[150px] transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "print" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Print</p>
          </div>
        </div>

        {/* PDF Icon */}
        <div
          className="flex flex-col gap-1 relative"
          onMouseEnter={() => handleMouseEnter("pdf")}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleExport("pdf")}
        >
          {/* <PdfIcon className="cursor-pointer" /> */}
          <PdfIcon
            className={`cursor-pointer transition-all ${togglePDFIconTheme} transition-all`}
          />
          <div
            className={`${hoverBgTheme} absolute w-[150px] mt-3 transition-all duration-300 p-2 h-[30px] bottom-0 top-9 left-1 -ml-4 ${
              hoveredIcon === "pdf" ? "block" : "hidden"
            }`}
          >
            <p className={hoverClass}>Export to PDF</p>
          </div>
        </div>
      </div>
      <div className="flex gap-5">
        <SearchField
          name="search"
          onChange={handleSearchTable}
          value={search}
        />
        {/* <SelectField
          showFilterIcon
          borderColor="#999898"
          padding="0 0 0 2.5rem"
          bgColor="#fff"
          placeholder="Sort by"
          value={sortVal}
          selectOptions={sortOptions}
          onChange={onSortChange}
        /> */}
      </div>
    </div>
  );

  const productBody = (
    <div className="overflow-auto h-full">
      {/* <div className="overflow-auto h-[700px]"> */}
      {loading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex flex-col justify-center items-center">
            <PageLoader />
          </div>
        </div>
      ) : productTableBody?.length > 0 ? (
        productTableBody?.map((chi, idx) => {
          const {
            product_image,
            product_name,
            product_description,
            quantity,
            size,
            date,
            cost_price,
            selling_price,
            qr_image,
            entry_officer,
            currency,
            year_of_manufacturing,
          } = chi;
          const updatedSVG = updateSVGDimensions({
            svgString: qr_image,
            newWidth: 50,
            newHeight: 50,
          });

          return (
            <TableBodyData
              loading={isFetching}
              trClass="cursor-pointer"
              onTableClick={() => {
                onOpenPreviewModal();
                setChildData(chi);
              }}
              dataLength={12}
              key={idx}
              image
              imgSrc={product_image?.[0]?.path}
              one={product_name || "--- ---"}
              two={product_description || "--- ---"}
              twoClass="w-[100px] truncate"
              three={quantity || "--- ---"}
              four={size || "--- ---"}
              five={date || "--- ---"}
              six={`${currency}${formatNumberWithCommas(cost_price ?? 0)}`}
              seven={`${currency}${formatNumberWithCommas(selling_price ?? 0)}`}
              eight={entry_officer || "--- ---"}
              nine={year_of_manufacturing || "--- ---"}
              nineClass="text-center"
              qrImage={
                <figure dangerouslySetInnerHTML={{ __html: updatedSVG }} />
              }
              onEdit={() => {
                onEditChild(chi);
                setIsEdit(true);
                onOpenAddModal();
              }}
              onDelete={() => {
                setChildData(chi);
                onOpenDeleteModal();
              }}
            />
          );
        })
      ) : (
           <p className={`font-medium transition-all ${toggleTextTheme} p-4`}>
          No product listed here...{" "}
          <span
            className="text-[#4A07A0] text-sm cursor-pointer"
            onClick={onOpenAddModal}
          >
            Add a new product
          </span>
        </p>
      )}
    </div>
  );

  return (
    <Frame
      rmPadding
      // bg="bg-[#FAF9F6]"
      bg={toggledBgTheme}
      className="p-0 overflow-auto h-[650px] pb-10"
    >
      <div className="p-4 px-6 border-b border-[#999898] ">
        <TitleText value="All Products" />
      </div>
      {topData}
      <div ref={componentRef}>
        <Table
          tableHead={productTableHead}
          dataLength={productTableHead?.length}
          tableBody={productBody}
        />
      </div>

      {/* modal wrap start */}
      <DashboardModal
        open={openPreviewModal}
        onClose={closePreviewModal}
        width={"749px"}
        height={"80%"}
      >
        <ProductPreview data={childData as ProductsType} />
      </DashboardModal>
      {/* modal wrap end */}

      {/* modal wrap start */}
      <DashboardModal
        open={openDeleteModal}
        onClose={closeDeleteModal}
        // width={"749px"}
      >
        <DeleteProduct
          data={childData as ProductsType}
          onCancel={closeDeleteModal}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Frame>
  );
};

export default ProductsTable;
