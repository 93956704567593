import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartType } from "./types";
import { useChart } from "./useChart";
import { useSidebar } from "component/Sidebar/useSidebar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function BarChart({ data }: ChartType) {
  const { isDarkTheme } = useSidebar();

  const { formatTickValue } = useChart();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          color: "#ffff",
        },
        ticks: {
          display: true,
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
            color: "black",
          },
        },
      },
      y: {
        border: {
          color: "#ffff",
        },
        ticks: {
          color: isDarkTheme ? "white" : "black",
          weight: 400,
          callback: function (value: number) {
            return formatTickValue(value);
          },
          maxTicksLimit: 6,
          padding: 15,
          font: {
            family: "Inter",
            size: 12,

            color: isDarkTheme ? "white" : "black",
          },
        },
      },
    },
  };

  return (
    <div className=" text-[black]">
      <Bar height={70} options={options as any} data={data} />
    </div>
  );
}
