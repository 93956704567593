import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword: React.FC = () => {
  return (
    <div className="relative h-screen login-bg-img flex">
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
