import { Container } from "component/Container";
import { Frame } from "component/Frame";
import { BigPlusIcon } from "assets/images/svgs";
import useDisclosure from "hooks/useDisclosure";
import { useState } from "react";
import { useDebounce } from "helpers/helper";
import { Pagination } from "@mui/material";
import DashboardModal from "component/Modal/Modal";
import { useCredit } from "./useCredit";
import { CreditTabEnum, creditTabs } from "./utils";
import { useGetCreditsQuery } from "../../redux/services/creditApi";
import { useGetDebitsQuery } from "../../redux/services/debitApi";
import CreditTable from "./CreditTable";
import AddCredits from "./AddCredit";
import { useSidebar } from "component/Sidebar/useSidebar";

const Credits = () => {
  const {
    isOpen: openAddCreditModal,
    open: onOpenAddCreditModal,
    close: closeAddCreditModal,
  } = useDisclosure();
  const { handleCreditTabChange, isActiveTab, creditTab } = useCredit();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<any>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const {
    data: creditData,
    isLoading: isLoadingCredit,
    isFetching,
  } = useGetCreditsQuery({
    search: debouncedSearchVal,
    filter_by: creditTab.value,
    page: page,
  });

  const {
    data: debitData,
    isLoading: isLoadingDebit,
    isFetching: isFetchingDebit,
  } = useGetDebitsQuery(
    {
      search: debouncedSearchVal,
      filter_by: creditTab.value,
      page: page,
    },
    { skip: creditTab.value !== CreditTabEnum.DEBIT }
  );

  const credits = creditData?.data[0]?.data;
  const lastPage = creditData?.data[0]?.last_page ?? 0;

  const debits = debitData?.data[0]?.data;
  const debitLastPage = debitData?.data[0]?.last_page ?? 0;

  const isDebit = creditTab.value === CreditTabEnum.DEBIT;

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const creditTabData = (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-2.5 px-5 flex justify-between items-center border-0 rounded-[10px]"
    >
      {creditTabs.map((chi, idx) => {
        const { label, value } = chi;
        return (
          <div key={idx} className="w-[500px]">
            <p
              className={`${toggleTextTheme} text-center cursor-pointer ${isActiveTab(
                value
              )}`}
              onClick={() => handleCreditTabChange(chi)}
            >
              {label}
            </p>
          </div>
        );
      })}
    </Frame>
  );
  const plusDisplay = (
    <div
      className="absolute right-12 mr-10 bottom-12 mb-14 cursor-pointer"
      onClick={() => onOpenAddCreditModal()}
    >
      <BigPlusIcon />
    </div>
  );
  return (
    <Container className="h-[650px]">
      {creditTabData}
      <CreditTable
        isDebitTab={isDebit}
        setIsEdit={setEdit}
        creditTableBody={
          creditTab.value === CreditTabEnum.DEBIT ? debits : credits
        }
        loading={isDebit ? isLoadingDebit : isLoadingCredit}
        isFetching={isDebit ? isFetchingDebit : isFetching}
        onOpenAddModal={onOpenAddCreditModal}
        onEditChild={setChildData}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />
      {plusDisplay}

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={
            creditTab.value === CreditTabEnum.DEBIT ? debitLastPage : lastPage
          }
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddCreditModal}
        // onClose={closeAddCreditModal}
        onClose={() => {
          closeAddCreditModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddCredits
          closeModal={() => {
            closeAddCreditModal();
            setEdit(false);
          }}
          isDebitTab={isDebit}
          isEdit={edit}
          data={childData}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Container>
  );
};

export default Credits;
