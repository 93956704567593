import { QrCodeIcon, TyreImage } from "assets/images/svgs";

export enum ProductTabEnum {
  PRODUCT_NAME = "product_name",
  SIZE = "size",
  PRODUCT_CATEGORY = "product_category",
  PRODUCT_DESCRIPTION = "product_description",
  CREATED_AT = "created_at",
}

export const productTabs = [
  { label: "All category", value: "" },
  { label: "Name", value: ProductTabEnum.PRODUCT_NAME },
  { label: "Recently added", value: ProductTabEnum.CREATED_AT },
  { label: "Size", value: ProductTabEnum.SIZE },
  { label: "Description", value: ProductTabEnum.PRODUCT_DESCRIPTION },
];

export const sortOptions = [
  {
    label: "Product name",
    value: "product_name",
  },
  {
    label: "Description",
    value: "description",
  },
];

export const productTableHead = [
  "Images",
  "Product name",
  "Description",
  "Quantity",
  "Size",
  "Date",
  "Cost price",
  "Selling price",
  "QR Code",
  "Entry officer",
  "Year of mfg",
  "Action",
];

export const productTableBody = [
  {
    images: TyreImage,
    product_name: "lorem ipsum",
    description: "Bus tire",
    quantity: "1",
    size: "45",
    date: "4/6/2024",
    cost_price: "₦50,000",
    selling_price: "₦60,000",
    qr_code: QrCodeIcon,
    code_number: "#35647636",
    entry_officer: "Hassan Lamidi",
    year_of_mfg: "2021",
  },
];
