import { Container } from "component/Container";
import { SalesTabEnum, SalesTabs } from "./utils";
import { useSales } from "./useSales";
import { Frame } from "component/Frame";
import SalesTable from "./SalesTable";
import { BigPlusIcon } from "assets/images/svgs";
import useDisclosure from "hooks/useDisclosure";
import { useState } from "react";
import { SalesArray } from "../../../@types/sales";
import { useDebounce } from "helpers/helper";
import { useGetSalesQuery } from "../../redux/services/salesApi";
import { Pagination } from "@mui/material";
import DashboardModal from "component/Modal/Modal";
import AddSales from "./AddSales";
import { useGetRentsQuery } from "../../redux/services/rentApi";
import { RentArray } from "../../../@types/rent";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useGetInvoicesQuery } from "../../redux/services/invoiceApi";
import { Invoices } from "../../../@types/invoice";

const Sales = () => {
  const {
    isOpen: openAddSalesModal,
    open: onOpenAddSalesModal,
    close: closeAddSalesModal,
  } = useDisclosure();
  const { handleSalesTabChange, isActiveTab, salesTab } = useSales();

  const [edit, setEdit] = useState(false);
  const [childData, setChildData] = useState<SalesArray>();
  const [searchVal, setSearchVal] = useState("");
  const debouncedSearchVal = useDebounce(searchVal, 1000);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // const {
  //   data: salesData,
  //   isLoading: isLoadingProducts,
  //   isFetching,
  // } = useGetSalesQuery({
  //   search: debouncedSearchVal,
  //   filter_by: salesTab.value,
  //   page: page,
  // });

  const {
    data: invoicesData,
    isLoading: isLoadingInvoices,
    isFetching,
  } = useGetInvoicesQuery({
    search: debouncedSearchVal,
    // search_by: productTab.value,
    filter_by: salesTab.value,
    page: page,
  });

  const {
    data: rentData,
    isLoading: isLoadingRent,
    isFetching: isFetchingRent,
  } = useGetRentsQuery(
    {
      search: debouncedSearchVal,
      filter_by: salesTab.value,
      page: page,
    },
    { skip: salesTab.value !== SalesTabEnum.RENT }
  );

  // const sales = salesData?.data;
  // const lastPage = salesData?.meta?.last_page ?? 0;

  const sales = invoicesData?.invoice?.data ?? [];
  const lastPage = invoicesData?.invoice?.last_page;

  const rents = rentData?.data;
  const rentLastPage = (rentData as any)?.meta?.last_page ?? 0;

  // console.log(rentData?.meta?.last_page, "rent last page");

  const isRent = salesTab.value === SalesTabEnum.RENT;

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const salesTabData = (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-2.5 px-5 flex justify-between items-center border-0 rounded-[10px]"
    >
      {SalesTabs.map((chi, idx) => {
        const { label, value } = chi;
        return (
          <div key={idx} className="w-[500px]">
            <p
              className={`${toggleTextTheme}  text-center cursor-pointer ${isActiveTab(
                value
              )}`}
              onClick={() => handleSalesTabChange(chi)}
            >
              {label}
            </p>
          </div>
        );
      })}
    </Frame>
  );
  const plusDisplay = (
    <div
      className="absolute right-12 mr-10 bottom-12 mb-14 cursor-pointer"
      onClick={() => onOpenAddSalesModal()}
    >
      <BigPlusIcon />
    </div>
  );
  return (
    <Container className="h-[650px]">
      {salesTabData}
      <SalesTable
        isRentTab={isRent}
        setIsEdit={setEdit}
        // salesTableBody={
        //   salesTab.value === SalesTabEnum.RENT
        //     ? (rents as RentArray[])
        //     : (sales as Invoices[])
        // }
        loading={isRent ? isLoadingRent : isLoadingInvoices}
        // loading={isRent ? isLoadingRent : isLoadingProducts}
        isFetching={isRent ? isFetchingRent : isFetching}
        onOpenAddModal={onOpenAddSalesModal}
        onEditChild={setChildData}
        salesTableBody={rents as RentArray[]}
        invoiceTableBody={sales}
        handleSearchTable={(e) => setSearchVal(e.target.value)}
        search={searchVal}
      />
      {isRent ? plusDisplay : null}

      {/* pagination */}
      <div className="flex justify-center -translate-y-4">
        <Pagination
          sx={{
            "& .MuiPaginationItem-root": {
              color: isDarkTheme ? "white" : "",
            },
            "& .Mui-selected": {
              color: "white",
              backgroundColor: "rgb(74, 7, 160) !important",
            },
          }}
          className="transition-all"
          count={salesTab.value === SalesTabEnum.RENT ? rentLastPage : lastPage}
          page={page}
          onChange={handleChange}
        />
      </div>
      {/* modal wrap start */}
      <DashboardModal
        open={openAddSalesModal}
        // onClose={closeAddSalesModal}
        onClose={() => {
          closeAddSalesModal();
          setEdit(false);
        }}
        width={"749px"}
        height={"80%"}
      >
        <AddSales
          closeModal={() => {
            closeAddSalesModal();
            setEdit(false);
          }}
          isRentTab={isRent}
          isEdit={edit}
          data={childData as SalesArray}
        />
      </DashboardModal>
      {/* modal wrap end */}
    </Container>
  );
};

export default Sales;
