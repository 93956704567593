// import { QueryParams } from "../../../@types/dashboard";
import { baseApi } from "../../redux/baseApi";
// import { QueryParams } from "../../../@types/dashboard";
import { generateQueryString } from "helpers/helper";

export const reportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/invoices${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getSalesReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/sales${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getRentReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/rent${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getPurchasesReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/purchases${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getStaffReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/staff${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getOutletsReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/outlet${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getCreditReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/credit${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getDebitReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/report/debit${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getProductReports: builder.query<any, any>({
      query: (params) => ({
        url: `/reports/products/report/all/${generateQueryString({
          ...params,
        })}`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getProductReportCountBreakDown: builder.query<any, any>({
      query: ({ params, report }) => ({
        url: `/reports/products/report/count`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getRentBreakdown: builder.query<any, any>({
      query: ({ params, report }) => ({
        url: `/reports/rent-breakdown`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getPurchaseBreakdown: builder.query<any, any>({
      query: ({ params, report }) => ({
        url: `/reports/purchases/brake-down`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
    getSalesAndInvoiceBreakdown: builder.query<any, any>({
      query: ({ params, report }) => ({
        url: `/reports/sales-breakdown`,
        method: "GET",
      }),
      providesTags: ["reports"],
      transformResponse: (response: any) => response,
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetProductReportsQuery,
  useGetProductReportCountBreakDownQuery,
  useGetSalesReportsQuery,
  useGetRentReportsQuery,
  useGetRentBreakdownQuery,
  useGetPurchasesReportsQuery,
  useGetPurchaseBreakdownQuery,
  useGetStaffReportsQuery,
  useGetOutletsReportsQuery,
  useGetCreditReportsQuery,
  useGetDebitReportsQuery,
  useGetSalesAndInvoiceBreakdownQuery,
} = reportApi;
