import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { InputField } from "component/Form";
import { useAppSelector } from "../../redux/store";
import { selectUser } from "../../redux/features/authSlice";
import { SingleUser } from "../../../@types/auth";
import { formatDateToYYYYMMDD, updateSVGDimensions } from "helpers/helper";
import { useAddSales } from "./useAddSales";
import { SalesArray } from "../../../@types/sales";
import {
  useGetProductsNoPaginateQuery,
  useGetProductsQuery,
} from "../../redux/services/productApi";
import { SelectField } from "component/Select";
import { OptionType } from "pages/Dashboard/types";
// import { useGetSalesOutletQuery } from "../../redux/services/salesApi";
import { useSidebar } from "component/Sidebar/useSidebar";
import { useGetOutletsNoPaginationQuery } from "../../redux/services/outletApi";

interface AddSalesProps {
  closeModal: () => void;
  isEdit?: boolean;
  data: SalesArray;
  isRentTab?: boolean;
}

const AddSales: React.FC<AddSalesProps> = ({
  closeModal,
  isEdit,
  data,
  isRentTab,
}) => {
  const { formik, isLoading, isLoadingUpdate, isLoadingRent, isUpdatingRent } =
    useAddSales({
      closeModal,
      isUpdate: isEdit,
      salesId: data?.id,
      data,
      isActiveRentTab: isRentTab,
    });

  const { data: productsData, isLoading: isLoadingProducts } =
    useGetProductsNoPaginateQuery();

  const { data: salesOutletsData, isLoading: isLoadingSalesOutlets } =
    useGetOutletsNoPaginationQuery();

  const salesOutlets = salesOutletsData?.data?.[0] ?? [];
  const handleFormatSalesOutletDrop = () => {
    const newData = salesOutlets?.map((chi: any) => ({
      ...chi,
      label: chi.outlets_name,
      value: chi.id,
    }));
    return newData;
  };

  const products = productsData?.[0] ?? [];

  const { isDarkTheme } = useSidebar();
  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-[#f0f0f0]";
  const toggledDateTheme = isDarkTheme ? "bg-[#222121]" : "";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const formatProducts = () => {
    const productList = (products as any[]).map((chi) => {
      return {
        ...chi,
        label: chi?.product_name,
        value: chi?.id,
      };
    });
    return productList;
  };

  const user = useAppSelector(selectUser);
  const labelClass = "text-[14px] font-medium";
  const inputClass = "pl-3";

  const [isBarCodeDisplay, setIsBarCodeDisplay] = useState("");

  const handleUpdateSvg = () => {
    if (!isEdit) {
      return;
    }
    const updatedSVG = updateSVGDimensions({
      svgString: data?.product?.qr_image,
      newWidth: 100,
      newHeight: 100,
    });
    return updatedSVG;
  };

  const getTitle = (isEdit?: boolean, isRentTab?: boolean): string => {
    if (isEdit && !isRentTab) {
      return "Update Sales";
    } else if (isRentTab && !isEdit) {
      return "Add Rent";
    } else if (isRentTab && isEdit) {
      return "Update Rent";
    } else {
      return "Add Sales";
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="p-6 px-10">
      <div>
        <p className={`${toggleTextTheme} transition-all font-medium text-2xl`}>
          {getTitle(isEdit, isRentTab)}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6 mt-5">
        <InputField
          type="text"
          label="Customer name"
          name="customer_name"
          onChange={formik.handleChange}
          value={formik.values.customer_name}
          placeholder="Enter name"
          className={inputClass}
          labelClassName={labelClass}
        />

        <div className="flex flex-col gap-1 -ml-3 ">
          <label className={`${labelClass} ${toggleTextTheme} ml-3`}>
            Products
          </label>
          <SelectField
            value={formik.values.product as OptionType}
            selectOptions={formatProducts()}
            onChange={(e: any) => {
              formik.setFieldValue("product", {
                label: e?.label,
                value: e?.value,
              });

              formik.setFieldValue("description", e?.product_description);
              formik.setFieldValue("quantity", e?.quantity);
              formik.setFieldValue("size", e?.size);
              const updatedSVG = updateSVGDimensions({
                svgString: e?.qr_image,
                newWidth: 100,
                newHeight: 100,
              });
              setIsBarCodeDisplay(updatedSVG);
            }}
            placeholder="Select Product.."
            className={`${inputClass} !w-full`}
            padding="6px"
            bgColor={isDarkTheme ? "#222121" : "white"}
            isLoading={isLoadingProducts}
          />
        </div>

        <InputField
          type="text"
          label="Entry officer"
          name="entry_officer"
          value={
            (isEdit && data?.entry_officer) ||
            `${(user as SingleUser)?.first_name} ${
              (user as SingleUser)?.last_name
            }`
          }
          onChange={formik.handleChange}
          placeholder="Enter name"
          disabled
          className={`${inputClass} cursor-not-allowed ${toggledBgTheme} !text-[#ccc]`}
          labelClassName={labelClass}
        />
        <InputField
          type="text"
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          disabled
          className={`${inputClass} cursor-not-allowed ${toggledBgTheme} !text-[#ccc]`}
          placeholder="Enter description"
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Quantity"
          name="quantity"
          onChange={formik.handleChange}
          value={formik.values.quantity}
          placeholder="Enter quantity"
          disabled
          className={`${inputClass} cursor-not-allowed ${toggledBgTheme} !text-[#ccc]`}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Size"
          name="size"
          onChange={formik.handleChange}
          value={formik.values.size}
          placeholder="Enter size"
          disabled
          className={`${inputClass} cursor-not-allowed ${toggledBgTheme} !text-[#ccc]`}
          labelClassName={labelClass}
        />
        <InputField
          type="number"
          label="Price"
          name="price"
          onChange={formik.handleChange}
          value={formik.values.price}
          placeholder="Enter price"
          className={inputClass}
          labelClassName={labelClass}
        />

        <div className="flex flex-col">
          <label className={`${labelClass} ${toggleTextTheme} mb-1`}>
            Date
          </label>
          <Flatpickr
            value={formik.values.date}
            onChange={(selectedDates: Date[]) => {
              const [date] = selectedDates;
              formik.setFieldValue("date", formatDateToYYYYMMDD(date));
            }}
            placeholder="Enter  date"
            className={`${inputClass} ${toggleTextTheme}  ${toggledDateTheme} rounded-lg border  outline-none h-[50px] `}
          />
        </div>
        {isRentTab ? (
          <div className="flex flex-col">
            <label className={`${labelClass} ${toggleTextTheme} mb-1`}>
              Return Date
            </label>
            <Flatpickr
              value={formik.values.return_date}
              onChange={(selectedDates: Date[]) => {
                const [date] = selectedDates;
                formik.setFieldValue("return_date", formatDateToYYYYMMDD(date));
              }}
              placeholder="Enter  date"
              className={`${inputClass} ${toggleTextTheme}  ${toggledDateTheme} rounded-lg border outline-none h-[50px] `}
            />
          </div>
        ) : null}

        <div className="flex flex-col gap-1 -ml-3 ">
          <label className={`${labelClass} ${toggleTextTheme} ml-3`}>
            Outlet of Sales
          </label>
          <SelectField
            value={formik.values.outlet as OptionType}
            selectOptions={handleFormatSalesOutletDrop()}
            onChange={(e: any) => {
              formik.setFieldValue("outlet", e);
              formik.setFieldValue("outlet.id", e?.value);
            }}
            placeholder="Select outlet.."
            className={`${inputClass} !w-full`}
            padding="7px"
            bgColor={isDarkTheme ? "#222121" : "white"}
            isLoading={isLoadingSalesOutlets}
          />
        </div>
        <div>
          <label
            htmlFor="qr_image"
            className={`${labelClass} ${toggleTextTheme} mb-1`}
          >
            QR Code
          </label>
          <figure
            dangerouslySetInnerHTML={{
              __html: handleUpdateSvg() || isBarCodeDisplay,
            }}
          />
        </div>
      </div>
      <div className="flex justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Submit}
          label={isEdit ? "Update" : "Save"}
          className="w-[284px]"
          disabled={
            !formik.isValid ||
            !formik.dirty ||
            !formik.values.product.value ||
            !formik.values.outlet.value
          }
          loading={
            isLoading || isLoadingUpdate || isLoadingRent || isUpdatingRent
          }
        />
      </div>
    </form>
  );
};

export default AddSales;
