import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router";
import {
  useGetSingleAdminUserQuery,
  useGetSingleUserQuery,
  useResetPasswordMutation,
} from "../../redux/services/authApi";
import { useState } from "react";

export const useResetPassword = () => {
  const passwordValidationMessage = `
    Must have 8 characters.
    Must include capital and small letters.
    Must include one number/special character.
  `;

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .test("password-validation", passwordValidationMessage, (value) => {
        if (!value) return false;
        const hasMinLength = value.length >= 8;
        const hasLowercase = /[a-z]/.test(value);
        const hasUppercase = /[A-Z]/.test(value);
        const hasNumberOrSpecialChar =
          /[0-9]/.test(value) || /[@$!%*?&]/.test(value);
        return (
          hasMinLength && hasLowercase && hasUppercase && hasNumberOrSpecialChar
        );
      }),
    password_confirmation: yup
      .string()
      .required("Password confirmation is required")
      .test("password-match", "Passwords must match", function (value) {
        return value === this.parent.password;
      }),
  });

  const passwordCriteria = [
    { label: "Must have 8 characters", regex: /.{8,}/ },
    {
      label: "Must include capital and small letters",
      regex: /^(?=.*[a-z])(?=.*[A-Z])/,
    },
    {
      label: "Must include one number/special character",
      regex: /^(?=.*[0-9])|(?=.*[@$!%*?&])/,
    },
  ];

  const [passwordValidations, setPasswordValidations] = useState(
    passwordCriteria.map((criterion) => ({ ...criterion, checked: false }))
  );

  const handlePasswordChange = (password: string) => {
    const updatedValidations = passwordCriteria.map((criterion) => ({
      ...criterion,
      checked: criterion.regex.test(password),
    }));
    setPasswordValidations(updatedValidations);
  };

  const location = useLocation();

  const handleGetID = () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    return id;
  };

  const { data: userData, isLoading: isLoadingUser } =
    useGetSingleAdminUserQuery(handleGetID() as any, {
      skip: !handleGetID(),
    });

  const email = userData?.email
    ? userData?.email
    : localStorage.getItem("user-email");

  const loginFormValues = {
    email: email,
    password: "",
    password_confirmation: "",
  };

  const navigate = useNavigate();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const handleSubmit = async () => {
    const payload = { ...formik.values, email: email };
    await resetPassword(payload)
      .unwrap()
      .then(() => {
        toast.success("Password Reset Successfully");
        new Promise(() => {
          setTimeout(() => {
            toast.dismiss();
            navigate("/login");
          }, 1000);
        });
      });
  };

  const formik = useFormik({
    initialValues: loginFormValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    passwordValidations,
    handlePasswordChange,
    email,
    isLoadingUser,
  };
};
