import { generateQueryString } from "helpers/helper";
import { ProductApiResponse } from "../../../@types/product";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";

export const purchaseApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPurchase: builder.mutation({
      query: (payload) => ({
        url: "/purchase/create-purchase",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["purchases"],
    }),

    getPurchase: builder.query<any, QueryParams>({
      query: (params) => ({
        url: `/purchase/get-all-purchase${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["purchases"],
      transformResponse: (response: any) => response,
    }),

    updatePurchase: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `purchase/update-purchase/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["purchases"],
    }),

    deletePurchase: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `purchase/delete-purchase/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["purchases"],
    }),

    downloadPurchase: builder.mutation<Blob, void>({
      query: () => ({
        url: `purchase/export/excel`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportPurchase: builder.mutation<Blob, void>({
      query: () => ({
        url: "purchase/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useCreatePurchaseMutation,
  useGetPurchaseQuery,
  useUpdatePurchaseMutation,
  useDeletePurchaseMutation,
  useDownloadPurchaseMutation,
  useExportPurchaseMutation,
} = purchaseApi;
