import React from "react";
import LoginForm from "./LoginForm";

const Login: React.FC = () => {
  return (
    <div className="relative h-screen login-bg-img flex">
      <LoginForm />
    </div>
  );
};

export default Login;
