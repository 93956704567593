import { useState } from "react";
import { settingsTabs } from "./utils";

export const useSettings = () => {
  const [settingsTab, setSettingsTab] = useState(settingsTabs[0]);

//   const handleSettingsTabChange = (value: string) => {
//     setSettingsTab(value);
//   };

//   const isActiveTab = (tab: string) => {
//     if (settingsTab === tab) {
//       return "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]";
//     }
//   };

  return {
    settingsTab,
    // handleSettingsTabChange,
    // isActiveTab,
  };
};
