import { useState } from "react";

type TabType = {
  label: string;
  value: string;
};

export const useSales = () => {
  const [salesTab, setSalesTab] = useState<TabType>({
    label: "Sales",
    value: "",
  });

  const handleSalesTabChange = (tab: TabType) => {
    setSalesTab(tab);
  };

  const isActiveTab = (tabValue: string) => {
    return salesTab.value === tabValue
      ? "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
      : "";
  };
  return {
    salesTab,
    handleSalesTabChange,
    isActiveTab,
  };
};
