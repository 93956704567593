import { useState } from "react";

type TabType = {
  label: string;
  value: string;
};

export const useCredit = () => {
  const [creditTab, setCreditTab] = useState<TabType>({
    label: "Credit",
    value: "",
  });

  const handleCreditTabChange = (tab: TabType) => {
    setCreditTab(tab);
  };

  const isActiveTab = (tabValue: string) => {
    return creditTab.value === tabValue
      ? "text-white bg-[#4A07A0] p-[10px] border-0 rounded-[10px]"
      : "";
  };
  return {
    creditTab,
    handleCreditTabChange,
    isActiveTab,
  };
};
