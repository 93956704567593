import { generateQueryString } from "helpers/helper";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";
import {  SalesOutletResponse, SalesResponse } from "../../../@types/sales";

export const salesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createSales: builder.mutation({
      query: (payload) => ({
        url: "/sales",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["sales"],
    }),

    getSales: builder.query<SalesResponse, QueryParams>({
      query: (params) => ({
        url: `/sales/get-sales${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["sales"],
      transformResponse: (response: SalesResponse) => response,
    }),

    getSalesOutlet: builder.query<SalesOutletResponse[], void>({
      query: () => ({
        url: "/outlets/get-outlet",
        method: "GET",
      }),
      transformResponse: (response: { data: SalesOutletResponse[] }) => response.data,
    }),

    updateSales: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `sales/update-sales/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["sales"],
    }),

    deleteSales: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `sales/delete-sales/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sales"],
    }),

    downloadSales: builder.mutation<Blob, void>({
      query: () => ({
        url: "sales/export/excel",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),

    exportSales: builder.mutation<Blob, void>({
      query: () => ({
        url: "sales/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useCreateSalesMutation,
  useGetSalesQuery,
  useUpdateSalesMutation,
  useDeleteSalesMutation,
  useDownloadSalesMutation,
  useExportSalesMutation,
  useGetSalesOutletQuery,
} = salesApi;
