export const PdfIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    className={`${className} cursor-pointer`}
  >
    <path
      d="M19.5 19.0385H23.5M19.5 25.5H31.5M19.5 29.1923H31.5M19.5 32.8846H23.5M17.5 37.5H33.5C34.0304 37.5 34.5391 37.3055 34.9142 36.9593C35.2893 36.6131 35.5 36.1435 35.5 35.6538V15.3462C35.5 14.8565 35.2893 14.3869 34.9142 14.0407C34.5391 13.6945 34.0304 13.5 33.5 13.5H17.5C16.9696 13.5 16.4609 13.6945 16.0858 14.0407C15.7107 14.3869 15.5 14.8565 15.5 15.3462V35.6538C15.5 36.1435 15.7107 36.6131 16.0858 36.9593C16.4609 37.3055 16.9696 37.5 17.5 37.5Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      className={className}
    />
    <path
      d="M31.5 19.5C31.7761 19.5 32 19.2934 32 19.0385C32 18.7836 31.7761 18.5769 31.5 18.5769C31.2239 18.5769 31 18.7836 31 19.0385C31 19.2934 31.2239 19.5 31.5 19.5Z"
      fill="black"
      stroke="black"
      stroke-width="1.5"
      className={className}
    />
  </svg>
);
