import { SvgTypes } from "./types";

export const LogoutIcon = ({ fill }: SvgTypes) => (
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 10.8333L16 7.50001M16 7.50001L12.6667 4.16668M16 7.50001H4.33333M9.33333 10.8333V11.6667C9.33333 12.3297 9.06994 12.9656 8.6011 13.4344C8.13226 13.9033 7.49637 14.1667 6.83333 14.1667H3.5C2.83696 14.1667 2.20107 13.9033 1.73223 13.4344C1.26339 12.9656 1 12.3297 1 11.6667V3.33334C1 2.6703 1.26339 2.03442 1.73223 1.56558C2.20107 1.09674 2.83696 0.833344 3.5 0.833344H6.83333C7.49637 0.833344 8.13226 1.09674 8.6011 1.56558C9.06994 2.03442 9.33333 2.6703 9.33333 3.33334V4.16668"
      stroke={fill || "#FC1330"}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
