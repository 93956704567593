import React, { useState } from "react";

const Toggle = ({
  handleToggle,
  isChecked,
}: {
  handleToggle: () => void;
  isChecked: boolean;
}) => {
  return (
    <label className="inline-flex items-center mb-5 cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={isChecked}
        onChange={handleToggle}
      />
      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-[#6D6A6A] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-[red] peer-checked:bg-[#4A07A0]"></div>
    </label>
  );
};

export default Toggle;
