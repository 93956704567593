import React from "react";

const RadioInput = ({
  name,
  label,
  onChange,
  checked,
  value,
  id,
}: {
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  value: string;
  id: string;
}) => {
  return (
    <div className="flex gap-3 items-center">
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        checked={checked}
        className="radio-custom"
      />
      <label
        htmlFor={id}
        className={`text-[#999898] font-medium text-sm cursor-pointer`}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioInput;
