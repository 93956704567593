import { generateQueryString } from "helpers/helper";
import { ProductApiResponse } from "../../../@types/product";
import { baseApi } from "../../redux/baseApi";
import { QueryParams } from "../../../@types/dashboard";

export const staffApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createStaff: builder.mutation({
      query: (payload) => ({
        url: "/staffs/create-staff",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["staffs"],
    }),

    updateStaff: builder.mutation<void, { id: number; payload: any }>({
      query: ({ id, payload }) => ({
        url: `staffs/update-staff/${id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["staffs"],
    }),

    getStaff: builder.query<any, QueryParams>({
      query: (params) => ({
        url: `staffs/get-staff${generateQueryString({ ...params })}`,
        method: "GET",
      }),
      providesTags: ["staffs"],
      transformResponse: (response) => response,
    }),

    deleteStaff: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `staffs/delete-staff/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["staffs"],
    }),

    downloadStaff: builder.mutation<Blob, void>({
      query: () => ({
        url: `staffs/staff-export`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    //   invalidatesTags: ["staffs"],
    }),
    exportStaff: builder.mutation<Blob, void>({
      query: () => ({
        url: "staffs/staff/export/pdf",
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    //   invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useCreateStaffMutation,
  useGetStaffQuery,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
  useDownloadStaffMutation,
  useExportStaffMutation,
} = staffApi;
