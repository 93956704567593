import { Button } from "component/Button";
import { ButtonTypeEnum } from "component/Button/types";
import { toast } from "sonner";
import { SalesArray } from "../../../@types/sales";
import { useDeleteSalesMutation } from "../../redux/services/salesApi";
import { useDeleteRentMutation } from "../../redux/services/rentApi";
import { useDeleteCreditMutation } from "../../redux/services/creditApi";
import { useDeleteDebitMutation } from "../../redux/services/debitApi";
import { useSidebar } from "component/Sidebar/useSidebar";

const DeleteCredit = ({
  data,
  onCancel,
  isDebitTab,
}: {
  data: any;
  onCancel: () => void;
  isDebitTab?: boolean;
}) => {
  const [deleteCredit, { isLoading }] = useDeleteCreditMutation();
  const [deleteDebit, { isLoading: isDeleting }] = useDeleteDebitMutation();

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "!bg-[#222121]" : "!bg-white";
  // const toggledTextTheme = isDarkTheme ? "!text-white" : "!text-[#4A07A0]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const handleDelete = async () => {
    const id = data?.id;
    if (isDebitTab) {
      await deleteDebit({ id })
        .unwrap()
        .then(() => {
          toast.success("Debit Deleted Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              onCancel();
            }, 1000);
          });
        });
    } else {
      await deleteCredit({ id })
        .unwrap()
        .then(() => {
          toast.success("Credit Deleted Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              onCancel();
            }, 1000);
          });
        });
    }
  };

  return (
    <div className="flex flex-col gap-2 p-5 ">
      <p className={`${toggleTextTheme} transition-all font-light text-center`}>
        Are you sure you <br /> want to delete this{" "}
        <span className="text-[#4A07A0] font-medium ">{`(${
          data?.product_sold || data?.vendor_name || "--- ----"
        })`}</span>{" "}
        {isDebitTab ? "Rent?" : "sales?"}
      </p>
      <div className="flex gap-3 items-center justify-end mt-10">
        <Button
          btnType={ButtonTypeEnum.Button}
          label="Yes, Delete"
          className="w-[200px] bg-[#DB2B2B] py-2"
          loading={isDebitTab ? isDeleting : isLoading}
          disabled={isDebitTab ? isDeleting : isLoading}
          onClick={handleDelete}
        />
        <Button
          onClick={onCancel}
          btnType={ButtonTypeEnum.Button}
          label="Cancel"
          className={`w-[150px] py-2  !text-[#4A07A0] ${toggledBgTheme} border-[#4A07A0] border-[1px]`}
        />
      </div>
    </div>
  );
};

export default DeleteCredit;
