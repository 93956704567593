import { Container } from "component/Container";
import { Frame } from "component/Frame";
import { settingsTabs } from "./utils";
import { useSettings } from "./useSettings";
import useDisclosure from "hooks/useDisclosure";
import SettingsProfile from "./Profile";
import LoginHistory from "./LoginHistory";
import AdminSetting from "./AdminSetting";
import Notifications from "./Notifications";
import SystemSettings from "./SystemSettings";
import { useState } from "react";
import AccountSettings from "./AccountSettings";
import { useLocation, useNavigate } from "react-router";
import { useSidebar } from "component/Sidebar/useSidebar";

const Settings = () => {
  const { settingsTab } = useSettings();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page") || "";

  // Function to determine if a tab is active
  const isActiveTab = (tabPath: string) => {
    return page.includes(tabPath)
      ? "bg-[#4A07A0] text-white p-[10px]"
      : "bg-none text-black";
  };

  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "bg-[#222121]" : "bg-[#FAF9F6]";
  const toggleTextTheme = isDarkTheme ? "text-white" : "text-black";

  const settingsTabData = (
    <Frame
      rmPadding
      bg={toggledBgTheme}
      className="p-2.5 flex justify-between items-center border-0 rounded-[10px]"
    >
      {settingsTabs.map((tab, idx) => {
        const { path, name } = tab;
        return (
          <div key={idx} className="w-[500px]">
            <p
              className={`${toggleTextTheme} cursor-pointer rounded-md text-center ${isActiveTab(
                path
              )} `}
              onClick={() => navigate(`/settings?page=${path}`)}
            >
              {name}
            </p>
          </div>
        );
      })}
    </Frame>
  );

  return (
    <Container>
      {settingsTabData}
      {page === "account-settings" && (
        <AccountSettings
          onProfileClick={() =>
            navigate(`/settings?page=account-settings/settings-profile`)
          }
          onLoginHistoryClick={() =>
            navigate(`/settings?page=account-settings/login-history`)
          }
        />
      )}
      {page === "account-settings/settings-profile" && <SettingsProfile />}
      {page === "account-settings/login-history" && <LoginHistory />}
      {page === "admin-settings" && <AdminSetting />}
      {page === "system-setting" && (
        <SystemSettings
          onNotificationClick={() =>
            navigate(`/settings?page=system-setting/notifications`)
          }
        />
      )}
      {page === "system-setting/notifications" && <Notifications />}
    </Container>
  );
};

export default Settings;
