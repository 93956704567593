import {
  CreditIcon,
  DashboardIcon,
  InvoiceIcon,
  ProductIcon,
  PurchaseIcon,
  QrCodeIcon,
  SalesIcon,
  StaffsIcon,
} from "assets/images/tsx";

export const sidebar_items = [
  {
    label: "Dashboard",
    icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    label: "Products",
    icon: ProductIcon,
    path: "/products",
  },
  {
    label: "Invoice",
    icon: InvoiceIcon,
    path: "/invoice",
  },
  {
    label: "Sales/Rent",
    icon: SalesIcon,
    path: "/sales-rent",
  },
  {
    label: "Purchase",
    icon: PurchaseIcon,
    path: "/purchase",
  },
  {
    label: "Staffs/Outlets",
    icon: StaffsIcon,
    path: "/staffs-outlets",
  },
  {
    label: "Credits/Debit",
    icon: CreditIcon,
    path: "/credits-debits",
  },
  {
    label: "Reports",
    icon: InvoiceIcon,
    path: "/reports",
  },
];
