import { BtnLoader } from "component/Loader/Loader";
import { BtnTypes } from "./types";
import { useSidebar } from "component/Sidebar/useSidebar";

const Button = ({
  className,
  label,
  btnType,
  onClick,
  disabled,
  loading,
}: BtnTypes) => {
  const { isDarkTheme } = useSidebar();

  const toggledBgTheme = isDarkTheme ? "!bg-[#161616]" : "!bg-[#d3d3d3]";
  return (
    <button
      className={`${className} ${
        loading || disabled
          ? `${toggledBgTheme}  text-[#a9a9a9] cursor-not-allowed`
          : "bg-[#4A07A0] text-white"
      } border-0 p-[15px] transition-all duration-300 cursor-pointer rounded-[10px]  text-center`}
      type={btnType}
      onClick={onClick}
      disabled={disabled}
      style={{ cursor: loading || disabled ? "not-allowed" : "pointer" }}
    >
      {loading ? (
        <div className="flex justify-center">
          <BtnLoader />
        </div>
      ) : (
        label
      )}
    </button>
  );
};

export default Button;
