import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "sonner";
import {
  useInviteUserMutation,
  useUpdateRolesMutation,
} from "../../redux/services/settingsApi";
import { SingleUser } from "../../../@types/auth";

export const useInviteUser = ({
  closeModal,
  user,
  isUpdate,
}: {
  closeModal: () => void;
  user?: SingleUser;
  isUpdate?: boolean;
}) => {
  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .min(1, "Work Email is required")
      .email("Invalid email address")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
      .required("work Email is required"),
    name: yup.string().required("Role is required"),
  });

  const formValues = {
    first_name: (isUpdate && user?.first_name) || "",
    last_name: (isUpdate && user?.last_name) || "",
    email: (isUpdate && user?.email) || "",
    name: (isUpdate && user?.name) || "",
  };

  const [inviteUser, { isLoading }] = useInviteUserMutation();
  const [updateRoles, { isLoading: isLoadingUpdate }] =
    useUpdateRolesMutation();
  const handleSubmit = async () => {
    if (isUpdate) {
      if (!user?.id) {
        return;
      }
      const payload = { name: formik?.values?.name, id: user?.id };
      console.log(payload, "payload");
      await updateRoles(payload)
        .unwrap()
        .then(() => {
          toast.success("User Role Updated Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              closeModal();
              formik.resetForm({
                values: {
                  first_name: "",
                  last_name: "",
                  email: "",
                  name: "",
                },
              });
            }, 1000);
          });
        });
    } else {
      const payload = { ...formik.values };
      await inviteUser(payload)
        .unwrap()
        .then(() => {
          toast.success("User Invited Successfully");
          new Promise(() => {
            setTimeout(() => {
              toast.dismiss();
              closeModal();
            }, 1000);
          });
        });
    }
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return {
    formik,
    isLoading,
    closeModal,
    isLoadingUpdate,
  };
};
